import React, { useEffect, useMemo, useState, FC, Fragment } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { PricesHistoryChart } from 'components/Charts';
import { Loader } from 'components/Indicators';
import {
	BaseCheckbox,
	CountriesSelectInput,
	CustomPeriodDropdown,
} from 'components/Inputs';
import { RemoteDataTable } from 'components/Tables';
import { getPricesForProductV2 } from 'services';
import { chartData } from 'utils';
import { productHistoryPriceColumns } from './columns';
import { LabelType } from 'models/types';
import { useReferenceData } from 'context';

interface HistoryPriceModalProps {
  onCancel?: () => void;
  product?: string | number | null;
  euAuthorizedProduct?: string;
}

export const HistoryPriceModal: FC<HistoryPriceModalProps> = ({
	onCancel,
	product,
	euAuthorizedProduct,
}) => {
	const {
		referenceData: { dateDropdownOptions },
	} = useReferenceData();
	const [pricesData, setPricesData] = useState<any[]>([]);
	const [pricesLoading, setPricesLoading] = useState<boolean>(false);
	const [searchInputs, setSearchInputs] = useState<{
    countries: any[];
    start_from: LabelType | null;
}>({
	countries: [],
	start_from: dateDropdownOptions[1],
});
	const [displayInputs, setDisplayInputs] = useState({
		exFactoryPrice: true,
		retailPrice: true,
		wholesalePrice: true,
		avaragePrice: true,
	});

	const onSearchInputsChangeHandler = (e) =>
		setSearchInputs((prevInputs) => ({
			...prevInputs,
			[e.target.name]: e.target.value,
		}));

	const onDisplayInputsChangeHandler = (e) =>
		setDisplayInputs((prevInputs) => ({
			...prevInputs,
			[e.target.name]: e.target.checked,
		}));

	const getPricesParams = useMemo(
		() => ({
			eu_authorized_product: euAuthorizedProduct,
			country: searchInputs.countries,
			page: 1,
			page_size: 10000,
			ordering: '-date',
			// start_from: searchInputs?.start_from?.id,
			start_from: null
		}),
		[
			searchInputs.countries, 
			// searchInputs.start_from, 
			euAuthorizedProduct
		]
	);
	useEffect(() => {
		async function onGetPrices() {
			try {
				setPricesLoading(true);
				const pricesData = await getPricesForProductV2({
					params: { product_id: product },
					queryParams: getPricesParams,
				});
				setPricesData(pricesData.results);
			} finally {
				setPricesLoading(false);
			}
		}
		onGetPrices();
	}, [getPricesParams]);

	return (
		<Fragment>
			{pricesLoading && <Loader />}
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Typography variant={'body1'} fontWeight={800}>
            Price History
					</Typography>
				</Grid>
				<Grid container item xs={12} spacing={4}>
					<Grid item xs={6}>
						<CountriesSelectInput
							onChange={onSearchInputsChangeHandler}
							value={searchInputs.countries}
							name={'countries'}
							label={'Country'}
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}>
						<CustomPeriodDropdown
							onChange={onSearchInputsChangeHandler}
							value={searchInputs.start_from}
							name={'start_from'}
							label={'Select Date Range'}
						/>
					</Grid>
				</Grid>
				<Grid container item xs={12}>
					<Grid item xs={3}>
						<BaseCheckbox
							onChange={onDisplayInputsChangeHandler}
							checked={displayInputs.exFactoryPrice}
							name={'exFactoryPrice'}
							label={'Ex-factory Price'}
						/>
					</Grid>
					<Grid item xs={3}>
						<BaseCheckbox
							onChange={onDisplayInputsChangeHandler}
							checked={displayInputs.retailPrice}
							name={'retailPrice'}
							label={'Retail Price'}
						/>
					</Grid>
					<Grid item xs={3}>
						<BaseCheckbox
							onChange={onDisplayInputsChangeHandler}
							checked={displayInputs.wholesalePrice}
							name={'wholesalePrice'}
							label={'Wholesale Price'}
						/>
					</Grid>
					<Grid item xs={3}>
						<BaseCheckbox
							onChange={onDisplayInputsChangeHandler}
							checked={displayInputs.avaragePrice}
							name={'avaragePrice'}
							label={'Average of available prices'}
						/>
					</Grid>
				</Grid>
				{pricesData && (
					<Grid item xs={12}>
						<Box
							height={'500px'}
							display={'flex'}
							justifyContent={'center'}
							alignItems={'center'}
						>
							{Array.isArray(pricesData) && pricesData.length > 0 ? (
								<PricesHistoryChart
									data={chartData(pricesData)}
									displayInputs={displayInputs}
									isShort={
										!searchInputs.start_from 
										|| !['Last 24 months', 'All'].includes(searchInputs.start_from.label)
									}
									start_from={searchInputs.start_from}
								/>
							) : (
								<Typography variant='body1'>No Results Found</Typography>
							)}
						</Box>
					</Grid>
				)}

				{searchInputs.countries.length === 1 && (
					<Grid item xs={12}>
						<RemoteDataTable
							data={pricesData}
							columns={productHistoryPriceColumns}
							enableRowSelection={false}
							enableFullScreenToggle
							enablePagination
							manualSorting
							enableMultiSort={false}
							renderTopToolbarCustomActions={() => {
								return (
									<Typography fontWeight='600' p={1}>
                    Selected Country Price History (displays data when one
                    country is selected)
									</Typography>
								);
							}}
						/>
					</Grid>
				)}
			</Grid>
		</Fragment>
	);
};
