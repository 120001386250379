import { QueryKeysEnum } from 'models/enums';
import { useQuery } from 'react-query';
import { getShortages } from 'services/ShortageServices';

export const useShortages = ({ queryParams }, enabled) => {
	const {
		reason__in,
		country__in,
		is_active,
		ordering,
		page,
		page_size,
		search,
	} = queryParams;
	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[
			QueryKeysEnum.SHORTAGES,
			reason__in,
			country__in,
			is_active,
			ordering,
			page,
			page_size,
			search,
		],
		() => getShortages({ queryParams }),
		{ enabled }
	);
	return { data, error, isLoading, isFetching, isFetched };
};
