import AxiosClient from './Clients/AxiosClient';

const authenticateServiceUrl = '/auth';

export const signIn = async (signInBody) => {
	const signInResponse = await AxiosClient.post(authenticateServiceUrl + '/jwt/create/', signInBody);
	return signInResponse.data;
};

export const register = async (registerBody) => {
	const registerResponse = await AxiosClient.post(authenticateServiceUrl + '/users/', registerBody);
	return registerResponse.data;
};

export const activateAccount = async (activateAccountBody) => {
	const activateAccountResponse = await AxiosClient.post(authenticateServiceUrl + '/users/activation/', activateAccountBody);
	return activateAccountResponse.data;
};

export const forgotPassword = async (forgotPasswordBody) => {
	const forgotPasswordResponse = await AxiosClient.post(authenticateServiceUrl + '/users/reset_password/', forgotPasswordBody);
	return forgotPasswordResponse.data;
};

export const changePassword = async (changePasswordBody) => {
	const changePasswordResponse = await AxiosClient.post(
		authenticateServiceUrl + '/users/reset_password_confirm/',
		changePasswordBody
	);
	return changePasswordResponse.data;
};

export const resendActivationLink = async (resendActivationLinkBody) => {
	const resendActivationLinkResponse = await AxiosClient.post(
		authenticateServiceUrl + '/users/resend_activation/',
		resendActivationLinkBody
	);
	return resendActivationLinkResponse.data;
};

export const refreshToken = async (refreshTokenBody) => {
	const refreshTokenResponse = await AxiosClient.post(authenticateServiceUrl + '/jwt/refresh/', refreshTokenBody);
	return refreshTokenResponse.data;
};

export const verifyToken = async (verifyTokenBody) => {
	const verifyTokenResponse = await AxiosClient.post(authenticateServiceUrl + '/jwt/verify/', verifyTokenBody);
	return verifyTokenResponse.data;
};
