import { useEffect, useMemo, useState } from 'react';
import { SortingType } from 'models/types';
import { getSortingValue } from 'utils';
import { useDebounce } from '.';
import { useLocation } from 'react-router-dom';

export const useQueryPagination = (
	queryService,
	searchKeyword?,
	params?,
	queryParams?,
	options?
) => {
	const location = useLocation();
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [doneLoading, setDoneLoading] = useState(false);
	const search = useDebounce(searchKeyword, 1000);

	const [data, setData] = useState<any[] | undefined>();

	const [rowCount, setRowCount] = useState(0);
	const [sorting, setSorting] = useState<SortingType[]>(options?.sort || []);
	const [pageCount, setPageCount] = useState<number | null>(null);
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

	const pageResetted = useMemo(() => {
		// when filters are being applied go back to first page
		if (pagination.pageIndex !== 0) {
			setPagination((prev) => ({ ...prev, pageIndex: 0 }));
		}
		return true;
	}, [queryParams, params, search, sorting, pageCount]);

	const enabled = useMemo(() => {
		if (params && Object.values(params).some((val) => !val)) {
			return false;
		}
		if (options?.mandatory) {
			const queryParamsCheck = searchKeyword != null
				? { searchKeyword, ...queryParams }
				: { ...queryParams };
			const shouldNotFetch = options?.mandatory.some(
				(mandatoryOption) => !queryParamsCheck[mandatoryOption]
			) || !pageResetted;
			if (shouldNotFetch) return false;
			else {
				return true;
			}
		} else {
			return true;
		}
	}, [options?.mandatory, params, queryParams, searchKeyword]);

	const {
		data: dataResult,
		error,
		isLoading,
		isFetching,
		isFetched,
	} = queryService(
		{
			params,
			queryParams: {
				// conditional ordering for common and price columns
				...(
					getSortingValue(sorting)?.includes('ex_factory_price')
						|| getSortingValue(sorting)?.includes('retail_price')
						|| getSortingValue(sorting)?.includes('wholesale_price')
						? { price_ordering: getSortingValue(sorting) }
						: { ordering: getSortingValue(sorting) }
				),
				page: pagination.pageIndex + 1,
				page_size: pageCount || 10,
				search,
				...queryParams,
			},
		},
		enabled
	);

	useEffect(() => {
		if (dataResult) {
			setData(dataResult?.results || []);
			setRowCount(dataResult?.count);
			setDoneLoading(true);
		}
	}, [dataResult]);


	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const pageParam = params.get('page');
		const pageIndex = pageParam ? parseInt(pageParam, 10) - 1 : 0;
		const count = rowCount / pagination.pageSize;

		if (count && pageIndex > 0 && pageIndex < count) {
			setPagination(prev => ({ ...prev, pageIndex }));
		} else if (!pageParam || pageIndex !== pagination.pageIndex) {
			setPagination(prev => ({
				...prev,
				pageIndex: pageParam ? pageIndex : 0
			}));
		}

	}, [location.search, window.history.state, rowCount, pagination.pageSize]);


	useEffect(() => {
		if (isFirstLoad) {
			setIsFirstLoad(false);
		} else {
			// Update the URL with the new page parameter
			const params = new URLSearchParams(window.location.search);
			params.delete('page');
			params.set('page', String(pagination.pageIndex + 1));

			window.history.replaceState(null, '', `?${params.toString()}`);
		}
	}, [pagination]);

	return {
		data,
		isLoading,
		isFetching,
		isFetched,
		error,
		setPagination,
		rowCount,
		pageCount,
		setPageCount,
		setSorting,
		state: {
			isLoading: isLoading || isFetching,
			isError: error,
			pagination,
			showProgressBars: isLoading || isFetching || !doneLoading,
			sorting,
		},
	};
};
