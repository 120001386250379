/* eslint-disable no-new-wrappers */
export const numberFormatter = (num) => {
	let amount = num + '';
	const delimiter = ','; // replace comma if desired
	let wholePart = amount.split('.', 2);
	let decimalPart = wholePart[1];
	if (decimalPart) {
		decimalPart = decimalPart.slice(0, 2);
	}
	let i = parseInt(wholePart[0]);
	if (isNaN(i)) {
		return '';
	}
	let minus = '';
	if (i < 0) {
		minus = '-';
	}
	i = Math.abs(i);
	let n = i + '';
	wholePart = [];
	while (n.length > 3) {
		const nn = n.substring(n.length - 3);
		wholePart.unshift(nn);
		n = n.substring(0, n.length - 3);
	}
	if (n.length > 0) {
		wholePart.unshift(n);
	}
	n = wholePart.join(delimiter);
	if (!decimalPart || decimalPart.length < 1) {
		amount = n;
	} else {
		amount = n + '.' + decimalPart;
	}
	amount = minus + amount;
	return amount;
};

export const abbreviateNumber = (num) => {
	if (num >= 1000000000) {
		return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
	}
	if (num >= 1000000) {
		return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
	}
	if (num >= 1000) {
		return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
	}
	return num;
};
