import React from 'react';
import { useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid, Box, Typography, Alert, Link } from '@mui/material';
import { Formik } from 'formik';

import { BaseButton } from 'components/Buttons';
import { BaseInput, BasePasswordInput } from 'components/Inputs';
import { registerSchema, initialFormValues } from 'forms/register';

import { register } from 'services';
import { ResponseMessageInterface } from 'models/interfaces';
import { AlertsTypesEnum } from 'models/enums';

export const Register = () => {
	const [responseMessage, setResponseMessage] = useState<ResponseMessageInterface | null>(null);
	const onRegisterHandler = async (
		{ company, confirmPassword, ...registerInputs }: any,
		{ setSubmitting, resetForm, setErrors }: any
	) => {
		try {
			setResponseMessage(null);
			await register(registerInputs);
			resetForm();
			setResponseMessage({
				type: AlertsTypesEnum.SUCCESS,
				detail: `Activation link has been send to '${registerInputs.email}'`,
			});
		} catch (err: any) {
			if (err.data.detail) {
				setResponseMessage({
					type: AlertsTypesEnum.ERROR,
					detail: err.data.detail,
				});
			} else {
				setErrors(err.data);
			}
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<Box>
			<Typography align="left" sx={{ mb: 4 }}>
        Register for a Free account to start exploring PharmaFootpath.
			</Typography>
			<Formik initialValues={initialFormValues} validationSchema={registerSchema} onSubmit={onRegisterHandler}>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={6} md={6}>
								<BaseInput
									fullWidth
									name="first_name"
									label="Enter First Name"
									value={values.first_name}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.first_name && Boolean(errors.first_name)}
									helperText={touched.first_name ? errors.first_name : ''}
								/>
							</Grid>
							<Grid item xs={6} md={6}>
								<BaseInput
									fullWidth
									name="last_name"
									label="Enter Last Name"
									value={values.last_name}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.last_name && Boolean(errors.last_name)}
									helperText={touched.last_name ? errors.last_name : ''}
								/>
							</Grid>
							<Grid item xs={12}>
								<BaseInput
									fullWidth
									name="email"
									label="Enter Email"
									value={values.email}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.email && Boolean(errors.email)}
									helperText={touched.email ? errors.email : ''}
								/>
							</Grid>
							<Grid item xs={12}>
								<BaseInput
									fullWidth
									name="company_name"
									label="Company Name"
									value={values.company_name}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.company_name && Boolean(errors.company_name)}
									helperText={touched.company_name ? errors.company_name : ''}
								/>
							</Grid>
							<Grid item xs={6}>
								<BasePasswordInput
									fullWidth
									name="password"
									label="Enter Password"
									autoComplete="current-password"
									value={values.password}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.password && Boolean(errors.password)}
									helperText={touched.password ? errors.password : ''}
								/>
							</Grid>
							<Grid item xs={6}>
								<BasePasswordInput
									fullWidth
									name="confirmPassword"
									label="Confirm Password"
									autoComplete="current-password"
									value={values.confirmPassword}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.confirmPassword && Boolean(errors.confirmPassword)}
									helperText={touched.confirmPassword ? errors.confirmPassword : ''}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox defaultChecked />}
										label={
											<Box display="flex">
                        I accept the
												<Link
													href="https://pharmafootpath.com/terms-and-conditions/"
													target="_blank"
													sx={{
														marginLeft: 1,
													}}
												>
                          Terms and Conditions
												</Link>
											</Box>
										}
									/>
									<FormControlLabel
										control={<Checkbox defaultChecked />}
										label={
											<Box display="flex">
                        I accept the
												<Link
													href="https://pharmafootpath.com/privacy-policy/"
													target="_blank"
													sx={{
														marginLeft: 1,
													}}
												>
                          Privacy Policy
												</Link>
											</Box>
										}
									/>
									<FormControlLabel
										control={<Checkbox />}
										label="Receive email updates about PharmaFootpath and other products (not third party emails)"
									/>
								</FormGroup>
							</Grid>
							{responseMessage && responseMessage.detail && (
								<Grid item xs={12}>
									<Alert severity={responseMessage.type}>{responseMessage.detail}</Alert>
								</Grid>
							)}
							<Grid item xs={12} sx={{ textAlign: 'center' }}>
								<BaseButton disabled={isSubmitting} label={'Sign Up'} />
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};
