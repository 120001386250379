import React, { ReactNode } from 'react';
import { Box, Select, FormControl, MenuItem } from '@mui/material';
import { MatFormLabel } from '../';

import { COLORS } from 'utils/consts';
import { useReferenceData } from 'context';
import {
	MEDIUM_BORDER_RADIUS,
	SMALL_VARIANT_FONT_SIZE,
	SMALL_VARIANT_INPUT_HEIGHT,
} from 'utils/consts/themeConsts';
import { MaterialUISizeEnum } from 'models/enums';
import { MaterialUIInputSizeType } from 'models/types';

interface CustomPeriodDropdownProps {
  onChange?: (x: any) => void;
  value: any;
  label: string;
  name: string;
  sx?: any;
  inputProps?: any;
  wrapperProps?: any;
  labelInfo?: ReactNode;
  size?: MaterialUIInputSizeType;
}

export const CustomPeriodDropdown = ({
	label,
	name,
	value,
	onChange,
	// options,
	labelInfo,
	wrapperProps,
	size = 'small',
}: CustomPeriodDropdownProps) => {
	const {
		referenceData: { dateDropdownOptions },
	} = useReferenceData();
	return (
		<FormControl fullWidth {...wrapperProps}>
			{label && (
				<MatFormLabel id={name}>
					{label}
					{labelInfo && <Box sx={{ ml: 'auto' }}>{labelInfo}</Box>}
				</MatFormLabel>
			)}
			<Select
				labelId={name}
				id={label + '-select'}
				name={name}
				value={value}
				onChange={onChange}
				autoWidth
				sx={{
					maxHeight: size === MaterialUISizeEnum.SMALL
						? SMALL_VARIANT_INPUT_HEIGHT
						: undefined,
					fontSize: size === MaterialUISizeEnum.SMALL
						? SMALL_VARIANT_FONT_SIZE
						: undefined,
					borderRadius: MEDIUM_BORDER_RADIUS,
					backgroundColor: COLORS.white,
				}}
			>
				{dateDropdownOptions.map((option: any) => (
					<MenuItem key={option.label} value={option}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
