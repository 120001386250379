import { CardItemTypeEnum } from 'models/enums';

export const cardFields = [
	{
		key: 'Country license is held:',
		fieldPath: ['wda_license', 'country', 'name'],
		type: CardItemTypeEnum.TEXT,
		col: 4,
	},
	{
		key: 'Website:',
		linkField: 'website',
		fieldPath: ['website'],
		type: CardItemTypeEnum.EXTERNAL_LINK,
		col: 4,
	},
	{
		key: 'Linkedin:',
		linkField: 'linkedin_link',
		fieldPath: ['linkedin_link'],
		type: CardItemTypeEnum.EXTERNAL_LINK,
		col: 4,
	},
	{
		key: 'Contact email:',
		linkField: 'email',
		fieldPath: ['email'],
		linkType: 'email',
		type: CardItemTypeEnum.EXTERNAL_LINK,
		col: 4,
	},
	{
		key: 'Office address:',
		fieldPath: ['wda_license', 'holder_address'],
		type: CardItemTypeEnum.TEXT,
		col: 4,
	},
	{
		key: 'Site address:',
		fieldPath: ['wda_license', 'site_address'],
		type: CardItemTypeEnum.TEXT,
		col: 4,
	},
];
