import AuthClient from './Clients/AuthClient';
import { GetATCCodesParamsInterface } from 'models/interfaces';
import { generateQuery } from 'utils';

const atcCodeServicesUrl = '/v1/atc-code/';

export const getATCCodes = async ({ queryParams }: { queryParams?: GetATCCodesParamsInterface }) => {
	const atcCodesResponse = await AuthClient.get(atcCodeServicesUrl + generateQuery(queryParams));
	return { results: atcCodesResponse.data };
};

export const getATCCode = async ({ params }: { params: any }) => {
	const atcCodeResponse = await AuthClient.get(`${atcCodeServicesUrl}${params.uid}/`);
	return atcCodeResponse.data;
};
