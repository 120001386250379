import React, { FC, ReactNode, useEffect, useState } from 'react';

import { Box, ListItem, Checkbox, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { MatFormLabel, TextFieldWrapper } from '..';
import { MaterialUISizeEnum } from 'models/enums';
import {
	MEDIUM_BORDER_RADIUS,
	SMALL_VARIANT_FONT_SIZE,
	SMALL_VARIANT_INPUT_HEIGHT,
} from 'utils/consts/themeConsts';
import { COLORS } from 'utils/consts';
import {
	MaterialUIInputSizeType,
	MaterialUIInputVariantType,
} from 'models/types';
import { useDebounce } from 'hooks';

const InjectiblesLabel = 'Injectibles';

interface BaseAutoCompleteInputRemoteDataInterface {
	parentValue?: string;
	onChange?: (x: any) => void;
	onBlur?: (x: any) => void;
	value?: any;
	name: string;
	label: string;
	labelInfo?: ReactNode;
	placeholder?: string;
	autoComplete?: string | number;
	variant?: MaterialUIInputVariantType;
	autoWidth?: boolean;
	fullWidth?: boolean;
	error?: boolean;
	helperText?: string;
	sx?: any;
	inputProps?: any;
	size?: MaterialUIInputSizeType;
	dataService?: (x: any) => Promise<any>;
}

interface Option {
	name: string;
	id?: string | number;
}

export const BaseAutoCompleteInputRemoteData: FC<BaseAutoCompleteInputRemoteDataInterface> = ({
	parentValue,
	value,
	onChange,
	label,
	labelInfo,
	placeholder,
	name,
	fullWidth,
	size = 'small',
	dataService,
}) => {
	const [inputOptions, setInputOptions] = useState<Option[]>([]);
	const [search, setSearch] = useState('');
	const debounceValue = useDebounce(search);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isInjectible, setIsInjectible] = useState<boolean | null>(null);

	useEffect(() => {
		async function getData(debounceValue) {
			try {
				setLoading(true);
				const data = dataService && (await dataService({
					search_for: parentValue,
					search: debounceValue,
					page_size: 100,
					...(isInjectible !== null && { is_injectible: isInjectible })
				}));
				const options: Option[] = [];
				if (name === 'presentation' && InjectiblesLabel.toLocaleLowerCase().includes(debounceValue.toLowerCase())) {
					options.push({
						name: InjectiblesLabel
					});
				}
				options.push(...data.results.map(e => ({
					...e,
					name: e.name || e.description
				})));
				setInputOptions(options);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		}
		// if (debounceValue && debounceValue.length >= 2) {
		getData(debounceValue);
		// }
	}, [debounceValue, dataService, parentValue, isInjectible]);

	const onChangeHandler = (e, selectedValues) => {
		// Get the last selected item (the one just clicked)
		const lastSelected = selectedValues[selectedValues.length - 1];

		if (lastSelected === undefined) {
			// case when selectedValues are empty
			onChange && onChange({ target: { name, value: selectedValues } });
			return;
		} else {
			// Check if the item already exists in the current value
			const exists = value.some((option) => option.name === lastSelected.name);

			let updatedValues;
			if (exists) {
				// Remove the item if it exists
				updatedValues = value.filter((option) => option.name !== lastSelected.name);
			} else {
				// Add the item if it does not exist
				updatedValues = [...value, lastSelected];
			}

			// Additional logic for the 'Injectibles' label
			if (name === 'presentation' && updatedValues.some((option) => option.name === InjectiblesLabel)) {
				// Exclude injectible options from presentations when "Injectibles" is selected
				setIsInjectible(false);
			}

			// Trigger the onChange callback with the updated values
			onChange && onChange({ target: { name, value: updatedValues } });
		}
	};


	const onSearchChange = (e) => setSearch(e.target.value);

	const onInputChange = (e, value, reason) => {
		if (reason === 'clear') setSearch('');
	};

	return (
		<Box>
			<MatFormLabel id={name}>
				{label}
				{labelInfo && <Box sx={{ ml: 'auto' }}>{labelInfo}</Box>}
			</MatFormLabel>
			<Autocomplete
				multiple
				freeSolo
				open={open}
				onOpen={() => setOpen(true)}
				// onClose={() => setOpen(false)}
				onBlur={() => setOpen(false)}
				onKeyDown={(e) => {
					if (e.code === 'Escape') {
						setOpen(false);
					}
				}}
				loading={loading}
				fullWidth={fullWidth}
				size={size}
				inputValue={search}
				onInputChange={onInputChange}
				value={value}
				onChange={onChangeHandler}
				options={inputOptions}
				getOptionLabel={(option: any) => option.name}
				renderTags={() => null}
				filterOptions={() => inputOptions}
				renderOption={(props, option: any, rest) => (
					<ListItem
						key={option.name + rest.index}
						value={option.name}
						{...props}
					>
						<Box display='flex' alignItems='center'>
							<Checkbox
								checked={value.some((val) => val.name === option.name)}
								style={{ pointerEvents: 'none' }}
							/>
							<Typography variant='body1' sx={{ fontSize: '14px' }}>
								{option.name}
							</Typography>
						</Box>
					</ListItem>
				)}
				renderInput={(params) => (
					<TextFieldWrapper
						onChange={onSearchChange}
						placeholder={placeholder}
						sx={{
							maxHeight: size === MaterialUISizeEnum.SMALL
								? SMALL_VARIANT_INPUT_HEIGHT
								: undefined,
							fontSize: size === MaterialUISizeEnum.SMALL
								? SMALL_VARIANT_FONT_SIZE
								: undefined,
							borderRadius: MEDIUM_BORDER_RADIUS,
							backgroundColor: COLORS.white,
						}}
						{...params}
					/>
				)}
				sx={{
					maxHeight: size === MaterialUISizeEnum.SMALL
						? SMALL_VARIANT_INPUT_HEIGHT
						: undefined,
					fontSize: size === MaterialUISizeEnum.SMALL
						? SMALL_VARIANT_FONT_SIZE
						: undefined,
					borderRadius: MEDIUM_BORDER_RADIUS,
					backgroundColor: COLORS.white,
				}}
			/>
		</Box>
	);
};
