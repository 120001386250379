import { QueryKeysEnum } from 'models/enums';
import { useQuery } from 'react-query';
import { getATCCode, getATCCodes } from 'services/ATCCodesServices';

export const useATCCode = ({ params }) => {
	const { uid } = params;

	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[QueryKeysEnum.ATC_CODE, uid],
		() => getATCCode({ params }),
		{ enabled: !!uid }
	);
	return { data, error, isLoading, isFetching, isFetched };
};

export const useATCCodes = ({ queryParams }, enabled) => {
	const { parent_id, country, ordering, page, page_size, search } = queryParams;
	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[
			QueryKeysEnum.ATC_CODES,
			parent_id,
			country,
			ordering,
			page,
			page_size,
			search,
		],
		() => getATCCodes({ queryParams }),
		{ enabled }
	);
	return { data, error, isLoading, isFetching, isFetched };
};
