import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, styled } from '@mui/material';

import { useAppData, useUser } from 'context';
import { useMyWDACompany } from 'services/queries';

import { COLORS } from 'utils/consts';
import { objectFilterHandler } from 'utils';
import { ApplicationRoutesEnum } from 'models/enums';

import { cardFields } from './cardFields';
import { RequestCompanyFeature } from './RequestCompanyFeature';

import { BaseCard } from 'components/Cards';
import { NoIcon, YesIcon } from 'components/Icons';
import { HeaderTitle } from 'components/StyledComponents';

interface ErrorType extends Error {
	status?: string | number;
}

const StyledLink = styled(Link)(({ disabled, color }: any) => ({
	color: disabled ? '#ccc' : color || 'unset',
	pointerEvents: disabled ? 'none' : 'unset',
	textDecoration: 'none',
	display: 'flex',
	alignItems: 'center',
	border: `1.5px solid ${COLORS.blue}`,
	borderRadius: '10px',
	padding: '10px 20px',
	gap: '5px'
}));

const HorizontalList = styled(List)<{ component?: React.ElementType }>({
	display: 'flex',
	columnGap: '40px',
	flexWrap: 'wrap',
	'& .MuiListItem-root': {
		width: 'auto',
		padding: '0 20px 0 0',
	},
	'& .MuiListItemIcon-root': {
		minWidth: 0,
		marginRight: 16,
	},
});

const VerticalList = styled(List)<{ component?: React.ElementType }>({
	'& .MuiListItem-root': {
		width: 'auto',
		padding: '0 20px 0 0',
	},
	'& .MuiListItemIcon-root': {
		minWidth: 0,
		marginRight: 16,
	},
});

export const CompanyProfile = (props) => {
	const { user } = useUser();
	const { setHeader, setHeaderButton, setHeaderElement } = useAppData();
	const [features, setFeatures] = useState<{ label: string, value: any }[]>([]);

	const {
		data: WDACompanyDetails,
		error,
		isLoading,
		isFetching,
		refetch
	} = useMyWDACompany();

	useEffect(() => {
		refetch();
	}, [refetch]);

	useEffect(() => {
		if ((error as ErrorType)?.status === 404) {
			// when company isn't assigned to user yet
			setHeader(user?.company?.name);
			setHeaderButton('');
			setHeaderElement(null);
		} else if (WDACompanyDetails) {
			setHeaderElement(
				<Grid container justifyContent={'space-between'} alignItems={'center'} xs={12}>
					<Grid item display={'flex'} alignItems={'center'} columnGap={2}>
						<Grid item alignItems={'start'} display={'flex'}>
							{WDACompanyDetails.logo && (
								<Box sx={{ maxWidth: '50px', display: 'inline-flex', marginRight: '10px' }}>
									<img
										style={{ maxWidth: '100%', maxHeight: '100%' }}
										alt={`${WDACompanyDetails.name} logo`}
										src={WDACompanyDetails.logo}
									/>
								</Box>
							)}
							<HeaderTitle component='h1'>
								{WDACompanyDetails.name}
							</HeaderTitle>
						</Grid>
						<Grid item>
							<StyledLink
								to={ApplicationRoutesEnum.EDIT_COMPANY_PROFILE}
								color={COLORS.blue}
							>
								<Typography variant='subtitle2' textTransform={'uppercase'}>Edit Profile</Typography>
							</StyledLink>
						</Grid>
					</Grid>
					{WDACompanyDetails?.wda_license?.link && (
						<Grid item>
							<StyledLink 
								to={WDACompanyDetails?.wda_license?.link}
								color={COLORS.blue}
								target='_blank'
							>
								<Typography variant='subtitle2' textTransform={'uppercase'}>View in EUDRA</Typography>
							</StyledLink>
						</Grid>
					)}
				</Grid>
			);

			const labels = {
				is_clinical_trial_supply: 'Clinical Trial Supply',
				is_parallel_trade: 'Parallel trade',
				is_import_export: 'Import/Export',
				is_tenders: 'Tenders',
				is_unlicensed_medicines: 'Unlicensed medicines'
			};
			const filtered_object = objectFilterHandler(
				WDACompanyDetails,
				(key, val) => Object.keys(labels).includes(key) && val !== null
			);
			const featuresArr = Object.keys(filtered_object).map(key => ({
				label: labels[key],
				value: filtered_object[key]
			}));
			setFeatures(featuresArr);
		}

	}, [WDACompanyDetails, error, setHeader, setHeaderButton, setHeaderElement, user?.company?.name]);

	return (error as ErrorType)?.status === 404 ? (
		<RequestCompanyFeature />
	) : isLoading ? (<>loading</>) : WDACompanyDetails ? (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<BaseCard
					loading={isLoading || isFetching}
					dataItem={WDACompanyDetails}
					cardFields={cardFields}
				/>
			</Grid>
			{(WDACompanyDetails?.description || WDACompanyDetails?.wda_license?.details) && (
				<Grid item xs={12}>
					<Paper sx={{ borderRadius: '10px', paddingTop: '20px', paddingBottom: '40px' }} elevation={0}>
						<Container maxWidth={false}>
							<Typography variant="h6" component='h2' sx={{ marginBottom: '20px' }}>
								About the company
							</Typography>
							<Typography>
								{WDACompanyDetails.description || WDACompanyDetails.wda_license.details}
							</Typography>
						</Container>
					</Paper>
				</Grid>
			)}
			{features && features.length > 0 && (
				<Grid item xs={12}>
					<Paper sx={{ borderRadius: '10px', paddingTop: '20px', paddingBottom: '20px' }} elevation={0}>
						<Container maxWidth={false}>
							<Typography variant="subtitle1" component='h3' sx={{ marginBottom: '20px', fontWeight: 500 }}>
								Options that company offer:
							</Typography>
							<HorizontalList>
								{features.map(item => (
									<ListItem key={item.label}>
										<ListItemIcon>
											{item.value ? (
												<YesIcon strokeWidth={1.5} />
											) : (typeof item.value === 'boolean' && !item.value) ? (
												<NoIcon strokeWidth={1.5} stroke={COLORS.red} />
											) : null}
										</ListItemIcon>
										<ListItemText
											primary={item.label}
											primaryTypographyProps={{
												variant: 'subtitle1',
												component: 'span',
												fontWeight: 500
											}}
										/>
									</ListItem>
								))}
							</HorizontalList>
						</Container>
					</Paper>
				</Grid>
			)}
			{(WDACompanyDetails?.wda_license?.wda_authorizations && WDACompanyDetails.wda_license.wda_authorizations.length > 0) && (
				<Grid item xs={12}>
					<Typography variant="subtitle1" component='h3' sx={{ marginBottom: '20px', fontWeight: 500, fontSize: '14px' }}>
						Licenses conditions
					</Typography>
					<Paper sx={{ borderRadius: '10px', paddingTop: '20px', paddingBottom: '20px' }} elevation={0}>
						<Container maxWidth={false}>
							<VerticalList>
								{WDACompanyDetails.wda_license.wda_authorizations.map(item => (
									<ListItem key={item.id}>
										<ListItemIcon>
											<YesIcon strokeWidth={1.5} />
										</ListItemIcon>
										<ListItemText
											primary={`${item.code} ${item.description}`}
											primaryTypographyProps={{
												variant: 'subtitle1',
												component: 'span'
											}}
										/>
									</ListItem>
								))}
							</VerticalList>
						</Container>
					</Paper>
				</Grid>
			)}
		</Grid>
	) : <></>;
};
