/* eslint-disable @typescript-eslint/no-empty-function */
import { ReactNode, createContext, useContext } from 'react';

interface IAppData {
  isLoading: boolean;
  headerTitle: string | ReactNode;
  headerDescription: string | null;
  headerBtn: ReactNode | string | null;
  headerEl: ReactNode | string | null;
  setHeader: (x: string | ReactNode) => void;
  setHeaderDesc: (x: string | null) => void;
  setHeaderButton: (x: string | ReactNode) => void;
  setHeaderElement: (x: string | ReactNode) => void;
  setLoading: (x: boolean) => void;
}

export const AppDataContext = createContext<IAppData>({
	isLoading: false,
	headerTitle: '',
	headerDescription: null,
	headerBtn: '',
	headerEl : '',
	setLoading: () => { },
	setHeader: () => { },
	setHeaderDesc: () => { },
	setHeaderButton: () => { },
	setHeaderElement: () => { },
});

export const useAppData = () => {
	const context = useContext(AppDataContext);
	return context;
};
