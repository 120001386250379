import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { COLORS } from 'utils/consts';
import { InfoCard } from 'components/Cards';
import { useDashboardStats } from 'hooks';
import  BackgroundLogo from 'assets/icons/logo-bg.svg';
import { StyledBtnLink } from 'components/StyledComponents';


export const FreeHome = () => {
	const {stats, dates, loading} = useDashboardStats();

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Typography
					color={COLORS.black}
					fontWeight={600}
					variant={'h2'}
					fontSize={15}
				>
					Week commencing {dates.start.format('Do MMMM')} 
				</Typography>
				<Grid container gap={3} sx={{ marginTop: '20px' }}>
					{stats?.map(item => (
						<InfoCard
							key={item.label}
							number={item.number}
							color={COLORS.blue}
							label={item.label}
							labelProps={{ sx: { maxWidth: item.max_width } }}
							loading={loading}
						/>
					))}
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Paper 
					elevation={0}
					sx={{ 
						borderRadius: '10px', 
						padding: '20px 20px 60px',
						minHeight: 'calc(100vh - 250px)',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}} 
				>
					<Box 
						maxWidth={'520px'} 
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '20px',
							backgroundImage: `url(${BackgroundLogo})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'auto',
							backgroundPosition: 'top left',
							padding: '70px 0 0'
						}}
					>
						<Typography variant='subtitle2' component='h2' fontSize={20} marginBottom={'10px'} textAlign={'center'}>
							Welcome to Pharma Footpath!
						</Typography>
						<Typography>
							You have the opportunity to enhance your experience by gaining access to two valuable features:
						</Typography>
						<Typography component={'ul'}>
							<Typography component={'li'}>
								Daily shortage announcements
							</Typography>
							<Typography component={'li'}>
								Detailed notes about the medicaments.
							</Typography>
						</Typography>
						<Typography>
							These exclusive benefits are reserved for our paid customers. Upgrade your account today to unlock these crucial updates and insights, empowering you with the information to stay ahead in the industry.
						</Typography>
						<Typography>
							Set up a call to discuss upgrade options.
						</Typography>
						<Box justifyContent={'center'} display={'flex'}>
							<StyledBtnLink
								to='https://calendly.com/phil-pharmafootpath/30min'
								target='_blank'
								color={COLORS.blue}
								style={{
									textTransform: 'uppercase', 
									fontSize: '14px',
									maxWidth: '290px',
								}}
							>
							Let&apos;s discuss further
							</StyledBtnLink>
						</Box>
					</Box>
				</Paper>
			</Grid>
		</Grid>
	);
};
