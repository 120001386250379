import * as Yup from 'yup';

export const initialFormValues = {
	first_name: '',
	last_name: '',
	email: '',
	company_name: '',
	password: '',
	confirmPassword: '',
};

export const registerSchema = Yup.object().shape({
	first_name: Yup.string().required('Required'),
	last_name: Yup.string().required('Required'),
	email: Yup.string().email('Invalid email').required('Required'),
	company_name: Yup.string(),
	password: Yup.string().min(8, 'This password is too short.').max(50, 'This password is too long.').required('Required'),
	confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
