
import React, { useCallback, useMemo, useState, SyntheticEvent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, InputAdornment, Tabs, Tab, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';

import { useQueryPagination } from 'hooks';
import { useUser, useNotifications, useAppData } from 'context';
import { Modal } from 'components/Modal/Modal';
import { BaseButton, SettingsButton } from 'components/Buttons';
import { RemoteDataTable } from 'components/Tables';
import { DeleteActivitiesModalContent } from '../shared/DeleteActivitiesModalContent/DeleteActivitiesModalContent';
import { EnableUserModalContent } from '../shared/EnableUserModalContent/EnableUserModalContent';
import { DisableUserModalContent } from '../shared/DisableUserModalContent/DisableUserModalContent';
import { CreateUserModalContent } from '../shared/CreateUserModalContent/CreateUserModalContent';
import { UpdateUserModalContent } from '../shared/UpdateUserModalContent/UpdateUserModalContent';

import { usersColumns, notesColumns } from './columns';
import { ApplicationRoutesEnum, MaterialUISizeEnum, NotificationsTypeEnum } from 'models/enums';
import { BaseDatePicker, BaseInput } from 'components/Inputs';
import { COLORS } from 'utils/consts';
import { NotesToolbarActions } from './shared';
import { useCompanyUsers, useMutateCompanySubuser, useMutateNotes, useNotes } from 'services/queries';

const initialFilters = { userName: '', created_at: null };

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const StyledLink = styled(Link)(({ disabled, color }: any) => ({
	color: disabled ? '#ccc' : color || 'unset',
	pointerEvents: disabled ? 'none' : 'unset',
	textDecoration: 'none',
	display: 'flex',
	alignItems: 'center',
	border: `1.5px solid ${COLORS.blue}`,
	borderRadius: '10px',
	padding: '10px 20px',
	gap: '5px'
}));

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
};

export const AdministratorHome = () => {
	const { setHeaderButton } = useAppData();
	const [showFilters, setShowFilters] = useState(false);
	const [searchInputs, setSearchInputs] = useState(initialFilters);
	const { addNotification } = useNotifications();
	const { user, userCompany } = useUser();

	const { deleteNotesAsync, deleteNotesLoading } = useMutateNotes();
	const { postCompanySubuserAsync, putCompanySubuserAsync, postDisableCompanySubuserAsync, postEnableCompanySubuserAsync } =
    useMutateCompanySubuser();
	const adminNotesQueryParams = useMemo(() => ({ created_at: searchInputs?.created_at }), [searchInputs.created_at]);
	const {
		data: notesData,
		state: notesState,
		setPagination: notesSetPagination,
		rowCount: notesRowCount,
		pageCount: notesPageCount,
		setPageCount: notesSetPageCount,
		// @ts-ignore
	} = useQueryPagination(useNotes, searchInputs.userName, null, adminNotesQueryParams);

	const [createUserModal, setCreateUserModal] = useState(false);
	const [userToBeEnabled, setUserToBeEnabled] = useState<{
    first_name: string;
    last_name: string;
    email: string;
  } | null>(null);
	const [userToBeDisabled, setUserToBeDisabled] = useState<{
    first_name: string;
    last_name: string;
    email: string;
  } | null>(null);
	const [noteForDeletion, setNoteForDeletion] = useState<any | null>(null);
	const [userForUpdate, setUserForUpdate] = useState<{
    first_name: string;
    last_name: string;
    email: string;
  } | null>(null);

	const onCreateUserModal = () => setCreateUserModal((prevState) => !prevState);
	const onEnableUser = useCallback((userData) => setUserToBeEnabled(userData), []);
	const onDisableUser = useCallback((userData) => setUserToBeDisabled(userData), []);
	const onUpdateUser = useCallback((userData) => setUserForUpdate(userData), []);
	const onDeleteNote = useCallback((noteData) => setNoteForDeletion(noteData), []);

	const activeUsersQueryParams = useMemo(() => ({ is_active: true }), []);
	const activeUsersParams = useMemo(() => ({ uid: user?.company?.id }), [user]);
	const {
		data: activeUsersData,
		state: activeUsersState,
		setPagination: activeUsersSetPagination,
		rowCount: activeUsersRowCount,
		setSorting: activeUsersSorting,
		pageCount: activeUsersPageCount,
		setPageCount: activeUsersSetPageCount,
	} = useQueryPagination(useCompanyUsers, null, activeUsersParams, activeUsersQueryParams);

	const disabledUsersQueryParams = useMemo(() => ({ is_active: false }), []);
	const disabledUsersParams = useMemo(() => ({ uid: user?.company?.id }), [user]);
	const {
		data: disabledUsersData,
		state: disabledUsersState,
		setPagination: disabledUsersSetPagination,
		rowCount: disabledUsersRowCount,
		setSorting: disabledUsersSorting,
		pageCount: disabledUsersPageCount,
		setPageCount: disabledUsersSetPageCount,
	} = useQueryPagination(useCompanyUsers, null, disabledUsersParams, disabledUsersQueryParams);

	const [value, setValue] = useState(0);  

	useEffect(()=>{
		setHeaderButton(
			<StyledLink 
				to={ApplicationRoutesEnum.COMPANY_PROFILE}
				color={COLORS.blue}
			>
				<Typography variant='subtitle2' textTransform={'uppercase'}>Company Profile</Typography>
			</StyledLink>
		);
	},[setHeaderButton]);

	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const onChangeHandler = useCallback(
		(e) =>
			setSearchInputs((prevInputs) => ({
				...prevInputs,
				[e.target.name]: e.target.value,
			})),
		[]
	);

	const onToggleFilters = () => {
		setShowFilters(!showFilters);
		if (showFilters) {
			setSearchInputs(initialFilters);
		}
	};

	const onCreateUserHandeler = async (userData: any, { setSubmitting, resetForm, setErrors }: any) => {
		try {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
			const createdUser = await postCompanySubuserAsync({ companyId: user?.company?.id!, userData });
			resetForm();
			if(createdUser){
				addNotification({
					message: `User: ${createdUser.first_name! + ' ' + createdUser.last_name!}' created successfully`,
					type: NotificationsTypeEnum.SUCCESS,
				});
				onCreateUserModal();
			}
		} finally {
			setSubmitting(false);
		}
	};

	const onUpdateUserHandeler = async (userData: any, { setSubmitting, resetForm, setErrors }: any) => {
		try {
			user?.company?.id && (await putCompanySubuserAsync({ companyId: user.company.id, userData }));
			resetForm();
			userForUpdate && addNotification({
				message: `User: ${userForUpdate.first_name! + ' ' + userForUpdate.last_name!}' updated successfully`,
				type: NotificationsTypeEnum.SUCCESS,
			});
			setUserForUpdate(null);
		} finally {
			setSubmitting(false);
		}
	};

	const onDisableUserHandeler = async (userId) => {
		try {
			user?.company?.id && (await postDisableCompanySubuserAsync({ companyId: user.company.id, userId }));
			userToBeDisabled && addNotification({
				message: `User: ${userToBeDisabled.first_name! + ' ' + userToBeDisabled.last_name!}' disabled successfully`,
				type: NotificationsTypeEnum.SUCCESS,
			});
			setUserToBeDisabled(null);
		} catch (err: any) {
			console.log(err);
		}
	};

	const onEnableUserHandler = async (userId) => {
		try {
			user?.company?.id && (await postEnableCompanySubuserAsync({ companyId: user.company.id, userId }));

			userToBeEnabled && addNotification({
				message: `User: ${userToBeEnabled.first_name! + ' ' + userToBeEnabled.last_name!}' enabled successfully`,
				type: NotificationsTypeEnum.SUCCESS,
			});
			setUserToBeEnabled(null);
		} catch (err: any) {
			console.log(err);
		}
	};

	const onDeleteNoteHandler = async (noteId) => {
		try {
			await deleteNotesAsync({ params: { uid: noteId } });

			addNotification({ message: 'Note deleted successfully', type: NotificationsTypeEnum.SUCCESS });
			setNoteForDeletion(null);
		} catch (err: any) {
			console.log('err', err);
		}
	};

	const onDeleteMultiple = async (noteIds: string[]) => {
		try {
			await Promise.all(noteIds.map((noteId) => deleteNotesAsync({ params: { uid: noteId } })));

			addNotification({ message: 'Notes deleted successfully', type: NotificationsTypeEnum.SUCCESS });
		} catch (err: any) {
			console.log('err', err);
		}
	};

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	const areLicensesAvailable = useMemo(() => {
		return userCompany && activeUsersRowCount < userCompany?.limit_number_of_users;
	}, [userCompany, activeUsersRowCount]);
	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Box display='flex' alignItems='flex-start'>
					<Typography>
						<strong>Number of User Licenses ({userCompany ? userCompany.limit_number_of_users : 0})</strong>
					</Typography>

					<Box sx={{ ml: 'auto' }}>
						<BaseButton
							disabled={!areLicensesAvailable}
							onClick={onCreateUserModal}
							startIcon={<AddIcon />}
							sx={{ paddingLeft: 6, paddingRight: 6 }}
							label='Create User'
						/>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Paper elevation={0}>
					<Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
						<Tab
							label='Active Users'
							{...a11yProps(0)}
							sx={{
								fontWeight: 600,
								fontSize: '0.8rem',
								padding: '1.2rem 1.5rem',
							}}
						/>
						<Tab
							label='Deactivated Users'
							{...a11yProps(1)}
							sx={{
								fontWeight: 600,
								fontSize: '0.8rem',
								padding: '1.2rem 1.5rem',
							}}
						/>
					</Tabs>
					<TabPanel value={value} index={0}>
						<RemoteDataTable
							state={activeUsersState}
							data={activeUsersData}
							pageCount={activeUsersPageCount}
							onRowsPerPageChange={activeUsersSetPageCount}
							onPaginationChange={activeUsersSetPagination}
							rowCount={activeUsersRowCount}
							columns={usersColumns}
							enableRowSelection={false}
							enablePagination
							manualSorting
							enableFullScreenToggle={false}
							enableMultiSort={false}
							onSortingChange={activeUsersSorting}
							onEdit={onUpdateUser}
							onDelete={onDisableUser}
						/>
					</TabPanel>
					<TabPanel value={value} index={1}>
						<RemoteDataTable
							state={disabledUsersState}
							data={disabledUsersData}
							pageCount={disabledUsersPageCount}
							onRowsPerPageChange={disabledUsersSetPageCount}
							onPaginationChange={disabledUsersSetPagination}
							rowCount={disabledUsersRowCount}
							columns={usersColumns}
							enableRowSelection={false}
							enablePagination
							manualSorting
							enableFullScreenToggle={false}
							enableMultiSort={false}
							onSortingChange={disabledUsersSorting}
							onEnable={areLicensesAvailable ? onEnableUser : undefined}
						/>
					</TabPanel>
				</Paper>
			</Grid>
			<Grid container item xs={12} spacing={2}>
				<Grid item xs={6}>
					<Typography>
						<strong>Activity Feed</strong>
					</Typography>
				</Grid>

				<Grid container item xs={6} spacing={2} display='flex' justifyContent='end'>
					{showFilters && (
						<>
							<Grid item xs={5}>
								<BaseInput
									onChange={onChangeHandler}
									value={searchInputs.userName}
									name='userName'
									placeholder='Search by name'
									fullWidth
									inputProps={{
										sx: { backgroundColor: COLORS.white },
										startAdornment: (
											<InputAdornment position='start'>
												<SearchIcon />
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={5}>
								<BaseDatePicker
									label='Choose a Date'
									onChange={onChangeHandler}
									value={searchInputs.created_at}
									name='created_at'
									inputProps={{ sx: { backgroundColor: COLORS.white } }}
								/>
							</Grid>
						</>
					)}
					<Grid item xs={2} display='flex' justifyContent='end'>
						<SettingsButton
							onClick={onToggleFilters}
							size={MaterialUISizeEnum.SMALL}
							color={showFilters ? 'primary' : 'inherit'}
							sx={{
								color: showFilters ? COLORS.white : undefined,
								backgroundColor: showFilters ? COLORS.primary : COLORS.mediumLightGray,
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<RemoteDataTable
					state={notesState}
					data={notesData}
					pageCount={notesPageCount}
					onRowsPerPageChange={notesSetPageCount}
					onDelete={onDeleteNote}
					onPaginationChange={notesSetPagination}
					rowCount={notesRowCount}
					columns={notesColumns}
					enableRowSelection
					enablePagination
					renderCustomActions={({ table }) => <NotesToolbarActions table={table} onDeleteMultiple={onDeleteMultiple} />}
				/>
			</Grid>

			{/* Action Modals */}
			{/* Company Users Modals */}
			<Modal modalTitle='Create user?' show={createUserModal} width='600px'>
				<CreateUserModalContent onSubmit={onCreateUserHandeler} onCancel={onCreateUserModal} />
			</Modal>
			<Modal modalTitle='Update user?' show={userForUpdate} width='600px'>
				<UpdateUserModalContent
					userForEdit={userForUpdate}
					onSubmit={onUpdateUserHandeler}
					onCancel={() => setUserForUpdate(null)}
				/>
			</Modal>
			<Modal modalTitle='Enable user?' show={userToBeEnabled}>
				<EnableUserModalContent
					userToBeEnabled={userToBeEnabled}
					onSubmit={onEnableUserHandler}
					onCancel={() => setUserToBeEnabled(null)}
				/>
			</Modal>
			<Modal modalTitle='Disable user?' show={userToBeDisabled}>
				<DisableUserModalContent
					userToBeDisabled={userToBeDisabled}
					onSubmit={onDisableUserHandeler}
					onCancel={() => setUserToBeDisabled(null)}
				/>
			</Modal>

			{/* Activities Feed Modals */}
			<Modal modalTitle='Delete activities' show={noteForDeletion}>
				<DeleteActivitiesModalContent
					noteForDeletion={noteForDeletion}
					onSubmit={onDeleteNoteHandler}
					onCancel={() => setNoteForDeletion(null)}
				/>
			</Modal>
		</Grid>
	);
};
