import AuthClient from './Clients/AuthClient';
import { generateQuery } from 'utils';
import {
	GetActiveIngredientNotesParamsInterface,
	GetEuProductParamsInterface,
	GetNationalProductNotesParamsInterface,
	GetNationalProductParamsInterface,
	PostActiveIngredientNoteBodyInterface,
	PostNationalProductNoteBodyInterface,
	GetAuthorisedPresentationINterface,
	GetProductPresentationInterface,
	GetProductRegistrationsInterface,
} from 'models/interfaces';

//National Products endpoints
const productRegistrationsServicesUrl = '/v2/registrations/';

export const getProductRegistrations = async ({
	queryParams,
}: {
  queryParams?: GetProductRegistrationsInterface;
}) => {
	const productsResponse = await AuthClient.get(
		productRegistrationsServicesUrl + generateQuery(queryParams)
	);
	return productsResponse.data;
};

export const getProductRegistration = async ({
	id,
	queryParams,
}: {
  id: number | string;
  queryParams?: GetProductPresentationInterface;
}) => {
	const prodRegistrationResponse = await AuthClient.get(
		`${productRegistrationsServicesUrl}` +
      generateQuery(queryParams) +
      `${generateQuery(queryParams) ? '&' : '?'}pack=${id}`
	);
	return prodRegistrationResponse.data;
};

export const getProductRegistrationNotes = async ({
	params,
	queryParams,
}: {
  params: any;
  queryParams?: GetNationalProductNotesParamsInterface;
}) => {
	const productsResponse = await AuthClient.get(
		`${productRegistrationsServicesUrl}${params.id}/notes/` +
      generateQuery(queryParams)
	);

	return {
		...productsResponse.data,
		results: productsResponse.data.results.map((item) => ({
			...item,
			user_name: `${item.user.first_name} ${item.user.last_name}`,
			user_avatar: item.user.avatar,
		})),
	};
};

//National Products endpoints
const productPresentationsServicesUrl = '/v2/representations/';

export const getProductPresentations = async ({
	queryParams,
}: {
  queryParams?: GetProductPresentationInterface;
}) => {
	const productsResponse = await AuthClient.get(
		productPresentationsServicesUrl + generateQuery(queryParams)
	);
	return productsResponse.data;
};

export const getProductPresentation = async (
	id: number | string,
	queryParams?: GetNationalProductParamsInterface
) => {
	const productsResponse = await AuthClient.get(
		`${productPresentationsServicesUrl}${id}/registration_number/` +
      generateQuery(queryParams)
	);
	return productsResponse.data;
};

export const getProductPresentationDetails = async ({
	params,
	queryParams,
}: {
  params?: any;
  queryParams?: GetNationalProductParamsInterface;
}) => {
	const productsResponse = await AuthClient.get(
		`${productPresentationsServicesUrl}${params.id}/registration_number/` +
      generateQuery(queryParams)
	);
	return productsResponse.data;
};

const productsServicesUrlV2 = '/v2/products/';

export const getProductV2 = async ({ params }) => {
	const productsResponse = await AuthClient.get(
		`${productsServicesUrlV2}${params.uid}/`
	);
	return productsResponse.data;
};

const productsServicesUrl = '/v1/products/';

export const getProducts = async ({
	queryParams,
}: {
  queryParams?: GetNationalProductParamsInterface;
}) => {
	const productsResponse = await AuthClient.get(
		productsServicesUrl + generateQuery(queryParams)
	);
	return productsResponse.data;
};

export const getProduct = async (params) => {
	const productsResponse = await AuthClient.get(
		`${productsServicesUrl}${params.uid}/`
	);
	return productsResponse.data;
};

export const getProductNotes = async ({
	params,
	queryParams,
}: {
  params: any;
  queryParams?: GetNationalProductNotesParamsInterface;
}) => {
	const productsResponse = await AuthClient.get(
		`${productsServicesUrlV2}${params.id}/notes/` + generateQuery(queryParams)
	);

	return {
		...productsResponse.data,
		results: productsResponse.data.results.map((item) => ({
			...item,
			user_name: `${item.user.first_name} ${item.user.last_name}`,
			user_avatar: item.user.avatar,
		})),
	};
};

export const postProductNotes = async (
	id: string | number | undefined,
	body: PostNationalProductNoteBodyInterface
) => {
	const productsResponse = await AuthClient.post(
		`${productRegistrationsServicesUrl}${id}/notes/`,
		body
	);
	return {
		...productsResponse.data,
		user_name: `${productsResponse.data.user.first_name} ${productsResponse.data.user.last_name}`,
		user_avatar: productsResponse.data.user.avatar,
	};
};

//EU Authorized Products endpoints
const euAuthorizedProductsServicesUrl = '/v1/eu-authorized-products/';

export const getEuAuthorizedProducts = async (
	queryParams?: GetEuProductParamsInterface
) => {
	const productsResponse = await AuthClient.get(
		euAuthorizedProductsServicesUrl + generateQuery(queryParams)
	);
	return productsResponse.data;
};

export const getEuAuthorizedProduct = async (id: number) => {
	const productsResponse = await AuthClient.get(
		`${euAuthorizedProductsServicesUrl}${id}/`
	);
	return productsResponse.data;
};

export const getAuthorisedPresentation = async (
	queryParams?: GetAuthorisedPresentationINterface
) => {
	const authorizedPresentationResponse = await AuthClient.get(
		euAuthorizedProductsServicesUrl + generateQuery(queryParams)
	);
	return authorizedPresentationResponse.data;
};

//Active Ingredirents endpoints
const activeIngredientsServicesUrl = '/v1/active-ingredients/';

export const getActiveIngredients = async ({
	queryParams,
}: {
  queryParams?: GetActiveIngredientNotesParamsInterface;
}) => {
	const productsResponse = await AuthClient.get(
		activeIngredientsServicesUrl + generateQuery(queryParams)
	);
	return productsResponse.data;
};

export const getActiveIngredient = async (id: number) => {
	const productsResponse = await AuthClient.get(
		`${activeIngredientsServicesUrl}${id}/`
	);
	return productsResponse.data;
};

export const getActiveIngredientNotes = async (
	params: any,
	queryParams?: GetActiveIngredientNotesParamsInterface
) => {
	const { id } = params;
	const productsResponse = await AuthClient.get(
		`${activeIngredientsServicesUrl}${id}/notes/` + generateQuery(queryParams)
	);
	return productsResponse.data;
};

export const postActiveIngredientNotes = async (
	id: number | string,
	body: PostActiveIngredientNoteBodyInterface
) => {
	const productsResponse = await AuthClient.post(
		`${activeIngredientsServicesUrl}${id}/notes/`,
		body
	);
	return productsResponse.data;
};
