/* eslint-disable react/prop-types */
import React, { FC, useMemo } from 'react';
import { styled } from '@mui/material';
import MaterialReactTable from 'material-react-table';

import { CustomTableCell } from '../shared/CustomCells';
import { ActionCell } from '../shared/CustomCells/ActionCell/ActionCell';
import { COLORS } from 'utils/consts';
import { TableCustomCellEnum } from 'models/enums';
import { MEDIUM_BORDER_RADIUS } from 'utils/consts/themeConsts';

interface BaseTableInterface {
  columns?: any;
  data: any[];
  onEdit?: (x: string | number) => void;
  onDelete?: (x: string | number) => void;
  // Table settings
  enableSorting?: boolean;
  enableRowSelection?: boolean;
  enableGlobalFilter?: boolean;
  enableFullScreenToggle?: boolean;
  enableDensityToggle?: boolean;
  enableHiding?: boolean;
  enablePagination?: boolean;
  // Custom values
  lastColumnAlignRight?: boolean;
  [key: string | number | symbol]: any;
}

const MaterialReactTableWrapper = styled('div')(
	({ tableShadow }: { tableShadow?: boolean }) => ({
		backgroundColor: COLORS.white,
		borderRadius: MEDIUM_BORDER_RADIUS,
		overflow: 'hidden',
		'& > .MuiPaper-root': {
			boxShadow: tableShadow ? '0 3px 10px rgb(0 0 0 / 0.2)' : 'none',
			borderRadius: MEDIUM_BORDER_RADIUS,
			'& > .MuiTableContainer-root': {
				borderRadius: MEDIUM_BORDER_RADIUS,
			},
		},
	})
);

export const BaseTable: FC<BaseTableInterface> = ({
	data,
	columns,
	onEdit,
	onDelete,

	enableSorting = true,
	enableRowSelection = true,
	enableGlobalFilter = false,
	enableFullScreenToggle = false,
	enableDensityToggle = false,
	enableHiding = false,
	enablePagination = false,

	lastColumnAlignRight = false,
	...props
}) => {
	const tableColumns = useMemo(
		() =>
			columns.map((column, index, columnsArr) => ({
				...column,
				id: column.title,
				muiTableHeadCellProps: {
					align: lastColumnAlignRight && column.cellType === TableCustomCellEnum.ACTION
						? 'right'
						: undefined,
				},
				Cell: ({ cell }) =>
					column.cellType === TableCustomCellEnum.ACTION ? (
						<ActionCell
							{...cell}
							column={column}
							onEdit={onEdit}
							onDelete={onDelete}
						/>
					) : (
						<CustomTableCell {...cell} column={column} />
					),
			})),
		[onEdit, onDelete, columns, lastColumnAlignRight]
	);

	return (
		<MaterialReactTableWrapper>
			<MaterialReactTable
				data={data}
				columns={tableColumns}
				// Global Table options start
				enableSorting={enableSorting}
				enableRowSelection={enableRowSelection}
				// Global Table options end

				// Bottom ToolbarProps start
				enableBottomToolbar={enablePagination}
				enablePagination={enablePagination}
				// Bottom ToolbarProps end

				// Top ToolbarProps Start
				enableTopToolbar={
					props.renderTopToolbarCustomActions ||
          enableFullScreenToggle ||
          enableDensityToggle ||
          enableHiding ||
          enableGlobalFilter
				}
				enableGlobalFilter={enableGlobalFilter}
				enableFullScreenToggle={enableFullScreenToggle}
				enableHiding={enableHiding}
				enableDensityToggle={enableDensityToggle}
				// renderTopToolbarCustomActions={renderCustomActions}
				// Top ToolbarProps End

				muiTableBodyRowProps={{ hover: false }}
				muiTableProps={{ sx: { boxShadow: 'none' } }}
				enableColumnActions={false}
				enableColumnFilters={false}
				{...props}
			/>
		</MaterialReactTableWrapper>
	);
};
