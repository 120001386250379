import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { StyledLink } from 'components/StyledComponents';
import { styled } from '@mui/material/styles';

interface PriceHistoryLinkCellProps {
  link: string;
  value?: string;
  icon?: ReactNode;
  dataItem: any;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	'& .MuiBadge-badge': {
		right: -10,
		top: 6,
		border: `2px solid ${theme.palette.success.light}`,
		backgroundColor: theme.palette.success.light,
		borderRadius: '50%',
		padding: '.1rem',
	},
}));

export const PriceHistoryLinkCell = ({
	link,
	value,
	icon,
	dataItem,
	...props
}: PriceHistoryLinkCellProps) => {
	const { pathname, search } = useLocation();

	return (
		<StyledBadge
			color='success'
			variant='dot'
			invisible={!dataItem?.is_price_available}
		>
			<StyledLink
				// @ts-ignore
				disabled={!dataItem?.is_price_available}
				to={{ pathname, search: search + '&' + link }}
			>
				{icon && <Box component='span'>{icon}</Box>}
			</StyledLink>
		</StyledBadge>
	);
};
