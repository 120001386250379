import React, { FC, ReactNode } from 'react';

import { IconButton } from '@mui/material';
import { MaterialUIButtonColorType, MaterialUIButtonSizeType } from 'models/types';

interface BaseButtonInterface {
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  color?: MaterialUIButtonColorType;
  size?: MaterialUIButtonSizeType;
  children: ReactNode;
  sx?: any;
}

// const ButtonSpinner = () => <CircularProgress color="inherit" size={"15px"} />;

export const BaseIconButton: FC<BaseButtonInterface> = ({
	color = 'primary',
	size = 'small',
	onClick,
	disabled,
	children,
	sx,
}) => {
	return (
		<IconButton disabled={disabled} onClick={onClick} color={color} size={size} sx={sx}>
			{children}
		</IconButton>
	);
};
