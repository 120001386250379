export enum TableCustomCellEnum {
  INDEX = 'index',
  DATA = 'data',
  PRICE = 'price',
  PRODUCT_LINK = 'productLink',
  ACTIVE_INGREDIENTS_LINK = 'activeIngredientsLink',
  LINK = 'link',
  EXTERNAL_LINK = 'externalLink',
  ACTION = 'action',
  AVATAR = 'avatar',
  ICON = 'icon',
  COUNTRY = 'country',
  MODAL_LINK = 'modalLink',
  STATUS = 'status',
}
