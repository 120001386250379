/* eslint-disable react/prop-types */
import React from 'react';

export const CloseIcon = ({ stroke = 'black', ...props }) => (
	<svg
		width='18'
		height='18'
		viewBox='0 0 18 18'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M6.14201 5.97498L12.192 12.025M6.14201 12.025L12.192 5.97498M16.998 8.99898C16.998 13.4161 13.4172 16.997 9.00001 16.997C4.58284 16.997 1.00201 13.4161 1.00201 8.99898C1.00201 4.5818 4.58284 1.00098 9.00001 1.00098C13.4172 1.00098 16.998 4.5818 16.998 8.99898Z'
			stroke={stroke}
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
