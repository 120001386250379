/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { Typography } from '@mui/material';
import { getNestedValue } from 'utils';
import { TypographyVariantType } from 'models/types';

export const ListItem = ({
	dataItem,
	item,
	color = 'inherit',
	fontWeight = 400,
	variant = 'body1',
	...props
}) => {
	if (item.value)
		return (
			<Typography
				color={color}
				fontWeight={fontWeight}
				variant={variant as TypographyVariantType}
			>
				{item.value}
			</Typography>
		);

	if (!dataItem) return null;
	const list = getNestedValue(dataItem, item.listFieldPath);
	return (
		<Fragment>
			{(Array.isArray(list) && list.length && list.map((val, index) => (
				<Typography
					key={val?.id || val || index}
					color={color}
					fontWeight={fontWeight}
					variant={variant as TypographyVariantType}
				>
					{getNestedValue(val, item.fieldPath)}
				</Typography>
			))) || '-'}
		</Fragment>
	);
};
