import { CustomInputTypeEnum } from 'models/enums/CustomInputTypeEnum';
import { getActiveIngredientsSuggestions } from 'services';

export const searchFilters = [
	{
		type: CustomInputTypeEnum.COUNTRY_AUTOCOMPLETE_STATIC,
		gridWrapper: 3,
		name: 'countries',
		label: 'Country',
		placeholder: 'Search country',
	},
	{
		type: CustomInputTypeEnum.AUTOCOMPLETE_REMOTE,
		gridWrapper: 3,
		name: 'active_ingredient',
		label: 'Search by active ingredient',
		placeholder: 'Search here',
		dataService: getActiveIngredientsSuggestions,
	},
	{
		type: CustomInputTypeEnum.SWITCH,
		gridWrapper: 3,
		name: 'closed_tenders',
		label: 'Include closed tenders',
	},
];
