/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { BaseButton } from 'components/Buttons';

export const DeleteActivitiesModalContent = ({ noteForDeletion, onSubmit, onCancel }) => {
	return (
		<Box>
			<Typography textAlign={'center'} mb={2}>
        Are you sure you want to delete activities from multiple users you checked?
			</Typography>
			<Box mb={2}>
				<BaseButton onClick={() => onSubmit(noteForDeletion.id)} label={'Confirm'} />
			</Box>
			<Box mb={2}>
				<BaseButton onClick={onCancel} variant={'outlined'} label={'Cancel'} />
			</Box>
		</Box>
	);
};
