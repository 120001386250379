import React from 'react';
import { FormLabel } from '@mui/material';

import { COLORS } from 'utils/consts';
import { SMALL_VARIANT_FONT_SIZE } from 'utils/consts/themeConsts';

export const MatFormLabel = ({ id, icon, children, ...props }: any) => (
	<FormLabel
		htmlFor={id}
		sx={{
			fontSize: SMALL_VARIANT_FONT_SIZE,
			mb: 2,
			fontWeight: 600,
			color: COLORS.black,
			display: 'flex',
			alignItems: 'flex-end',
			'&.MuiFormLabel-root.Mui-focused': {
				color: COLORS.black,
			},
		}}
		{...props}
	>
		{icon && <span style={{ display: 'flex', marginRight: 12 }}>{icon}</span>}
		{children}
	</FormLabel>
);
