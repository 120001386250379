import React from 'react';
import ReactDOM from 'react-dom';
import { Snackbar, Box, Alert, Fade } from '@mui/material';
import { useNotifications } from '../../../context';

export const NotificationAlerts = () => {
	const { notifications, removeNotification, fadeNotification } = useNotifications();

	const content = (
		<Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={notifications.length > 0}>
			<Box>
				{notifications.map((notification, index) => (
					<Fade in={notification.show} key={notification.id} onExited={removeNotification.bind(null, notification.id)}>
						<Box mb={1}>
							<Alert onClose={fadeNotification.bind(null, notification.id)} severity={notification?.type}>
								{notification?.message}
							</Alert>
						</Box>
					</Fade>
				))}
			</Box>
		</Snackbar>
	);
	return ReactDOM.createPortal(content, document.getElementById('notifications'));
};
