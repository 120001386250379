import React, { useCallback, useMemo } from 'react';
import { Box, Grid, Paper } from '@mui/material';

import { EU27_ID } from 'utils/consts';
import { searchFilters } from './searchFilters';
import { parallelColumns } from './columns';

import { RemoteDataTable } from 'components/Tables';
import { BaseCheckbox, SearchInput } from 'components/Inputs';
import { FormWithAdditionaFilters } from 'components/Forms';

import { useQueryPagination, useSearchParams } from 'hooks';
import { useParallelTradeLicenses } from 'services/queries';

const initialSearchInputs = {
	destination_countries: [],
	origin_countries: [],
	searchName: '',
	is_non_active_exclude: true,
	is_exclude_eu: false,
};

interface SearchInputsIntreface {
	destination_countries: { name: string; id: number; code: string | number }[];
	origin_countries: { name: string; id: number; code: string | number }[];
	searchName: string;
	is_non_active_exclude: boolean;
	is_exclude_eu: boolean;
}

export const ParallelTradeSearch = () => {
	const [searchInputs, setSearchInputs, resetSearchParams] = useSearchParams<SearchInputsIntreface>(
		initialSearchInputs
	);

	const ParallelQueryParams = useMemo(
		() => ({
			destination_countries__id__in: searchInputs.destination_countries
				.filter((e) => e.id + '' !== EU27_ID)
				.map((val) => val.id)
				.join(','),
			origin_countries__id__in: searchInputs.origin_countries
				.filter((e) => e.id + '' !== EU27_ID)
				.map((val) => val.id)
				.join(','),
			...(searchInputs.is_non_active_exclude
				? { status__in: 'active' }
				: null),
			...(searchInputs.is_exclude_eu ? { exclude_eu_licenses: true } : null),
		}),
		[
			searchInputs.destination_countries,
			searchInputs.origin_countries,
			searchInputs.is_non_active_exclude,
			searchInputs.is_exclude_eu,
		]
	);

	const {
		data: parallelData,
		state: parallelState,
		setPagination: parallelSetPagination,
		rowCount: parallelRowCount,
		setSorting: parallelSorting,
		pageCount: parallelPageCount,
		setPageCount: parallelSetPageCount,
	} = useQueryPagination(
		useParallelTradeLicenses,
		searchInputs.searchName,
		null,
		ParallelQueryParams,
		{
			sort: [{ id: 'date_of_decision', desc: true }]
		}
	);

	const onChangeHandler = useCallback((e, type?: string) => {
		const { name, value, checked } = e.target;

		setSearchInputs((prevInputs) => {
			const newState = {
				...prevInputs,
				[name]: type === 'checkbox' ? checked : value,
			};
			return newState;
		});
	}, []);

	return (
		<Paper elevation={0}>
			<Box padding={4}>
				<FormWithAdditionaFilters
					filters={searchFilters}
					searchInputs={searchInputs}
					onChangeHandler={onChangeHandler}
					onResetHandler={resetSearchParams}
					gridWidth={3}
				>
					<Grid item xs={3}>
						<SearchInput
							onChange={onChangeHandler}
							value={searchInputs.searchName}
							name={'searchName'}
							label={'Parallel Trade Licenses'}
							altLabel={
								'(Search by product name, company, product code, ATC code, or active ingredient)'
							}
							placeholder={'Product name or ATC code'}
							fullWidth
						/>
					</Grid>
					<Grid item alignSelf='end'>
						<BaseCheckbox
							onChange={(e) => onChangeHandler(e, 'checkbox')}
							checked={searchInputs.is_non_active_exclude}
							name={'is_non_active_exclude'}
							label={'Exclude non-active licenses'}
						/>
					</Grid>
					<Grid item alignSelf='end'>
						<BaseCheckbox
							onChange={(e) => onChangeHandler(e, 'checkbox')}
							checked={searchInputs.is_exclude_eu}
							name={'is_exclude_eu'}
							label={'Exclude European level licenses'}
						/>
					</Grid>
				</FormWithAdditionaFilters>
			</Box>
			<Box mt={2}>
				<RemoteDataTable
					state={parallelState}
					data={parallelData}
					pageCount={parallelPageCount}
					onRowsPerPageChange={parallelSetPageCount}
					onPaginationChange={parallelSetPagination}
					rowCount={parallelRowCount}
					columns={parallelColumns}
					enableRowSelection={false}
					renderCustomActions={false}
					enablePagination
					manualSorting
					enableMultiSort={true}
					onSortingChange={parallelSorting}
				/>
			</Box>
		</Paper>
	);
};
