import { QueryKeysEnum } from 'models/enums';
import { useQuery } from 'react-query';
import { getClinicalTrials } from 'services/ClinicalTrialsServices';

export const useClinicalTrials = ({ queryParams }, enabled) => {
	const {
		filter_by_product,
		active_ingredients__id,
		ordering,
		page,
		page_size,
		search,
	} = queryParams;
	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[
			QueryKeysEnum.CLINICAL_TRIALS,
			filter_by_product,
			active_ingredients__id,
			ordering,
			page,
			page_size,
			search,
		],
		() => getClinicalTrials({ queryParams }),
		{ enabled }
	);
	return { data, error, isLoading, isFetching, isFetched };
};
