import moment from 'moment';
import { COMMON_DATE_FORMAT, SERVER_DATE_FORMAT } from './consts';

export const dateFormatter = (
	dateString: string, 
	formatType?: 'COMMON' | 'SERVER'
) => {
	switch (formatType) {
	case 'COMMON':
		return moment(dateString).format(COMMON_DATE_FORMAT);
	case 'SERVER':
		return moment(dateString).format(SERVER_DATE_FORMAT);
	default:
		return moment(dateString).format(COMMON_DATE_FORMAT);
	}
};