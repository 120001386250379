/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Box } from '@mui/system';
import { Paper, Typography } from '@mui/material';
import { numberFormatter } from 'utils';
import moment from 'moment';

const enabledTypes = ['number', 'string'];

export const PricesHistoryTooltip = ({ point, displayInputs, data, date, startDate, endDate }) => {
	const isFirstHalf = useMemo(() => {
		const middleTimestamp = (startDate.valueOf() + endDate.valueOf()) / 2;
		const middleDate = moment(middleTimestamp);
		const currentDate = moment(point.data.compareDate);

		// all the point values available on left axis
		let mergedYs = [];
		for (const row of data) {
			mergedYs = mergedYs.concat(row.data.map((val) => val.y));
		}
		// remove duplicates and sort
		const uniqueMergedYs = [...new Set(mergedYs)]
			.filter((e) => e)
			.sort((a, b) => a - b);
		const maxYPoint = Number(uniqueMergedYs[uniqueMergedYs.length - 1]);
		const minYPoint = Number(uniqueMergedYs[0]);
		return {
			x: currentDate.isBefore(middleDate),
			y: point.data.y >= minYPoint + (maxYPoint - minYPoint) / 2,
		};
	}, [point, data]);

	return (
		<Paper
			elevation={3}
			sx={{
				overflow: 'visible',
				position: 'absolute',
				left: isFirstHalf.x ? '30px' : 'auto',
				right: isFirstHalf.x ? 'auto' : '30px',
				bottom: isFirstHalf.y ? 'auto' : '30px',
				top: isFirstHalf.y ? '30px' : 'auto',
				width: '250px',
				maxWidth: '80vw',
			}}
		>
			<Box p={3}>
				<Box mb={2} display='flex' justifyContent='space-between'>
					<Typography variant='body1' fontWeight={600}>
						{point.serieId}
					</Typography>
					<Typography variant='body1' fontWeight={600} paddingLeft={'3px'}>
						{date}
					</Typography>
				</Box>
				{displayInputs.exFactoryPrice
					&& enabledTypes.includes(typeof point?.data?.ex_factory_price)
					&& point?.data?.ex_factory_price !== ''
					&& (
						<Typography mb={1} variant='body1' fontWeight={400}>
							{`Ex-factory Price: ${point?.data?.currency?.symbol}${numberFormatter(point.data.ex_factory_price)}`}
						</Typography>
					)}
				{displayInputs.retailPrice
					&& enabledTypes.includes(typeof point?.data?.retail_price)
					&& point?.data?.retail_price !== ''
					&& (
						<Typography mb={1} variant='body1' fontWeight={400}>
							{`Retail Price: ${point?.data?.currency?.symbol}${numberFormatter(
								point.data.retail_price
							)}`}
						</Typography>
					)}
				{displayInputs.wholesalePrice
					&& enabledTypes.includes(typeof point?.data?.wholesale_price)
					&& point?.data?.wholesale_price !== ''
					&& (
						<Typography mb={1} variant='body1' fontWeight={400}>
							{`Wholesale Price: ${point?.data?.currency?.symbol}${numberFormatter(point.data.wholesale_price)}`}
						</Typography>
					)}
				{displayInputs.avaragePrice
					&& enabledTypes.includes(typeof point?.data?.y)
					&& point?.data?.y !== ''
					&& (
						<Typography mb={1} variant='body1' fontWeight={400}>
							{`Average Price: ${point?.data?.currency?.symbol}${numberFormatter(point.data.y)}`}
						</Typography>
					)}
			</Box>
		</Paper>
	);
};
