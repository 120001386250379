import React, { FC, ReactNode, useEffect, useState } from 'react';

import { Box, ListItem, Checkbox, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { MatFormLabel, TextFieldWrapper } from '..';
import { MaterialUISizeEnum } from 'models/enums';
import { MEDIUM_BORDER_RADIUS, SMALL_VARIANT_FONT_SIZE, SMALL_VARIANT_INPUT_HEIGHT } from 'utils/consts/themeConsts';
import { COLORS } from 'utils/consts';
import { getPresentations } from 'services';
import { MaterialUIInputSizeType, MaterialUIInputVariantType } from 'models/types';
import { useDebounce } from 'hooks';

interface BaseAutoCompleteInputStaticDataInterface {
  onChange?: (x: any) => void;
  onBlur?: (x: any) => void;
  value?: any;
  data: any[];
  name: string;
  label: string;
  labelInfo?: ReactNode;
  placeholder?: string;
  autoComplete?: string;
  variant?: MaterialUIInputVariantType;
  autoWidth?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  sx?: any;
  inputProps?: any;
  size?: MaterialUIInputSizeType;
}

export const BaseAutoCompleteInputStaticData: FC<BaseAutoCompleteInputStaticDataInterface> = ({
	data = [],
	value,
	onChange,
	label,
	labelInfo,
	placeholder,
	name,
	fullWidth,
	size = 'small',
}) => {
	const [inputOptions, setInputOptions] = useState([]);
	const [search, setSearch] = useState('');
	const debounceValue = useDebounce(search);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function getData(debounceValue) {
			try {
				setLoading(true);
				const data = await getPresentations({ search: debounceValue });

				setInputOptions(data.results);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		}
		// if (debounceValue && debounceValue.length >= 2) {
		getData(debounceValue);
		// }
	}, [debounceValue]);

	const onChangeHandler = (e, value) => onChange && onChange({ target: { name, value } });

	const onSearchChange = (e) => setSearch(e.target.value);

	const onInputChange = (e, value, reason) => {
		if (reason === 'clear') setSearch('');
	};

	return (
		<Box>
			<MatFormLabel id={name}>
				{label}
				{labelInfo && <Box sx={{ ml: 'auto' }}>{labelInfo}</Box>}
			</MatFormLabel>
			<Autocomplete
				multiple
				freeSolo
				open={open}
				onOpen={() => setOpen(true)}
				// onClose={() => setOpen(false)}
				onBlur={() => setOpen(false)}
				onKeyDown={(e) => {
					if (e.code === 'Escape') {
						setOpen(false);
					}
				}}
				loading={loading}
				fullWidth={fullWidth}
				size={size}
				inputValue={search}
				onInputChange={onInputChange}
				value={value}
				onChange={onChangeHandler}
				options={inputOptions}
				getOptionLabel={(option: any) => option.name}
				renderTags={() => null}
				renderOption={(props, option: any, rest) => (
					<ListItem key={option.id + rest.index} value={option.name} {...props}>
						<Box display='flex' alignItems='center'>
							<Checkbox checked={value.some((val) => val.name === option.name)} />
							<Typography variant='body1' sx={{ fontSize: '14px' }}>
								{option.name}
							</Typography>
						</Box>
					</ListItem>
				)}
				renderInput={(params) => (
					<TextFieldWrapper
						onChange={onSearchChange}
						placeholder={placeholder}
						sx={{
							maxHeight: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_INPUT_HEIGHT : undefined,
							fontSize: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_FONT_SIZE : undefined,
							borderRadius: MEDIUM_BORDER_RADIUS,
							backgroundColor: COLORS.white,
						}}
						{...params}
					/>
				)}
				sx={{
					maxHeight: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_INPUT_HEIGHT : undefined,
					fontSize: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_FONT_SIZE : undefined,
					borderRadius: MEDIUM_BORDER_RADIUS,
					backgroundColor: COLORS.white,
				}}
			/>
		</Box>
	);
};
