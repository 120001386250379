import React from 'react';
import { TableCustomCellEnum } from 'models/enums';
import { ProductLinkCell } from 'components/Tables/shared/UniqueCells';
import { PriceCell } from 'components/Tables/shared/CustomCells/PriceCell/PriceCell';

export const atcCategoriesColumns = [
	{
		header: 'Subcategories',
		accessorKey: 'code',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		header: 'Name',
		accessorKey: 'name',
		linkBase: 'atc-code-explorer/',
		linkField: 'id',
		cellType: TableCustomCellEnum.LINK,
		align: 'right',
		fontWeight: 400,
	},
];

export const productColumns = [
	{
		size: 150,
		header: 'Product',
		accessorKey: 'name',
		accessorFn: (row) => (
			<ProductLinkCell
				value={row.product_pack.product.name}
				link={`/product-explorer/${row?.product_pack?.id}/`}
				params={!row.is_eu_authorized && `?country=${row?.country?.id || '0'}`}
			/>
		),
		cellType: TableCustomCellEnum.PRODUCT_LINK,
		align: 'right',
		component: 'div',
	},
	{
		size: 150,
		header: 'Active Ingredient',
		accessorKey: 'active_ingredient__name',
		accessorFn: (row) =>
			row?.product_pack?.active_ingredients
				? row.product_pack.active_ingredients
					.map((val) => val.active_ingredient.name)
					.join(', ')
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		enableSorting: false,
	},
	{
		size: 70,
		header: 'Pack Size',
		accessorKey: 'pack_size__name',
		accessorFn: (row) =>
			row?.product_pack?.pack_size ? row.product_pack.pack_size.name : null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		size: 100,
		header: 'Presentation',
		accessorKey: 'presentation__name',
		accessorFn: (row) =>
			row?.product_pack?.presentation
				? row.product_pack.presentation.name
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		size: 100,
		header: 'Strength',
		accessorFn: (row) =>
			row?.product_pack?.active_ingredients
				? row.product_pack.active_ingredients
					.map((val) => val.strength.name)
					.join(', ')
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		enableSorting: false,
	},
	{
		size: 100,
		header: 'Country',
		accessorKey: 'country__name',
		accessorFn: (row) => (row.country ? row.country.name : null),
		cellType: TableCustomCellEnum.COUNTRY,
		align: 'right',
	},
	{
		size: 100,
		header: 'MA Holder',
		accessorKey: 'ma_holder__name',
		accessorFn: (row) =>
			row?.product_pack?.product?.company
				? row.product_pack.product.company.name
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		maxSize: 70,
		header: 'Ex-Factory',
		accessorKey: 'last_price__ex_factory_price',
		accessorFn: (row) => (
			<PriceCell
				getValue={() => row?.last_price?.ex_factory_price}
				row={row}
				column={{ priceSymbolPath: ['last_price', 'currency', 'symbol'] }}
			/>
		),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		maxSize: 70,
		header: 'Wholesale',
		accessorKey: 'last_price__wholesale_price',
		accessorFn: (row) => (
			<PriceCell
				getValue={() => row?.last_price?.wholesale_price}
				row={row}
				column={{ priceSymbolPath: ['last_price', 'currency', 'symbol'] }}
			/>
		),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		maxSize: 70,
		header: 'Retail',
		accessorKey: 'last_price__retail_price',
		accessorFn: (row) => (
			<PriceCell
				getValue={() => row?.last_price?.retail_price}
				row={row}
				column={{ priceSymbolPath: ['last_price', 'currency', 'symbol'] }}
			/>
		),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
];
