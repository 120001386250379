export enum CustomInputTypeEnum {
  COUNTRY = 'country',
  COUNTRY_AUTOCOMPLETE_STATIC = 'country_autocomplete_static',
  SELECT = 'select',
  AUTOCOMPLETE_REMOTE = 'autocomplete_remote',
  AUTOCOMPLETE_STATIC = 'autocomplete_static',
  SWITCH = 'switch',
  CHECKBOX = 'checkbox',
  TEXT = 'text',
  TEXT_SEARCH = 'text_search',
}
