import React, { FC, ReactNode, MouseEvent } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import {
	MaterialUIButtonColorType,
	MaterialUIButtonSizeType,
	MaterialUIButtonVariantType,
} from 'models/types';
import { MaterialUISizeEnum } from 'models/enums';
import {
	SMALL_VARIANT_BUTTON_HEIGHT,
	SMALL_VARIANT_FONT_SIZE,
} from 'utils/consts/themeConsts';

interface BaseButtonInterface {
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  color?: MaterialUIButtonColorType;
  variant?: MaterialUIButtonVariantType;
  type?: 'submit' | 'button' | 'reset';
  size?: MaterialUIButtonSizeType;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  sx?: any;
  fullWidth?: boolean;
  children?: ReactNode;
}

const ButtonSpinner = () => (
	<CircularProgress color='inherit' size={SMALL_VARIANT_FONT_SIZE} />
);

export const BaseButton: FC<BaseButtonInterface> = ({
	label,
	loading = false,
	disabled,
	onClick,
	color = 'primary',
	variant = 'contained',
	type = 'submit',
	size = 'small',
	sx,
	startIcon,
	endIcon,
	fullWidth = true,
	children,
}) => {
	return (
		<Button
			fullWidth={fullWidth}
			disabled={disabled}
			onClick={onClick}
			disableElevation={true}
			color={color}
			variant={variant}
			type={type}
			size={size}
			startIcon={startIcon && (!loading ? startIcon : <ButtonSpinner />)}
			endIcon={endIcon && (!loading ? endIcon : <ButtonSpinner />)}
			sx={{
				fontSize: size === MaterialUISizeEnum.SMALL
					? SMALL_VARIANT_FONT_SIZE
					: undefined,
				fontWeight: 500,
				height: size === MaterialUISizeEnum.SMALL
					? SMALL_VARIANT_BUTTON_HEIGHT
					: undefined,
				...sx,
			}}
		>
			{label && <strong>{label}</strong>}
			{children}
		</Button>
	);
};
