/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Typography } from '@mui/material';

export const ExternalLink = ({ column, ...props }) => {
	const dataItem = props.row.original;
	return (
		<a href={dataItem[column.accessorKey]} {...column.linkProps}>
			<Box 
				display={'flex'} 
				alignItems={'center'} 
				{...(column.aligned && { justifyContent: column.aligned })}
			>
				{column.icon && <Box mr={1}>{column.icon}</Box>}
				{column.linkValue && (
					<Typography color='primary' sx={{ fontWeight: 600 }} variant='body1'>
						{column.linkValue}
					</Typography>
				)}
			</Box>
		</a>
	);
};
