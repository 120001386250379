import { CustomInputTypeEnum } from 'models/enums/CustomInputTypeEnum';

export const searchFilters = [
	{
		type: CustomInputTypeEnum.COUNTRY_AUTOCOMPLETE_STATIC,
		gridWrapper: 3,
		name: 'destination_countries',
		label: 'Destination Country',
		placeholder: 'Search destination country',
	},
	{
		type: CustomInputTypeEnum.COUNTRY_AUTOCOMPLETE_STATIC,
		gridWrapper: 3,
		name: 'origin_countries',
		label: 'Origin Country',
		placeholder: 'Search origin country',
		pillColor: '#0554a8'
	},
];
