import React from 'react';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Box, Link, Typography, Alert } from '@mui/material';
import { Formik } from 'formik';

import { BaseButton } from 'components/Buttons';
import { BaseInput } from 'components/Inputs';

import { forgotPasswordSchema, initialFormValues } from 'forms/forgotPassword';
import { forgotPassword } from 'services';
import { ApplicationRoutesEnum } from 'models/enums';
import { ResponseMessageInterface } from 'models/interfaces';
import { AlertsTypesEnum } from 'models/enums';

export const ForgotPassword = () => {
	const [responseMessage, setResponseMessage] = useState<ResponseMessageInterface | null>(null);

	const onForgotPasswordHandler = async (forgotPasswordInputs: any, { setSubmitting, resetForm }: any) => {
		try {
			await forgotPassword(forgotPasswordInputs);
			resetForm();
			setResponseMessage({
				type: AlertsTypesEnum.SUCCESS,
				detail: 'If that email is in our records, you\'ll soon receive a reset password link.',
			});
		} catch (err: any) {
			setResponseMessage({
				type: AlertsTypesEnum.ERROR,
				detail: 'There has been an error.',
			});
		} finally {
			setSubmitting(false);
		}
	};
	return (
		<Box px={4} py={8} display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={1}>
			<Box
				sx={{
					width: { xs: '100%', md: '60%' },
					borderBottom: 1,
					borderColor: 'divider',
				}}
			>
				<Typography variant="h5" align="left" sx={{ mb: 4 }}>
          Reset Password
				</Typography>
				<Typography align="left" sx={{ mb: 4 }}>
          Forgotten your password? Enter your email address below, and we’ll email instructions for setting a new one.
				</Typography>
				<Formik initialValues={initialFormValues} validationSchema={forgotPasswordSchema} onSubmit={onForgotPasswordHandler}>
					{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
						<form onSubmit={handleSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<BaseInput
										fullWidth
										name="email"
										label="Enter Email"
										value={values.email}
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email ? errors.email : ''}
									/>
									<Box width={1} display="flex" pt={1} justifyContent="flex-start">
										<Typography variant="body2">
                      Remembered your password?
											<Link variant="body2" component={RouterLink} to={ApplicationRoutesEnum.SIGNIN} sx={{ ml: 1 }}>
                        Sign In
											</Link>
										</Typography>
									</Box>
								</Grid>
								{responseMessage && responseMessage.detail && (
									<Grid item xs={12}>
										<Alert severity={responseMessage.type}>{responseMessage.detail}</Alert>
									</Grid>
								)}
								<Grid item xs={12} sx={{ textAlign: 'center' }}>
									<BaseButton
										disabled={isSubmitting || (responseMessage != null && responseMessage.type === AlertsTypesEnum.SUCCESS)}
										label={'Submit'}
									/>
								</Grid>
							</Grid>
						</form>
					)}
				</Formik>
			</Box>
		</Box>
	);
};
