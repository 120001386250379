/* eslint-disable react/prop-types */
import React, { useState, useMemo } from 'react';

import { BaseButton } from 'components/Buttons';

export const NotesToolbarActions = ({ table, onDeleteMultiple }) => {
	const [isLoading, setIsLoading] = useState(false);
	const selectedRows = useMemo(() => table.getSelectedRowModel().flatRows, [table]);

	const onDeleteMultipleHandler = async () => {
		try {
			setIsLoading(true);
			await onDeleteMultiple(selectedRows.map((selectedRow) => selectedRow.original.id));
			table?.resetRowSelection();
		} finally {
			setIsLoading(false);
		}
	};
	if (selectedRows && selectedRows.length > 0) {
		return (
			<div>
				<BaseButton size='small' color='primary' onClick={onDeleteMultipleHandler} variant='contained' loading={isLoading}>
					Delete multiple notes
				</BaseButton>
			</div>
		);
	}
	return null;
};
