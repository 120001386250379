import { TableCustomCellEnum } from 'models/enums';

export const productHistoryPriceColumns = [
	{
		header: 'Date',
		accessorKey: 'start_date',
		cellType: TableCustomCellEnum.DATA,
		width: '',
		align: 'right',
	},
	{
		header: 'Ex-Factory Price',
		accessorKey: 'ex_factory_price',
		cellType: TableCustomCellEnum.PRICE,
		priceSymbolPath: ['currency', 'symbol'],
		width: '',
		align: 'right',
	},
	{
		header: 'Wholesale Price',
		accessorKey: 'wholesale_price',
		cellType: TableCustomCellEnum.PRICE,
		priceSymbolPath: ['currency', 'symbol'],
		width: '',
		align: 'right',
	},
	{
		header: 'Retail Price',
		accessorKey: 'retail_price',
		cellType: TableCustomCellEnum.PRICE,
		priceSymbolPath: ['currency', 'symbol'],
		width: '',
		align: 'right',
	},
];
