/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Avatar, Typography, styled } from '@mui/material';
import { COLORS } from 'utils/consts';

const StyledAvatar = styled(Avatar)({
	backgroundColor: COLORS.primary,
	color: COLORS.black,
	width: 24,
	height: 24,
});
const AvatarWrapper = styled(Box)({
	display: 'flex',
	gap: '8px',
	alignItems: 'center',
});
export const AvatarCell = ({ column, ...props }) => {
	const dataItem = props.row.original;
	return (
		<AvatarWrapper>
			<StyledAvatar alt="Profile image" src={dataItem.user_avatar} />
			<Typography color="primary" fontSize={12}>
				<strong> {props.getValue()}</strong>
			</Typography>
		</AvatarWrapper>
	);
};
