import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';

import { Box, Grid, Paper } from '@mui/material';

import { Logo } from 'components/shared/Logo/Logo';

const LayoutContainer = styled(Grid)(() => ({ height: '100vh', width: '100%' }));

const LogoContainer = styled(Grid)(() => ({ position: 'relative', backgroundColor: '#F4F4F6' }));

const LogoWrapper = styled(Box)(() => ({ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }));

export const AuthLayout = ({ children }: { children: ReactNode }) => (
	<LayoutContainer container>
		<LogoContainer item xs={false} sm={false} md={6} lg={6} xl={6}>
			<LogoWrapper>
				<Logo />
			</LogoWrapper>
		</LogoContainer>
		<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
			<Paper elevation={6} square sx={{ height: 1, width: 1 }}>
				{children}
			</Paper>
		</Grid>
	</LayoutContainer>
);
