import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { Grid, Box, Typography, Alert, Link, CircularProgress } from '@mui/material';

import { activateAccount } from 'services';
import { AlertsTypesEnum, ApplicationRoutesEnum } from 'models/enums';
import { ResponseMessageInterface } from 'models/interfaces';

export const ActivateAccount = () => {
	const [loading, setLoading] = useState(false);
	const [responseMessage, setResponseMessage] = useState<ResponseMessageInterface | null>(null);
	const navigate = useNavigate();
	const { uid, token } = useParams();

	useEffect(() => {
		if (uid && token) {
			onActivateAccount(uid, token);
		} else {
			navigate(ApplicationRoutesEnum.REGISTER);
		}
		async function onActivateAccount(uid, token) {
			try {
				setLoading(true);
				await activateAccount({ uid, token });
				navigate(ApplicationRoutesEnum.SIGNIN);
			} catch (err: any) {
				setResponseMessage({ type: AlertsTypesEnum.ERROR, detail: err.data.detail });
				setLoading(false);
			}
		}
	}, []);
	return (
		<Box>
			<Grid container spacing={2} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
				{loading && <CircularProgress />}
				{responseMessage && responseMessage.detail && (
					<Grid item xs={12}>
						<Alert severity={responseMessage.type}>{responseMessage.detail}</Alert>
						<Box width={1} display="flex" pt={1} justifyContent="flex-start">
							<Typography variant="body2">
                Back to Sign In?
								<Link variant="body2" component={RouterLink} to={ApplicationRoutesEnum.SIGNIN} sx={{ ml: 1 }}>
                  Sign In
								</Link>
							</Typography>
						</Box>
					</Grid>
				)}
			</Grid>
		</Box>
	);
};
