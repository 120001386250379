/* eslint-disable react/prop-types */
import React from 'react';

export const FileIcon = ({ stroke = '#0494F1', ...props }) => (
	<svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 12 14' fill='none'>
		<path
			d='M11.3334 4.94432V13.221H0.666748V0.77832H7.16741M11.3334 4.94432L7.16741 0.77832M11.3334 4.94432H7.16741V0.77832'
			stroke={stroke}
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
