/* eslint-disable react/prop-types */
import React from 'react';
import { COLORS } from 'utils/consts';

export const PlusIcon = ({
	primary = COLORS.blue,
	secondary = COLORS.lightAqua,
	width = '13',
	height = '13',
	active = false,
	...props
}) => (
	<svg
		width={width}
		height={height}
		viewBox='0 0 10 10'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<circle cx='5' cy='5' r='5' fill={active ? primary : secondary} />
		<path
			d='M4.885 3L4.88525 4.88538M4.88525 4.88538L4.8855 6.7705M4.88525 4.88538L3 4.88525M4.88525 4.88538L6.7705 4.8855'
			stroke={active ? 'white' : primary}
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
