import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { COLORS } from 'utils/consts';

let theme = createTheme({});

theme = createTheme({
	...theme,
	typography: {
		fontFamily: 'Codec-Pro',
	},
	palette: {
		primary: {
			main: '#0494F1',
		},
		secondary: {
			main: '#FFB949',
		},
		error: {
			main: red.A400,
		},
		success: {
			main: COLORS.successGreen,
		},
	},
	components: {
		MuiTableSortLabel: {
			styleOverrides: {
				root: {
					width: '2.6ch !important',
					marginLeft: '2px',
					'& .customAsc': {
						transform: 'none'
					}
				}
			}
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					'&:not(.header_toolbar)': {
						boxShadow: 'none !important',
						paddingRight: '20px !important',
						paddingLeft: '20px !important',
						paddingTop: '15px',
						'&.MuiToolbar-gutters': {
							flexDirection: 'row-reverse',
							'& > .MuiBox-root > .MuiBox-root': {
								flex: 1,
								width: '100%',
							},
						},
					},
				},
			},
		},
		MuiTablePagination: {
			styleOverrides: {
				root: {
					width: '100%',
					'& .MuiTablePagination-spacer': {
						display: 'none',
					},
					'& .MuiPagination-root': {
						order: 4,
					},
					'& .MuiTablePagination-displayedRows': {
						order: 3,
						marginRight: '20px !important',
					},
					'& .MuiTablePagination-selectLabel': {
						order: 2,
					},
					'& .MuiInputBase-root': {
						order: 1,
						marginRight: 0,
					},
				},
			},
		},
		MuiPagination: {
			styleOverrides: {
				root: {
					display: 'flex',
					justifyContent: 'center',
					position: 'absolute',
					width: '100%',
					[theme.breakpoints.down('md')]: {
						position: 'static',
					},
					'&>ul': {
						background: COLORS.white
					}
				},
			},
		},
		MuiPaginationItem: {
			styleOverrides: {
				root: {
					borderRadius: '10px',
					'&.Mui-selected': {
						background: COLORS.darkTransparent,
						color: COLORS.darkBlack,
						'&:hover': {
							background: COLORS.darkTransparent,
						},
					},
					'&.MuiPaginationItem-previousNext': {
						background: COLORS.blue,
						color: `${COLORS.white} !important`,
						paddingLeft: '16px',
						paddingRight: '16px',
						paddingTop: '10px',
						paddingBottom: '10px',
						'&.Mui-disabled': {
							background: 'transparent',
						},
					},
				},
			},
		},
	},
});

export default theme;
