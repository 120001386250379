/* eslint-disable react/prop-types */
import React from 'react';

export const LinkArrowIcon = ({ stroke = '#0494F1', ...props }) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M5.37528 1.78906H11.6613V8.0744M4.33728 9.11306L11.6519 1.7984M1.77661 14.2211H14.2213'
			stroke={stroke}
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
