/* eslint-disable react/prop-types */
import React from 'react';
import { styled, Box } from '@mui/material';

import { COLORS } from 'utils/consts';
import { MEDIUM_BORDER_RADIUS } from 'utils/consts/themeConsts';

const CardWrapper = styled(Box)(() => ({
	boxSizing: 'border-box',
	padding: '24px',
	background: COLORS.white,
	borderRadius: MEDIUM_BORDER_RADIUS,
	width: '100%',
}));

export const Card = (props) => <CardWrapper>{props.children}</CardWrapper>;
