/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Formik } from 'formik';

import { BaseButton } from 'components/Buttons';
import { BaseInput } from 'components/Inputs';
import { createSubuserSchema, initialFormValues } from 'forms/createSubuser';

export const CreateUserModalContent = ({ onSubmit, onCancel }) => {
	return (
		<Box>
			<Formik initialValues={initialFormValues} validationSchema={createSubuserSchema} onSubmit={onSubmit}>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={4}>
							<Grid item xs={12}>
								<Typography mb={1}>
                  Add a new user to your company, they&apos;ll receive an invite to Pharma Footpath straight away! They&apos;ll need to set
                  a password, and then login using their email and newly created password.
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<BaseInput
									fullWidth
									name="first_name"
									label="First Name"
									value={values.first_name}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.first_name && Boolean(errors.first_name)}
									helperText={touched.first_name ? errors.first_name : ''}
								/>
							</Grid>
							<Grid item xs={12}>
								<BaseInput
									fullWidth
									name="last_name"
									label="Last Name"
									value={values.last_name}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.last_name && Boolean(errors.last_name)}
									helperText={touched.last_name ? errors.last_name : ''}
								/>
							</Grid>
							<Grid item xs={12}>
								<BaseInput
									fullWidth
									name="email"
									label="Enter Email"
									value={values.email}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.email && Boolean(errors.email)}
									helperText={touched.email ? errors.email : ''}
								/>
							</Grid>
							<Grid container spacing={2} item xs={12}>
								<Grid item xs={6}>
									<Box mb={2}>
										<BaseButton type="button" onClick={onCancel} variant={'outlined'} label={'Cancel'} />
									</Box>
								</Grid>
								<Grid item xs={6}>
									<Box mb={2}>
										<BaseButton type={'submit'} label={'Confirm'} />
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};
