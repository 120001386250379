import { TableCustomCellEnum } from 'models/enums';

export const tenderColumns = [
	{
		header: 'Lot number',
		accessorKey: 'lot__number',
		accessorFn: (row) => (row?.number ? row.number : '-'),
		// enableSorting: false,
		cellType: TableCustomCellEnum.DATA,
		maxSize: 100,
	},
	{
		header: 'Lot name',
		accessorKey: 'lot__title',
		accessorFn: (row) => (row?.title ? row.title : '-'),
		cellType: TableCustomCellEnum.DATA,
		size: 400,
		maxSize: '25vw',
		// enableSorting: false,
	},
	{
		header: 'Description',
		accessorKey: 'lot__description',
		accessorFn: (row) => (row?.description ? row.description : '-'),
		cellType: TableCustomCellEnum.DATA,
		size: 400,
		maxSize: '25vw',
	},
	{
		header: 'Active ingredient identified',
		accessorKey: 'lot__active_ingredient',
		accessorFn: (row) =>
			row?.active_ingredients ? row.active_ingredients : null,
		cellType: TableCustomCellEnum.ACTIVE_INGREDIENTS_LINK,
		baseLink: '/ingredients/',
		size: 200
		// enableSorting: false,
	},
];
