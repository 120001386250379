export function getSortingValue(sortObject: { id: string; desc: boolean }[]) {
	if (sortObject.length > 0) {
		if (sortObject[0].id.includes(',')) {
			// multi sort by same desc attribute
			const splitSorts = sortObject[0].id.split(',');
			return splitSorts.map(item => (
				(sortObject[0].desc ? '-' : '') + item
			));
		} else {
			return (sortObject[0].desc ? '-' : '') + sortObject[0].id;
		}
	} else {
		return undefined;
	}
}
