export const COMPACT_TABLE__BODY_STYLES = {
	fontWeight: 'normal',
	fontSize: '12px',
	paddingLeft: '10px',
	paddingRight: '10px',
};
export const COMPACT_TABLE__HEADER_STYLES = {
	fontSize: '12px',
	padding: '10px',
};
