import { TableCustomCellEnum } from 'models/enums';

export const usersColumns = [
	{
		header: 'Number',
		accessorKey: null,
		cellType: TableCustomCellEnum.INDEX,
		align: undefined,
		enableSorting: false,
	},
	{
		header: 'First Name',
		accessorKey: 'first_name',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		header: 'Last Name',
		accessorKey: 'last_name',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		header: 'Email',
		accessorKey: 'email',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		header: 'Actions',
		accessorKey: null,
		cellType: TableCustomCellEnum.ACTION,
		align: 'right',
		alignHeader: 'right',
	},
];

export const notesColumns = [
	{
		header: 'User Name',
		accessorKey: 'user_name',
		cellType: TableCustomCellEnum.AVATAR,
		align: 'right',
	},
	{
		header: 'Description',
		accessorKey: 'note',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		header: 'Date',
		accessorKey: 'created_at',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		header: 'More',
		accessorKey: null,
		cellType: TableCustomCellEnum.ACTION,
		align: 'right',
		enableSorting: false,
	},
];
