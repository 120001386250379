import React, { FC } from 'react';
import { Box } from '@mui/material';

import { BaseIconButton } from 'components/Buttons';
import { EditIcon, TrashIcon, ReactivateIcon } from 'components/Icons';
import { COLORS } from 'utils/consts';

interface ActionCellInterface {
  column: any;
  row?: any;
  onEdit?: (x: string | number) => void;
  onDelete?: (x: string | number) => void;
  onEnable?: (x: string | number) => void;
}

export const ActionCell: FC<ActionCellInterface> = ({ onEdit, onDelete, onEnable, column, row, ...props }) => {
	return (
		<Box display={'flex'}>
			<Box ml='auto'>
				{onEnable && (
					<BaseIconButton onClick={() => onEnable(row.original)}>
						<ReactivateIcon />
					</BaseIconButton>
				)}
				{onDelete && (
					<BaseIconButton onClick={() => onDelete(row.original)}>
						<TrashIcon stroke={COLORS.danger} />
					</BaseIconButton>
				)}
				{onEdit && (
					<BaseIconButton onClick={() => onEdit(row.original)}>
						<EditIcon />
					</BaseIconButton>
				)}
			</Box>
		</Box>
	);
};
