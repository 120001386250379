import { QueryKeysEnum } from 'models/enums';
import { useQuery } from 'react-query';
import { getMyWDACompany, getWDACompany, getWDALicenses } from 'services';

export const useWDALicenses = ({ queryParams }, enabled) => {
	const {
		country__in,
		authorization__in,
		is_profile_filled,
		search,
		ordering,
		page,
		page_size,
	} = queryParams;
	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[
			QueryKeysEnum.WDA_LICENSES,
			country__in,
			authorization__in,
			is_profile_filled,
			search,
			ordering,
			page,
			page_size
		],
		() => getWDALicenses({ queryParams }),
		{ enabled }
	);
	return { data, error, isLoading, isFetching, isFetched };
};


export const useWDACompany = ({ params }) => {
	const { uid } = params;
	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[QueryKeysEnum.WDA_COMPANY, uid],
		() => getWDACompany({ params }),
		{ enabled: !!uid }
	);
	return { data, error, isLoading, isFetching, isFetched };
};

export const useMyWDACompany = () => {
	const { data, error, isLoading, isFetching, isFetched, refetch } = useQuery(
		[QueryKeysEnum.MY_WDA_COMPANY],
		() => getMyWDACompany()
	);
	return { data, error, isLoading, isFetching, isFetched, refetch };
};