import React, { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { StyledLink } from 'components/StyledComponents';
import { COLORS } from 'utils/consts';

interface ProductCellPropsInterface {
  link: string;
  params?: any;
  value: string;
  icon?: ReactNode;
  className?: string;
  extraProps?: {
    minSize?: number | string;
    maxSize?: number | string;
	wrap?: boolean;
  };
}

export const ProductLinkCell: FC<ProductCellPropsInterface> = ({
	link,
	params,
	value,
	icon,
	className,
	extraProps,
	...props
}) => {
	const { pathname, search } = useLocation();
	return (
		<StyledLink
			to={{ pathname: link, search: params }}
			state={{ from: pathname + search }}
			style={{
				maxWidth: extraProps?.maxSize || 'auto', 
				minWidth: extraProps?.minSize || 'auto',
			}}
			{...props}
		>
			<Box
				display='flex'
				alignItems='center'
				maxWidth={extraProps?.maxSize || '100%'}
				title={value}
				className={className || ''}
				component='span'
			>
				{icon && (
					<Box mr={1} component='span'>
						{icon}
					</Box>
				)}
				<Typography
					component='span'
					color='primary'
					sx={{
						fontSize: 'inherit',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: extraProps?.wrap ? 'wrap' : 'nowrap',
						fontWeight: 600,
						'&:hover': {
							color: COLORS.primaryDark,
						}
					}}
					variant='body1'
				>
					{value}
				</Typography>
			</Box>
		</StyledLink>
	);
};
