/* eslint-disable react/prop-types */
import React from 'react';

export const AtcCodeIcon = ({ stroke = '#0494F1', ...props }) => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M18.9572 1.03833H15.7098L8.87666 7.87149C6.81662 7.06423 4.38417 7.49288 2.71961 9.15743C0.477741 11.3993 0.47774 15.0341 2.71961 17.276C4.96148 19.5178 8.59627 19.5178 10.8381 17.276C12.5027 15.6114 12.9313 13.1789 12.1241 11.1189L13.8319 9.4111V7.51466H15.7061V5.64042L17.6026 5.64042L18.9572 4.28574V1.03833Z"
			stroke={stroke}
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.41528 15.025C6.74126 15.699 5.64846 15.699 4.97444 15.025C4.30041 14.351 4.30041 13.2582 4.97444 12.5842C5.64846 11.9101 6.74126 11.9101 7.41528 12.5842C8.0893 13.2582 8.0893 14.351 7.41528 15.025Z"
			stroke={stroke}
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
