import React from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	TablePagination,
	Box,
	Avatar,
	Typography,
	styled,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { TableCellContentTypeEnum } from 'models/enums';
import { FileIcon } from 'components/Icons';
import { COLORS } from 'utils/consts';
import { Link } from 'react-router-dom';

interface SimpleTableInterface {
  order: 'desc' | 'asc' | undefined;
  orderBy: string;
  headCells: {
    id: any;
    disablePadding: boolean;
    label: string;
    sortable: boolean;
  }[];
  tableRows: any[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  onRequestSort: Function;
  rowsPerPage: any;
  page: number;
  handleChangePage: any;
  itemsCount: number;
}

const StyledAvatar = styled(Avatar)({
	backgroundColor: COLORS.primary,
	color: COLORS.black,
	width: 24,
	height: 24,
});

const AvatarWrapper = styled(Box)({
	display: 'flex',
	gap: '8px',
	alignItems: 'center',
});

const StyledLink = styled(Link)(({ theme }) => ({
	textDecoration: 'none',
	color: theme.palette.primary.main,
	display: 'flex',
	alignItems: 'center',
	gap: '6px',
	textTransform: 'capitalize'
}));

const StatusWrapper = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	gap: '6px',
	justifyContent: 'flex-end',
	textTransform: 'capitalize'
}));

const StatusCircle = styled(Box)(() => ({
	width: '5px',
	height: '5px',
	borderRadius: '100%'
}));

export const SimpleTable = ({
	order,
	orderBy,
	headCells,
	tableRows,
	onRequestSort,
	rowsPerPage,
	page,
	handleChangePage,
	itemsCount,
}: SimpleTableInterface) => {
	return (
		<>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							{headCells.map((headCell, index) => (
								<TableCell
									key={headCell.id + index}
									align={index === headCells.length - 1 ? 'right' : 'left'}
									padding={headCell.disablePadding ? 'none' : 'normal'}
									sortDirection={orderBy === headCell.id ? order : false}
									style={{fontSize: '12px', maxWidth: '150px', minWidth: '50px'}}
								>
									{headCell.sortable ? (
										<TableSortLabel
											active={orderBy === headCell.id || orderBy === '-' + headCell.id }
											direction={orderBy === headCell.id || orderBy === '-' + headCell.id ? order : 'asc'}
											onClick={() => onRequestSort(headCell.id)}
										>
											<strong>{headCell.label}</strong>
											{orderBy === headCell.id ? (
												<Box component="span" sx={visuallyHidden}>
													{order === 'desc'
														? 'sorted descending'
														: 'sorted ascending'}
												</Box>
											) : null}
										</TableSortLabel>
									) : (
										<Box>
											<strong>{headCell.label}</strong>
										</Box>
									)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{tableRows.map((row, index) => (
							<TableRow
								key={index}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								{Object.keys(row).map((item, i) => {
									let tableCellContent;

									if (typeof row[item] === 'object' && row[item] !== null) {
										switch (row[item]?.type) {
										case TableCellContentTypeEnum.AVATAR:
											tableCellContent = (
												<AvatarWrapper>
													<StyledAvatar
														alt="Profile image"
														src={row[item]?.link}
													/>
													<Typography color="primary" fontSize={12}>
														<strong>{row[item]?.name}</strong>
													</Typography>
												</AvatarWrapper>
											);
											break;
										case TableCellContentTypeEnum.ICON:
											tableCellContent = <FileIcon />;
											break;
										case TableCellContentTypeEnum.LINK:
											tableCellContent = (
												<StyledLink to={row[item]?.link}>
                            
													{row[item]?.value}
												</StyledLink>
											);
											break;
										case TableCellContentTypeEnum.STATUS:
											tableCellContent = (
												<StatusWrapper>
													<StatusCircle sx={{backgroundColor: row[item]?.color}}/>
													{row[item]?.value}
												</StatusWrapper>
											);
											break;
										default:
										}
									} else {
										tableCellContent = row[item];
									}

									return (
										<TableCell
											key={i}
											style={{fontSize: '12px'}}
											align={
												i == Object.keys(row)?.length - 1 ? 'right' : 'left'
											}
										>
											{tableCellContent}
										</TableCell>
									);
								})}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				component="div"
				count={itemsCount}
				rowsPerPage={rowsPerPage}
				rowsPerPageOptions={[]}
				page={page}
				onPageChange={handleChangePage}
			/>
		</>
	);
};
