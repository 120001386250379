import React from 'react';
import { NoIcon, YesIcon } from 'components/Icons';
import { TableCustomCellEnum } from 'models/enums';
import { COLORS } from 'utils/consts';

export const columns = [
	{
		header: 'Company name',
		accessorKey: 'company__name',
		accessorFn: (row) => (row?.company?.name ? row.company.name : '-'),
		// minSize: 100,
		cellType: TableCustomCellEnum.LINK,
		linkBase: 'eu-wda-licenses/company/',
		// linkField: 'id',
		linkFieldPath: ['company', 'id'],
		maxSize: 180,
		align: 'right',
	},
	{
		header: 'Country',
		accessorKey: 'country__name',
		accessorFn: (row) => (row?.country?.name ? row.country.name : '-'),
		cellType: TableCustomCellEnum.DATA,
		enableSorting: false,
		// size: 75,
		maxSize: 100,
	},
	{
		header: 'Parallel trade',
		accessorKey: 'company__is_parallel_trade',
		accessorFn: (row) => {
			if (typeof row?.company?.is_parallel_trade === 'boolean') {
				return row?.company?.is_parallel_trade
					? <YesIcon strokeWidth={1.5} />
					: <NoIcon strokeWidth={1.5} stroke={COLORS.red} />;
			}
			return '';
		},
		cellType: TableCustomCellEnum.DATA,
		aligned: 'center',
		alignHeader: 'center',
		enableSorting: false,
	},
	{
		header: 'Clinical Trial Supply',
		accessorKey: 'company__is_clinical_trial_supply',
		accessorFn: (row) => {
			if (typeof row?.company?.is_clinical_trial_supply === 'boolean') {
				return row?.company?.is_clinical_trial_supply
					? <YesIcon strokeWidth={1.5} />
					: <NoIcon strokeWidth={1.5} stroke={COLORS.red} />;
			}
			return '';
		},
		cellType: TableCustomCellEnum.DATA,
		aligned: 'center',
		alignHeader: 'center',
		// size: 150,
		enableSorting: false,
	},
	{
		header: 'Import/Export',
		accessorKey: 'company__is_import_export',
		accessorFn: (row) => {
			if (typeof row?.company?.is_import_export === 'boolean') {
				return row?.company?.is_import_export
					? <YesIcon strokeWidth={1.5} />
					: <NoIcon strokeWidth={1.5} stroke={COLORS.red} />;
			}
			return '';
		},
		cellType: TableCustomCellEnum.DATA,
		aligned: 'center',
		alignHeader: 'center',
		// size: 150,
		enableSorting: false,
	},
	{
		header: 'Tenders',
		accessorKey: 'company__is_tenders',
		accessorFn: (row) => {
			if (typeof row?.company?.is_tenders === 'boolean') {
				return row?.company?.is_tenders
					? <YesIcon strokeWidth={1.5} />
					: <NoIcon strokeWidth={1.5} stroke={COLORS.red} />;
			}
			return '';
		},
		cellType: TableCustomCellEnum.DATA,
		aligned: 'center',
		alignHeader: 'center',
		enableSorting: false,
		// size: 150,
	},
	{
		header: 'Unlicensed Medicines',
		accessorKey: 'company__is_unlicensed_medicines',
		accessorFn: (row) => {
			if (typeof row?.company?.is_unlicensed_medicines === 'boolean') {
				return row?.company?.is_unlicensed_medicines
					? <YesIcon strokeWidth={1.5} />
					: <NoIcon strokeWidth={1.5} stroke={COLORS.red} />;
			}
			return '';
		},
		cellType: TableCustomCellEnum.DATA,
		aligned: 'center',
		alignHeader: 'center',
		enableSorting: false,
	},
];
