import React from 'react';
import { CompanyIcon } from 'components/Icons';
import { TableCustomCellEnum } from 'models/enums';

export const columns = [
	{
		header: 'Company',
		accessorKey: 'name',
		cellType: TableCustomCellEnum.LINK,
		linkBase: 'company-explorer/',
		linkField: 'id',
		icon: <CompanyIcon />,
		align: 'right',
		className: 'capitalize',
	},
	{
		header: 'Country',
		accessorKey: 'country__name',
		accessorFn: (row) => (row.country ? row.country.name : null),
		cellType: TableCustomCellEnum.COUNTRY,
		align: 'right',
	},
	{
		header: 'MA Licenses',
		accessorKey: 'ma_holders_count',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		enableSorting: false,
	},
	{
		header: 'Parallel Licenses',
		accessorKey: 'parallel_trade_licenses_count',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		enableSorting: false,
	},
];
