import React, { FC } from 'react';

import { SettingsIcon } from 'components/Icons';
import { COLORS } from 'utils/consts';
import { BaseButton } from '../BaseButton/BaseButton';
import { MaterialUIButtonColorType, MaterialUIButtonSizeType } from 'models/types';
import { MaterialUISizeEnum } from 'models/enums';
import { SMALL_BORDER_RADIUS, SMALL_VARIANT_BUTTON_HEIGHT, SMALL_VARIANT_BUTTON_WIDTH } from 'utils/consts/themeConsts';

interface SettingsButtonInterface {
  size?: MaterialUIButtonSizeType;
  color?: MaterialUIButtonColorType;
  sx?: any;
  onClick?: () => void;
}

export const SettingsButton: FC<SettingsButtonInterface> = ({ size = 'small', color = 'inherit', sx, onClick }) => {
	return (
		<BaseButton
			color={color}
			onClick={onClick}
			size={size}
			sx={{
				...sx,
				backgroundColor: sx?.backgroundColor || COLORS.white,
				borderRadius: SMALL_BORDER_RADIUS,
				height: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_BUTTON_HEIGHT : undefined,
				minWidth: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_BUTTON_WIDTH : undefined,
				maxWidth: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_BUTTON_WIDTH : undefined,
			}}
		>
			<SettingsIcon m={0} stroke={sx?.color ? sx?.color : COLORS.darkGray} />
		</BaseButton>
	);
};
