/* eslint-disable react/prop-types */
import React from 'react';

export const ActiveIcon = ({ stroke = '#2DAC00', ...props }) => (
	<svg
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M6.99992 0.333496C3.33325 0.333496 0.333252 3.3335 0.333252 7.00016C0.333252 10.6668 3.33325 13.6668 6.99992 13.6668C10.6666 13.6668 13.6666 10.6668 13.6666 7.00016C13.6666 3.3335 10.6666 0.333496 6.99992 0.333496ZM5.66658 10.3335L2.33325 7.00016L3.27325 6.06016L5.66658 8.44683L10.7266 3.38683L11.6666 4.3335L5.66658 10.3335Z"
			fill={stroke}
		/>
	</svg>
);

export const DormantIcon = ({ stroke = '#FFB949', ...props }) => (
	<svg
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M6.99992 0.333496C6.12444 0.333496 5.25753 0.505935 4.4487 0.840966C3.63986 1.176 2.90493 1.66706 2.28587 2.28612C1.03563 3.53636 0.333252 5.23205 0.333252 7.00016C0.333252 8.76827 1.03563 10.464 2.28587 11.7142C2.90493 12.3333 3.63986 12.8243 4.4487 13.1594C5.25753 13.4944 6.12444 13.6668 6.99992 13.6668C8.76803 13.6668 10.4637 12.9644 11.714 11.7142C12.9642 10.464 13.6666 8.76827 13.6666 7.00016C13.6666 6.12468 13.4941 5.25778 13.1591 4.44894C12.8241 3.6401 12.333 2.90517 11.714 2.28612C11.0949 1.66706 10.36 1.176 9.55114 0.840966C8.7423 0.505935 7.8754 0.333496 6.99992 0.333496ZM9.79992 9.80016L6.33325 7.66683V3.66683H7.33325V7.1335L10.3333 8.9335L9.79992 9.80016Z"
			fill={stroke}
		/>
	</svg>
);

export const InactiveIcon = ({ stroke = '#CFCCD6', ...props }) => (
	<svg
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.3333 7.66683H3.66658V6.3335H10.3333M6.99992 0.333496C6.12444 0.333496 5.25753 0.505935 4.4487 0.840966C3.63986 1.176 2.90493 1.66706 2.28587 2.28612C1.03563 3.53636 0.333252 5.23205 0.333252 7.00016C0.333252 8.76827 1.03563 10.464 2.28587 11.7142C2.90493 12.3333 3.63986 12.8243 4.4487 13.1594C5.25753 13.4944 6.12444 13.6668 6.99992 13.6668C8.76803 13.6668 10.4637 12.9644 11.714 11.7142C12.9642 10.464 13.6666 8.76827 13.6666 7.00016C13.6666 6.12468 13.4941 5.25778 13.1591 4.44894C12.8241 3.6401 12.333 2.90517 11.714 2.28612C11.0949 1.66706 10.36 1.176 9.55114 0.840966C8.7423 0.505935 7.8754 0.333496 6.99992 0.333496Z"
			fill={stroke}
		/>
	</svg>
);

export const CancelledIcon = ({ stroke = '#CFCCD6', ...props }) => (
	<svg
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7.00004 0.333496C10.6867 0.333496 13.6667 3.3135 13.6667 7.00016C13.6667 10.6868 10.6867 13.6668 7.00004 13.6668C3.31337 13.6668 0.333374 10.6868 0.333374 7.00016C0.333374 3.3135 3.31337 0.333496 7.00004 0.333496ZM9.39337 3.66683L7.00004 6.06016L4.60671 3.66683L3.66671 4.60683L6.06004 7.00016L3.66671 9.3935L4.60671 10.3335L7.00004 7.94016L9.39337 10.3335L10.3334 9.3935L7.94004 7.00016L10.3334 4.60683L9.39337 3.66683Z"
			fill={stroke}
		/>
	</svg>
);

export const WithdrawnIcon = ({ stroke = '#CFCCD6', ...props }) => (
	<svg
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8 0C6.4087 0 4.88258 0.632141 3.75736 1.75736C2.63214 2.88258 2 4.4087 2 6H0L2.66667 8.66667L5.33333 6H3.33333C3.33333 4.76232 3.825 3.57534 4.70017 2.70017C5.57534 1.825 6.76232 1.33333 8 1.33333C9.23768 1.33333 10.4247 1.825 11.2998 2.70017C12.175 3.57534 12.6667 4.76232 12.6667 6C12.6667 7.23768 12.175 8.42466 11.2998 9.29983C10.4247 10.175 9.23768 10.6667 8 10.6667C7 10.6667 6.06 10.3333 5.29333 9.8L4.33333 10.76C5.36 11.5333 6.62667 12 8 12C9.5913 12 11.1174 11.3679 12.2426 10.2426C13.3679 9.11742 14 7.5913 14 6C14 4.4087 13.3679 2.88258 12.2426 1.75736C11.1174 0.632141 9.5913 0 8 0ZM9.33333 6C9.33333 5.64638 9.19286 5.30724 8.94281 5.05719C8.69276 4.80714 8.35362 4.66667 8 4.66667C7.64638 4.66667 7.30724 4.80714 7.05719 5.05719C6.80714 5.30724 6.66667 5.64638 6.66667 6C6.66667 6.35362 6.80714 6.69276 7.05719 6.94281C7.30724 7.19286 7.64638 7.33333 8 7.33333C8.35362 7.33333 8.69276 7.19286 8.94281 6.94281C9.19286 6.69276 9.33333 6.35362 9.33333 6Z"
			fill={stroke}
		/>
	</svg>
);