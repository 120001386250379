import { Typography, styled } from '@mui/material';
import { TypographyProps } from '@mui/system';

export const HeaderTitle = styled(Typography)<
  TypographyProps & { component: React.ElementType }
>(() => ({
	fontSize: '40px',
	fontWeight: 600,
	lineHeight: 1.5,
	textTransform: 'capitalize',
}));