import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Box, Button, Link, Typography, Alert } from '@mui/material';
import { Formik } from 'formik';

import { BaseInput } from 'components/Inputs';
import { resendActivationLinkSchema, initialFormValues } from 'forms/resendActivationLink';
import { resendActivationLink } from 'services';
import { AlertsTypesEnum, ApplicationRoutesEnum } from 'models/enums';
import { ResponseMessageInterface } from 'models/interfaces';

export const ResendActivationLink = () => {
	const [responseMessage, setResponseMessage] = useState<ResponseMessageInterface | null>(null);
	const onResendActivationLinkHandler = async (resendActivationLinkInputs: any, { setSubmitting, resetForm }: any) => {
		try {
			await resendActivationLink(resendActivationLinkInputs);
			resetForm();
			setResponseMessage({
				type: AlertsTypesEnum.SUCCESS,
				detail: `Activation link has been send to '${resendActivationLinkInputs.email}'`,
			});
		} catch (err: any) {
			setResponseMessage({ type: AlertsTypesEnum.ERROR, detail: err.data.detail });
		} finally {
			setSubmitting(false);
		}
	};
	return (
		<Box px={4} py={8} display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={1}>
			<Box
				sx={{
					width: { xs: '100%', md: '60%' },
					borderBottom: 1,
					borderColor: 'divider',
				}}
			>
				<Typography variant="h5" align="left" sx={{ mb: 4 }}>
          Resend Activation Link
				</Typography>
				<Typography align="left" sx={{ mb: 4 }}>
          Didnt get your activation link or link expired? Enter your email address below, and we’ll email instructions for
          activating your account.
				</Typography>
				<Formik
					initialValues={initialFormValues}
					validationSchema={resendActivationLinkSchema}
					onSubmit={onResendActivationLinkHandler}
				>
					{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
						<form onSubmit={handleSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<BaseInput
										fullWidth
										name="email"
										label="Enter Email"
										value={values.email}
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email ? errors.email : ''}
									/>
									<Box width={1} display="flex" pt={1} justifyContent="flex-start">
										<Typography variant="body2">
                      Remembered your password?
											<Link variant="body2" component={RouterLink} to={ApplicationRoutesEnum.SIGNIN} sx={{ ml: 1 }}>
                        Sign In
											</Link>
										</Typography>
									</Box>
								</Grid>
								{responseMessage && responseMessage.detail && (
									<Grid item xs={12}>
										<Alert severity={responseMessage.type}>{responseMessage.detail}</Alert>
									</Grid>
								)}
								<Grid item xs={12} sx={{ textAlign: 'center' }}>
									<Button
										disabled={isSubmitting}
										disableElevation={true}
										color="primary"
										variant="contained"
										type="submit"
										sx={{
											width: '100%',
											marginTop: 2,
											height: 56,
											fontSize: 16,
											fontWeight: 500,
										}}
									>
                    Submit
									</Button>
								</Grid>
							</Grid>
						</form>
					)}
				</Formik>
			</Box>
		</Box>
	);
};
