/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react';
import { CompanyType, IUserType } from 'models/types';

interface IAppData {
  user: IUserType | null;
  userCompany: CompanyType | null;
  setUserData: any;
  onSigninUser: any;
  userAuthenticating: boolean;
  appIsDeploying: boolean;
  setAppIsDeploying: any;
}

export const UserContext = createContext<IAppData>({
	user: null,
	userCompany: null,
	userAuthenticating: false,
	appIsDeploying: false,
	setUserData: () => {},
	setAppIsDeploying: () => {},
	onSigninUser: () => {},
});

export const useUser = () => {
	const context = useContext(UserContext);
	return context;
};
