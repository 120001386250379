/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { styled, Theme, CSSObject } from '@mui/material/styles';

import {
	Box,
	Drawer as MuiDrawer,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Divider,
	IconButton,
	CssBaseline,
} from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import PharmaLogo from 'assets/images/logo.svg';
import PharmaLogoIcon from 'assets/images/logo_icon.png';
import { COLORS, DRAWER_ITEMS } from 'utils/consts';
import { MEDIUM_BORDER_RADIUS } from 'utils/consts/themeConsts';

const drawerWidthOpen = 300;
const drawerWidthClosed = 120;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidthOpen,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(12)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(15)} + 1px)`,
	},
});

const DrawerHeader = styled('div')(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

const SideDrawerItem = styled(ListItemButton)(({ open, ...rest }: { open: boolean; [key: string | symbol | number]: any }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: open ? 'initial' : 'center',
	borderRadius: MEDIUM_BORDER_RADIUS,
	'&:hover': {
		backgroundColor: rest.theme.palette.secondary.main,
	},
	'&.MuiButtonBase-root.MuiListItemButton-root.Mui-selected': {
		backgroundColor: rest.theme.palette.secondary.main,
	},
}));

const DrawerHeaderWrapper = styled(Box)(() => ({
	width: '100%',
	padding: '1.5rem 2rem 1.5rem 2rem',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
}));

const AppContent = styled(Box)(({ theme, open }: { theme: Theme; open: boolean }) => ({
	...(open && {
		...openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
	}),
	flexGrow: 1,
	backgroundColor: COLORS.background,
	minHeight: '100vh',
	overflowX: 'clip',
	width: open ? `calc(100% - ${drawerWidthOpen}px)` : `calc(100% - ${drawerWidthClosed}px)`,
}));

const SidebarLogo = styled('img')(() => ({ height: '50px' }));

const SidebarIconWrapper = styled(Box)(({ open }: { open: boolean }) => ({
	marginLeft: '1.2rem',
	marginRight: open ? '1rem' : 0,
	display: 'flex',
	alignItems: 'center',
}));

export const SideDrawer = ({ children }) => {
	const { pathname } = useLocation();

	const [isDrawerOpen, setIsDrawerOpen] = useState(true);

	const toggleDrawerHandler = () => setIsDrawerOpen((prevState) => !prevState);
	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<Drawer variant='permanent' open={isDrawerOpen}>
				<DrawerHeader>
					<DrawerHeaderWrapper>
						<Box>
							<SidebarLogo alt='Pharmafootpath icon' src={isDrawerOpen ? PharmaLogo : PharmaLogoIcon} />
						</Box>
						<Box>
							<IconButton onClick={toggleDrawerHandler}>{isDrawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
						</Box>
					</DrawerHeaderWrapper>
				</DrawerHeader>
				<Divider />
				<List>
					{DRAWER_ITEMS.map(({ title, route, icon }) => (
						<Link key={route} to={route} style={{ all: 'unset' }}>
							<ListItem>
								<SideDrawerItem open={isDrawerOpen} selected={pathname === route}>
									<SidebarIconWrapper open={isDrawerOpen}>{icon}</SidebarIconWrapper>
									<ListItemText
										primaryTypographyProps={{ fontWeight: 600 }}
										primary={title}
										sx={{ opacity: isDrawerOpen ? 1 : 0 }}
									/>
								</SideDrawerItem>
							</ListItem>
						</Link>
					))}
				</List>
			</Drawer>
			{/* @ts-ignore */}
			<AppContent open={isDrawerOpen}>{children}</AppContent>
		</Box>
	);
};
