import * as Yup from 'yup';

export const initialFormValues = {
	email: '',
	password: '',
};

export const signinSchema = Yup.object().shape({
	email: Yup.string().email('Invalid email').required('Required'),
	password: Yup.string()
		.min(8, 'This password is too short.')
		.max(50, 'This password is too long.')
		.required('Required'),
});
