/* eslint-disable react/prop-types */
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, ButtonBase, Typography } from '@mui/material';

export const ModalLinkCell = ({ column, ...props }) => {
	const [ searchParams, setSearchParams] = useSearchParams();
	const link = props.getValue();

	if (!link) return <Typography fontSize={'1.2rem'}>-</Typography>;

	const handleClick = () => {
		const newParams = { [link.label]: link.value };
		const currentSearchParams = new URLSearchParams(window.location.search);
		currentSearchParams.forEach((value, key) => {
			newParams[key] = value;
		});
		setSearchParams(newParams);
	};

	return (
		<ButtonBase onClick={handleClick}>
			<Box
				display={'flex'}
				alignItems={'center'}
				className={props.className ? props.className : ''}
			>
				{column.icon && <Box mr={1}>{column.icon}</Box>}

				<Typography
					sx={{
						fontWeight: column?.fontWeight ? column.fontWeight : 400,
						textDecoration: 'underline',
						fontSize: '12px',
					}}
				>
					{'see information'}
				</Typography>
			</Box>
		</ButtonBase>
	);
};
