import React, { ReactNode } from 'react';
import { Typography, Box, styled, Grid } from '@mui/material';
import { TypographyProps } from '@mui/system';

import { useHeaderTitle } from 'hooks';
import { COLORS } from 'utils/consts';

import { BackButton } from 'components/Buttons/BackButton/BackButton';

const StyledHeader = styled('header')({
	backgroundColor: COLORS.background,
	padding: '40px 0',
});

const StyledNav = styled('nav')({
	display: 'flex',
	alignItems: 'center',
	width: '100%'
});

const HeaderTitle = styled(Typography)<
  TypographyProps & { component: React.ElementType }
>(() => ({
	fontSize: '40px',
	fontWeight: 600,
	lineHeight: 1.5,
	textTransform: 'capitalize',
}));

export const AdminHeader = () => {
	const { 
		title, 
		headerDescription, 
		hasBackButton, 
		headerButton,
		headerElement
	}: { 
    title: string | React.ReactNode, 
    headerDescription: string | null, 
    hasBackButton: boolean, 
    headerButton: ReactNode | string | null,
	headerElement: ReactNode | string | null,
  } = useHeaderTitle();

	return (
		<StyledHeader>
			<StyledNav>
				{hasBackButton && <BackButton />}
				<Box width={'100%'}>
					{headerElement ? (
						<React.Fragment>{headerElement}</React.Fragment>
					) : (
						<Grid container alignItems={'center'} gap={'20px'}>
							<HeaderTitle component='h1'>{title}</HeaderTitle>
							{headerButton && <>{headerButton}</>}
						</Grid>
					)}
					{headerDescription && (
						<Typography variant='h5' component='p'>
							{headerDescription}
						</Typography>
					)}
				</Box>
			</StyledNav>
		</StyledHeader>
	);
};
