/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { StyledLink } from 'components/StyledComponents';
import { getNestedValue } from 'utils';
import { TypographyVariantType } from 'models/types';

export const ExternalLinkItem = ({
	dataItem,
	item,
	color = 'primary',
	fontWeight = 600,
	variant = 'body1',
	...props
}) => {
	if (item.value)
		return (
			<Typography
				color={color}
				fontWeight={fontWeight}
				variant={variant as TypographyVariantType}
			>
				{item.value}
			</Typography>
		);

	if (!dataItem || (!dataItem[item.linkField] && !item.linkFieldPath) ) return null;

	return (
		<StyledLink
			to={
				`${item.linkType === 'email' ? 'mailto:' : ''}${item.linkField
					? dataItem[item.linkField] || ''
					: getNestedValue(dataItem, item.linkFieldPath)}`
			}
			target='_blank'
		>
			<Box display={'flex'} alignItems={'center'}>
				{item.icon && <Box mr={1}>{item.icon}</Box>}

				<Typography
					color={color}
					fontWeight={fontWeight}
					variant={variant as TypographyVariantType}
					sx={{
						wordBreak: 'break-all'
					}}
				>
					{getNestedValue(dataItem, item.fieldPath)}{' '}
					{item.devider && item.devider}
					{item.additionalFieldPath
						? getNestedValue(dataItem, item.additionalFieldPath) || null
						: null}
				</Typography>
			</Box>
		</StyledLink>
	);
};
