import { QueryKeysEnum } from 'models/enums';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	createCompanySubuser,
	disableCompanySubuser,
	enableCompanySubuser,
	getCompanySubusers,
	updateCompanySubuser,
} from 'services/UserCompanyServices';

export const useCompanyUsers = ({ params, queryParams }) => {
	const { uid } = params;
	const { is_active, ordering, page, page_size, search } = queryParams;

	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[QueryKeysEnum.COMPANY_USERS, uid, is_active, ordering, page, page_size, search],
		() => getCompanySubusers({ params, queryParams }),
		{
			enabled: !!uid,
		}
	);
	return { data, error, isLoading, isFetching, isFetched };
};

export const useMutateCompanySubuser = () => {
	const queryClient = useQueryClient();

	const postCompanySubuser = useMutation(
		({ companyId, userData }: { companyId: string | number; userData: any }) => createCompanySubuser(companyId, userData),
		{
			onSuccess: (updatedUser: any) => {
				queryClient.invalidateQueries({
					queryKey: [QueryKeysEnum.COMPANY_USERS],
					exact: false,
				});
			},
		}
	);

	const putCompanySubuser = useMutation(
		({ companyId, userData }: { companyId: string | number; userData: any }) => updateCompanySubuser(companyId, userData),
		{
			onSuccess: (updatedUser: any) => {
				queryClient.invalidateQueries({
					queryKey: [QueryKeysEnum.COMPANY_USERS],
					exact: false,
				});
			},
		}
	);
	const postEnableCompanySubuser = useMutation(
		({ companyId, userId }: { companyId: string | number; userId: string }) => enableCompanySubuser(companyId, userId),
		{
			onSuccess: (updatedUser: any) => {
				queryClient.invalidateQueries({
					queryKey: [QueryKeysEnum.COMPANY_USERS],
					exact: false,
				});
			},
		}
	);
	const postDisableCompanySubuser = useMutation(
		({ companyId, userId }: { companyId: string | number; userId: string }) => disableCompanySubuser(companyId, userId),
		{
			onSuccess: (updatedUser: any) => {
				queryClient.invalidateQueries({
					queryKey: [QueryKeysEnum.COMPANY_USERS],
					exact: false,
				});
			},
		}
	);
	return {
		// Create user
		postCompanySubuser: postCompanySubuser.mutate,
		postCompanySubuserAsync: postCompanySubuser.mutateAsync,
		postCompanySubuserLoading: postCompanySubuser.isLoading || postCompanySubuser.isLoading,
		postCompanySubuserError: postCompanySubuser.error,
		// Update user
		putCompanySubuser: putCompanySubuser.mutate,
		putCompanySubuserAsync: putCompanySubuser.mutateAsync,
		putCompanySubuserLoading: putCompanySubuser.isLoading || putCompanySubuser.isLoading,
		putCompanySubuserError: putCompanySubuser.error,
		// Enable user
		postEnableCompanySubuser: postEnableCompanySubuser.mutate,
		postEnableCompanySubuserAsync: postEnableCompanySubuser.mutateAsync,
		postEnableCompanySubuserLoading: postEnableCompanySubuser.isLoading || postEnableCompanySubuser.isLoading,
		postEnableCompanySubuserError: postEnableCompanySubuser.error,
		// Disable user
		postDisableCompanySubuser: postDisableCompanySubuser.mutate,
		postDisableCompanySubuserAsync: postDisableCompanySubuser.mutateAsync,
		postDisableCompanySubuserLoading: postDisableCompanySubuser.isLoading || postDisableCompanySubuser.isLoading,
		postDisableCompanySubuserError: postDisableCompanySubuser.error,
	};
};
