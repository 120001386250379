/* eslint-disable react/prop-types */
import React, { FC, useMemo } from 'react';
import { Pagination, PaginationItem, styled, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';

import { CustomTableCell } from '../shared/CustomCells';
import { ActionCell } from '../shared/CustomCells/ActionCell/ActionCell';
import {
	COLORS,
	COMPACT_TABLE__BODY_STYLES,
	COMPACT_TABLE__HEADER_STYLES,
} from 'utils/consts';
import { TableCustomCellEnum } from 'models/enums';
import { MEDIUM_BORDER_RADIUS } from 'utils/consts/themeConsts';
import { FullScreenIcon, SortDownIcon, SortIcon, SortUpwardIcon } from 'components/Icons';

interface RemoteDataTableInterface {
  tableShadow?: boolean;
  compact?: boolean;
  state?: any;
  data?: any[];
  columns?: any;
  onEdit?: (x: string | number) => void;
  onDelete?: (x: string | number) => void;
  onEnable?: (x: string | number) => void;
  pageCount?: number | null;
  // Table settings
  enableSorting?: boolean;
  enableRowSelection?: boolean;
  enableGlobalFilter?: boolean;
  enableFullScreenToggle?: boolean;
  enableDensityToggle?: boolean;
  enableHiding?: boolean;
  enablePagination?: boolean;
  // Custom values
  lastColumnAlignRight?: boolean;
  [key: string | number | symbol]: any;
}

const MaterialReactTableWrapper = styled('div')(
	({ tableShadow }: { tableShadow?: boolean }) => ({
		backgroundColor: COLORS.white,
		borderRadius: MEDIUM_BORDER_RADIUS,
		overflow: 'hidden',
		'& > .MuiPaper-root': {
			boxShadow: tableShadow ? '0 3px 10px rgb(0 0 0 / 0.2)' : 'none',
			borderRadius: MEDIUM_BORDER_RADIUS,
			'& > .MuiTableContainer-root': {
				borderRadius: MEDIUM_BORDER_RADIUS,
			},
		},
	})
);

export const RemoteDataTable: FC<RemoteDataTableInterface> = ({
	tableShadow = false,
	compact = true,
	state,
	data,
	columns,
	onEdit,
	onDelete,
	onEnable,
	pageCount,
	onRowsPerPageChange,

	enableSorting = true,
	enableRowSelection = true,
	enableGlobalFilter = false,
	enableFullScreenToggle = false,
	enableDensityToggle = false,
	enableHiding = false,
	enablePagination = false,

	renderCustomActions,
	...props
}) => {
	const tableColumns = useMemo(
		() =>
			columns.map((column, index, columnsArr) => ({
				...column,
				id: column.title,
				muiTableHeadCellProps: {
					align: column.alignHeader || undefined,
					style: { width: column.maxSize }
				},
				Cell: column.cellType
					? ({ cell }) =>
						column.cellType === TableCustomCellEnum.ACTION ? (
							<ActionCell
								{...cell}
								column={column}
								onEdit={onEdit}
								onEnable={onEnable}
								onDelete={onDelete}
							/>
						) : (
							<CustomTableCell
								{...cell}
								column={column}
								className={column.className ? column.className : ''}
							/>
						)
					: undefined,
			})),
		[onEdit, onDelete, onEnable, columns]
	);

	// page size change
	const onPageChange = (e) => onRowsPerPageChange(e.target.value);

	// page change
	const paginationHandler = (e, page) =>{
		props.onPaginationChange((prev) => ({ ...prev, pageIndex: page - 1 }));
	};

	return (
		<MaterialReactTableWrapper tableShadow={tableShadow}>
			<MaterialReactTable
				icons={{
					FullscreenIcon: (props) => <FullScreenIcon {...props} />,
					ArrowDownwardIcon: (props) => {
						if (props.className.includes('MuiTableSortLabel-iconDirectionDesc')) {
							return (<SortDownIcon {...props} className={`${props.className} customDesc`} />);
						} else {
							return (<SortUpwardIcon {...props} className={`${props.className} customAsc`} />);
						}
					},
					SortIcon: (props) => <SortIcon {...props} className={`${props.className} customSort`} />
				}}
				{...props}
				state={{ ...state /*density: compact ? "compact" : undefined*/ }}
				data={data || []}
				// layoutMode="grid"
				columns={tableColumns}
				muiTableBodyProps={{
					sx: {
						'& tr td': {
							border: 'none',
							'&:first-of-type': {
								paddingLeft: '20px'
							},
							'&:last-of-type': {
								paddingRight: '20px'
							}
						},
						'& tr:nth-of-type(odd)': {
							backgroundColor: COLORS.lightTransparent,
						},
					},
				}}
				enableSorting={enableSorting}
				enableRowSelection={enableRowSelection}
				// Bottom ToolbarProps start
				enableBottomToolbar={enablePagination}
				// enablePagination={enablePagination}
				manualPagination
				rowCount={props.rowCount}
				// Bottom ToolbarProps end

				// Top ToolbarProps Start
				enableTopToolbar={
					props.renderTopToolbarCustomActions ||
          enableFullScreenToggle ||
          enableDensityToggle ||
          enableHiding ||
          enableGlobalFilter ||
          renderCustomActions
				}
				enableGlobalFilter={enableGlobalFilter}
				enableFullScreenToggle={enableFullScreenToggle}
				enableHiding={enableHiding}
				enableDensityToggle={enableDensityToggle}
				renderTopToolbarCustomActions={renderCustomActions}
				// Top ToolbarProps End

				muiTableBodyRowProps={{ hover: false }}
				muiTableProps={{ sx: { boxShadow: 'none' } }}
				enableColumnActions={false}
				enableColumnFilters={false}
				// labelDisplayedRows={({ from, to, count }) =>
				//   `${from}-${to} OF ${count !== -1 ? count : `MORE THAN ${to}`}`
				// }
				muiTablePaginationProps={() => ({
					onRowsPerPageChange: onPageChange,
					rowsPerPage: pageCount || 10,
					showFirstButton: false,
					showLastButton: false,
					page: state.pagination.pageIndex,
					ActionsComponent: (subProps) => {
						const { page: currentPage, count, rowsPerPage } = subProps;
						return (
							<Pagination
								page={currentPage + 1}
								count={Math.ceil(count / rowsPerPage)}
								shape='rounded'
								color='primary'
								siblingCount={0}
								boundaryCount={1}
								renderItem={(item) => {
									return (
										<PaginationItem
											slots={{
												previous: () => (
													// @ts-ignore
													<Typography
														{...item}
														disabled={item.disabled}
														color={
															item.disabled
																? COLORS.mediumLightGray
																: COLORS.white
														}
														textTransform='uppercase'
														fontSize={12}
														fontWeight={500}
													>
                            Previous
													</Typography>
												),
												next: () => (
													// @ts-ignore
													<Typography
														{...item}
														disabled={item.disabled}
														color={
															item.disabled
																? COLORS.mediumLightGray
																: COLORS.white
														}
														textTransform='uppercase'
														fontSize={12}
														fontWeight={500}
													>
                            Next
													</Typography>
												),
											}}
											{...item}
										/>
									);
								}}
								onChange={paginationHandler}
							/>
						);
					},
				})}
				muiTableHeadRowProps={{
					sx: {
						boxShadow: 'none'
					}
				}}
				muiTableHeadCellProps={
					{
						sx: {
							borderBottom: `1px solid ${COLORS.darkTransparent}`,
							paddingLeft: '10px',
							paddingRight: '10px',
							'&:first-of-type': {
								paddingLeft: '20px'
							},
							'&:last-of-type': {
								paddingRight: '20px'
							},
							...(compact ? { sx: { ...COMPACT_TABLE__HEADER_STYLES } } : null)
						}
					}
				}
				muiTableBodyCellProps={{
					sx: {
						...(compact ? COMPACT_TABLE__BODY_STYLES : {
							flex: 1
						}),
					}
				}}
				muiTablePaperProps={{
					sx: {
						paddingBottom: '1rem'
					}
				}}
				muiBottomToolbarProps={{
					sx: {
						borderTop: `1px solid ${COLORS.darkTransparent}`
					}
				}}
			/>
		</MaterialReactTableWrapper>
	);
};
