/* eslint-disable react/prop-types */
import React from 'react';
import { CardItemTypeEnum } from 'models/enums';
import { LinkItem } from './LinkItem/LinkItem';
import { TextItem } from './/TextItem/TextItem';
import { ListItem } from './ListItem/ListItem';
import { ListLinkItem } from './ListLinkItem/ListLinkItem';
import { ListChipItem } from './ListChipItem/ListChipItem';
import { DateItem } from './DateItem/DateItem';
import { ExternalLinkItem } from './ExternalLinkItem/ExternalLinkItem';

export const CustomCardItem = ({ dataItem, item, ...props }) => {
	switch (item.type) {
	case CardItemTypeEnum.LINK:
		return (
			<LinkItem
				{...props}
				mainDataItem={dataItem}
				dataItem={dataItem}
				item={item}
			/>
		);
	case CardItemTypeEnum.TEXT:
		return (
			<TextItem
				{...props}
				mainDataItem={dataItem}
				dataItem={dataItem}
				item={item}
			/>
		);
	case CardItemTypeEnum.DATE:
		return (
			<DateItem
				{...props}
				mainDataItem={dataItem}
				dataItem={dataItem}
				item={item}
			/>
		);
	case CardItemTypeEnum.LIST:
		return (
			<ListItem
				{...props}
				mainDataItem={dataItem}
				dataItem={dataItem}
				item={item}
			/>
		);
	case CardItemTypeEnum.LIST_CHIP:
		return (
			<ListChipItem
				{...props}
				mainDataItem={dataItem}
				dataItem={dataItem}
				item={item}
			/>
		);
	case CardItemTypeEnum.LIST_LINK:
		return (
			<ListLinkItem
				{...props}
				mainDataItem={dataItem}
				dataItem={dataItem}
				item={item}
			/>
		);
	case CardItemTypeEnum.EXTERNAL_LINK:
		return (
			<ExternalLinkItem
				{...props}
				mainDataItem={dataItem}
				dataItem={dataItem}
				item={item}
			/>
		);
	default:
		return (
			<TextItem
				{...props}
				mainDataItem={dataItem}
				dataItem={dataItem}
				item={item}
			/>
		);
	}
};
