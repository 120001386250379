export enum StatusColorEnum {
    ACTIVE = '#2DAC00',
    INACTIVE = '#000',
    DORMANT = '#FFB949',
    WITHDRAWN = '#000',
    CANCELLED = '#000',
}

export enum StatusEnum {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    DORMANT = 'dormant',
    WITHDRAWN = 'withdrawn',
    CANCELLED = 'cancelled',
}