import AuthClient from './Clients/AuthClient';
import { generateQuery } from 'utils';
import { GetCurrenciesParamsInterface } from 'models/interfaces';

const currencyServicesUrl = '/currency';

export const getCurrencies = async () => {
	const currencyResponse = await AuthClient.get(currencyServicesUrl);
	return currencyResponse.data;
};

const currencyServicesV1Url = '/v1/currencies/';

export const getCurrenciesV1 = async ({ queryParams }: { queryParams?: GetCurrenciesParamsInterface }) => {
	const currencyResponse = await AuthClient.get(currencyServicesV1Url + generateQuery(queryParams));
	return currencyResponse.data;
};

export const getCurrencyV1 = async ({ params }: { params: any }) => {
	const currencyResponse = await AuthClient.get(`${currencyServicesV1Url}${params.uid}/`);
	return currencyResponse.data;
};
