import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import  BackgroundLogo from 'assets/icons/logo-bg.svg';

export const RequestCompanyFeature = () => {
	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Paper 
					elevation={0}
					sx={{ 
						borderRadius: '10px', 
						padding: '20px 20px 40px', 
						textAlign: 'center',
						minHeight: 'calc(100vh - 250px)',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}} 
				>
					<Box 
						maxWidth={'750px'} 
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '20px',
							backgroundImage: `url(${BackgroundLogo})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'auto',
							backgroundPosition: 'top left',
							padding: '70px 0 0'
						}}
					>
						<Typography variant='subtitle2' component='h2' fontSize={20} marginBottom={'10px'}>
                            Unregistered Company: No Data Available
						</Typography>
						<Typography>
                            It seems your company isn&apos;t registered with us yet. No worries – we&apos;re here to assist!
						</Typography>
						<Typography>
                            To take control and start showcasing your business, simply send us a quick message. We&apos;ll walk you through the process. Let&apos;s ensure your company gets the recognition it deserves!
						</Typography>
					</Box>
				</Paper>
			</Grid>
		</Grid>
	);
};
