import AuthClient from './Clients/AuthClient';
import AuthClientMultipartFormData from './Clients/AuthClientMultipartFormData';
import { generateQuery } from 'utils';


const WDALicensesUrl = '/v2/wda_licenses/';

export const getWDALicenses = async ({ queryParams }: { queryParams: any }) => {
	const tendersResponse = await AuthClient.get(
		WDALicensesUrl + generateQuery(queryParams)
	);
	return tendersResponse.data;
};

const WDACompanyUrl = '/v2/companies_wda/';

export const getWDACompany = async ({ params }: { params: any }) => {
	const WDACompanyResponse = await AuthClient.get(`${WDACompanyUrl}${params.uid}/`);
	return WDACompanyResponse.data;
};

export const updateWDACompany = async ({uid, body} : {uid: string | number, body: any}) => {
	const WDACompanyResponse = await AuthClientMultipartFormData.patch(`${WDACompanyUrl}${uid}/`, body);
	return WDACompanyResponse.data;
};

export const getMyWDACompany = async () => {
	const WDACompanyResponse = await AuthClient.get(`${WDACompanyUrl}my_company/`);
	return WDACompanyResponse.data;
};