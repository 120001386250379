import React, { useState, useCallback, useMemo, ReactNode } from 'react';
import { AppDataContext } from './AppDataContext';

export const AppDataContextProvider = ({ children }: any) => {
	const [headerTitle, setHeaderTitle] = useState<string | ReactNode>('');
	const [headerDescription, setHeaderDescription] = useState<string | null>(null);
	const [headerBtn, setHeaderBtn] = useState<ReactNode | string | null>('');
	const [headerEl, setHeaderEl] = useState<ReactNode | string | null>('');

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const setHeader = useCallback((title: string | ReactNode) => setHeaderTitle(title), []);
	const setHeaderDesc = useCallback((description: string | null) => setHeaderDescription(description), []);
	const setHeaderButton = useCallback((button: ReactNode | string | null) => setHeaderBtn(button), []);
	const setHeaderElement = useCallback((el: ReactNode | string | null) => setHeaderEl(el), []);

	const setLoading = useCallback((loading: boolean) => setIsLoading(loading), []);

	const store = useMemo(
		() => ({
			isLoading,
			headerTitle,
			headerDescription,
			headerBtn,
			headerEl,
			setLoading,
			setHeader,
			setHeaderDesc,
			setHeaderButton,
			setHeaderElement
		}),
		[
			isLoading, 
			headerTitle, 
			headerDescription, 
			headerBtn, 
			headerEl,
			setLoading, 
			setHeader, 
			setHeaderDesc, 
			setHeaderButton,
			setHeaderElement
		]
	);

	return <AppDataContext.Provider value={store}>{children}</AppDataContext.Provider>;
};
