/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { StyledTreeItem } from '../shared/StyledTreeItem';
import { Box, Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { COLORS } from 'utils/consts';
import { Nested } from '../Nested/Nested';

export const Tree = ({ list, service, state, ...props }) => {
	const [expanded, setExpanded] = useState<string[]>([]);
	return (
		<Fragment>
			<Box p={2} pt={4}>
				<Container maxWidth={false}>
					<Grid container>
						<Grid item xs={8}>
							<Typography
								fontSize={'14px'}
								fontWeight={500}
								color={COLORS.darkBlack}
								textTransform={'capitalize'}
								sx={{ paddingRight: '5px' }}
							>
                Class
							</Typography>
						</Grid>
						<Grid item xs={4} textAlign={'right'}>
							<Typography
								fontSize={'14px'}
								fontWeight={500}
								color={COLORS.darkBlack}
							>
                ATC code
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<TreeView
				expanded={expanded}
				aria-label='customized'
				sx={{ overflowX: 'hidden' }}
				disableSelection={true}
				onNodeToggle={(event, nodeIds) => {
					const parentIDs = list.map((e) => e.id + '');
					const filteredArray = nodeIds.filter((value) =>
						parentIDs.includes(value)
					);
					if (filteredArray.length > 1) {
						filteredArray.shift();
						const updatedNodeIDs = nodeIds.filter(
							(val) => !filteredArray.includes(val)
						);
						setExpanded(updatedNodeIDs);
					} else {
						setExpanded(nodeIds);
					}
				}}
				{...props}
			>
				{list?.map((item) => (
					<StyledTreeItem
						key={item.id}
						nodeId={item.id + ''}
						label={`${item.code}: ${item.name}`}
						collapseIcon={<></>}
						expandIcon={<></>}
						endIcon={<></>}
						className='first'
						// disabled={!item.children || item.children.length === 0}
					>
						{Array.isArray(item.children) ? (
							<Nested parent_id={item.id} service={service} />
						) : null}
					</StyledTreeItem>
				))}
			</TreeView>
		</Fragment>
	);
};
