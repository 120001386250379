import axios from 'axios';
import { LocalStorageValuesEnum } from 'models/enums';
import { parseJwt } from 'utils';

const instance = axios.create({
	baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
	headers: {
		'content-type': 'application/json',
	},
});

const isTokenExpired = (token: any, type?: string) => {
	if (token) {
		const tokenData = parseJwt(token);
		if (tokenData?.exp) {
			return !(tokenData.exp * 1000 - new Date().getTime() > 0);
		} else {
			return true;
		}
	} else {
		return true;
	}
};
let retryCount = 0;

const handleTokenRefreshError = async () => {
	localStorage.removeItem(LocalStorageValuesEnum.ACCESS_TOKEN);
	localStorage.removeItem(LocalStorageValuesEnum.REFRESH_TOKEN);
	await new Promise(resolve => setTimeout(resolve, 1000)); // Optional delay
	window.location.reload();
};

instance.interceptors.request.use(
	(config) => {
		//@ts-ignore
		if (config.headers === undefined) config.headers = {};

		const token =
      localStorage.getItem(LocalStorageValuesEnum.ACCESS_TOKEN) || sessionStorage.getItem(LocalStorageValuesEnum.ACCESS_TOKEN);
		if (token) {
			//@ts-ignore
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(res) => res,
	async (err) => {
		const originalConfig = err.config;

		const accessToken = localStorage.getItem(LocalStorageValuesEnum.ACCESS_TOKEN);
		const isAccessExpired = isTokenExpired(accessToken, 'ACCESS');

		const token = localStorage.getItem(LocalStorageValuesEnum.REFRESH_TOKEN);
		const isRefreshExpired = isTokenExpired(token, 'REFRESH');
		if (originalConfig.url !== '/auth/signin/' && err.response && err.response.status === 401 && retryCount < 7) {
			retryCount++;
			const token = localStorage.getItem(LocalStorageValuesEnum.REFRESH_TOKEN);
			if (isAccessExpired || !accessToken) {
				if (!token || isRefreshExpired) {
					await handleTokenRefreshError();
				} else {
					try {
						const rs = await instance.post('/auth/jwt/refresh/', {
							refresh: token
						});

						const { access } = rs.data;
						localStorage.setItem(LocalStorageValuesEnum.ACCESS_TOKEN, access);

						await new Promise(resolve => setTimeout(resolve, 1000));
						return instance(originalConfig);
					} catch (_error) {
						return Promise.reject(_error);
					}
				}
			}
		} else if (err.response.status !== 401) {
			console.info('Other non 401 reason', err);
		} else {
			await handleTokenRefreshError();
		}
		return Promise.reject(err.response);
	}
);

export default instance;
