/* eslint-disable react/prop-types */
import React from 'react';

export const FullScreenIcon = ({
	stroke = '#0494F1',
	width = '22',
	height = '22',
	...props
}) => (
	<svg
		width={width}
		height={height}
		viewBox='0 0 22 22'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M1.66699 7.00097V1.66797M1.66699 1.66797H6.99999M1.66699 1.66797L8.33299 8.33397M1.66699 14.999V20.332M1.66699 20.332H6.99999M1.66699 20.332L8.33299 13.666M20.333 7.00097V1.66797M20.333 1.66797H15M20.333 1.66797L13.667 8.33397M20.333 14.999V20.332M20.333 20.332H15M20.333 20.332L13.667 13.666'
			stroke={stroke}
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
