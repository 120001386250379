export enum CountryFlagsEnum {
  EGP = 'eg',
  USD = 'us',
  AED = 'ae',
  AFN = 'af',
  ALL = 'al',
  AMD = 'am',
  ANG = 'an',
  AOA = 'ao',
  ARS = 'ar',
  AUD = 'au',
  AWG = 'aw',
  AZN = 'az',
  BAM = 'ba',
  BBD = 'bb',
  BDT = 'bd',
  BGN = 'bg',
  BHD = 'bh',
  BIF = 'bi',
  BMD = 'bm',
  BND = 'bn',
  GBP = 'gb',
  LEV = 'bg',
  HRK = 'hr',
  CZK = 'cz',
  DKK = 'dk',
  EUR = 'eu',
  HUF = 'hu',
  ISK = 'is',
  NOK = 'no',
  PLN = 'pl',
  RON = 'ro',
  SEK = 'se',
  CHF = 'ch',
  TRY = 'tr',
  UAH = 'ua',
  MDL = 'md',
}
