/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { BaseButton } from 'components/Buttons';

export const DisableUserModalContent = ({ onSubmit, onCancel, userToBeDisabled }) => {
	return (
		<Box>
			<Typography textAlign={'center'} mb={2}>
        Are you sure you want to remove {userToBeDisabled.first_name + ' ' + userToBeDisabled.last_name} from this licensed seat?
        All notes created would remain in your company records.
			</Typography>
			<Box mb={2}>
				<BaseButton onClick={() => onSubmit(userToBeDisabled.id)} label={'Confirm'} />
			</Box>
			<Box mb={2}>
				<BaseButton onClick={onCancel} variant={'outlined'} label={'Cancel'} />
			</Box>
		</Box>
	);
};
