import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Navigate, Route, BrowserRouter, Routes } from 'react-router-dom';
import { useUser, useAppData, ReferenceDataProvider } from 'context';

import { AppLayout, AuthLayout, AdministratorLayout } from 'components/Layouts';
import { PageSpinner, Loader } from 'components/Indicators';
import { NotificationAlerts } from './components/Notifications/NotificationAlerts/NotificationAlerts';

// Route components for unauthenticated users
import {
	Authenticate,
	ActivateAccount,
	ResendActivationLink,
	ForgotPassword,
	ChangePassword,
} from 'pages/Authentification';
// Route components for authenticated administrator users
import { 
	AdministratorHome, 
	CompanyProfile,
	EditCompanyProfile
} from 'pages/Administrator';
// Route components for authenticated non administrator users
import {
	Home,
	EditProfile,
	ProductExplorer,
	Product,
	// CompanyExplorer,
	// Company,
	ParallelTradeSearch,
	ATCCodesExplorer,
	ATCCode,
	Shortages,
	Tenders,
	Tender,
	WDALicenses,
	WDACompany
} from 'pages/Authenticated';

import { ApplicationRoutesEnum } from 'models/enums';
import 'App.css';
import theme from 'theme';
import { ActiveIngredient } from 'pages/Authenticated/Product/ActiveIngredient/ActiveIngredient';
import { 
	FreeHome, 
	Products, 
	Tenders as TendersFree,
	PTExplorer,
	Shortages as ShortagesFree,
	ATCExplorer
} from 'pages/FreeUser';
import { Maintanance } from 'pages/Maintanance/Maintanance';

function App() {
	const { user, userAuthenticating, appIsDeploying } = useUser();
	const { isLoading } = useAppData();
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<BrowserRouter>
					{userAuthenticating ? (
						<PageSpinner />
					) : appIsDeploying ? (
						<ReferenceDataProvider>
							{/* <AppLayout> */}
							<Routes>
								<Route
									path={ApplicationRoutesEnum.HOME}
									element={<Maintanance />}
								/>
							</Routes>
							{/* </AppLayout> */}
						</ReferenceDataProvider>
					) : !user ? (
						<AuthLayout>
							<Routes>
								<Route
									path={ApplicationRoutesEnum.SIGNIN}
									element={<Authenticate />}
								/>
								<Route
									path={ApplicationRoutesEnum.REGISTER}
									element={<Authenticate />}
								/>
								<Route
									path={ApplicationRoutesEnum.FORGOT_PASSWORD}
									element={<ForgotPassword />}
								/>
								<Route
									path={ApplicationRoutesEnum.CHANGE_PASSWORD}
									element={<ChangePassword />}
								/>
								<Route
									path={ApplicationRoutesEnum.ACTIVATE_ACCOUNT}
									element={<ActivateAccount />}
								/>
								<Route
									path={ApplicationRoutesEnum.RESEND_ACTIVATION}
									element={<ResendActivationLink />}
								/>
								<Route
									path={ApplicationRoutesEnum.ALL}
									element={
										<Navigate
											to={ApplicationRoutesEnum.SIGNIN}
											state={{ from: window.location.pathname, search: window.location.search }}
											replace={true}
										/>
									}
								/>
							</Routes>
						</AuthLayout>
					) : user && user.is_admin ? (
						<AdministratorLayout>
							<Routes>
								<Route
									path={ApplicationRoutesEnum.HOME}
									element={<AdministratorHome />}
								/>
								<Route
									path={ApplicationRoutesEnum.COMPANY_PROFILE}
									element={<CompanyProfile />}
								/>
								<Route
									path={ApplicationRoutesEnum.EDIT_COMPANY_PROFILE}
									element={<EditCompanyProfile />}
								/>
								<Route
									path={ApplicationRoutesEnum.ALL}
									element={
										<Navigate to={ApplicationRoutesEnum.HOME} replace={true} />
									}
								/>
							</Routes>
						</AdministratorLayout>
					) : user && user?.company?.is_paid ? (
						<ReferenceDataProvider>
							<AppLayout>
								<Routes>
									<Route path={ApplicationRoutesEnum.HOME} element={<Home />} />
									<Route
										path={ApplicationRoutesEnum.EDIT_PROFILE}
										element={<EditProfile />}
									/>
									<Route
										path={ApplicationRoutesEnum.PRODUCT_EXPLORER}
										element={<ProductExplorer />}
									/>
									<Route
										path={ApplicationRoutesEnum.PRODUCT_ID}
										element={<Product />}
									/>
									<Route
										path={ApplicationRoutesEnum.INGREDIENT_ID}
										element={<ActiveIngredient />}
									/>
									{/* <Route path={ApplicationRoutesEnum.COMPANY_EXPLORER} element={<CompanyExplorer />} />
                <Route path={ApplicationRoutesEnum.COMPANY_ID} element={<Company />} /> */}
									<Route
										path={ApplicationRoutesEnum.PARALLEL_TRADE}
										element={<ParallelTradeSearch />}
									/>
									<Route
										path={ApplicationRoutesEnum.ATC_CODE_EXPLORER}
										element={<ATCCodesExplorer />}
									/>
									<Route
										path={ApplicationRoutesEnum.ATC_CODE_ID}
										element={<ATCCode />}
									/>
									<Route
										path={ApplicationRoutesEnum.SHORTAGES}
										element={<Shortages />}
									/>
									<Route
										path={ApplicationRoutesEnum.TENDER_EXPLORER}
										element={<Tenders />}
									/>
									<Route
										path={ApplicationRoutesEnum.TENDER_ID}
										element={<Tender />}
									/>
									<Route
										path={ApplicationRoutesEnum.WDA_LICENSES}
										element={<WDALicenses />}
									/>
									<Route
										path={ApplicationRoutesEnum.WDA_COMPANY}
										element={<WDACompany />}
									/>
									<Route
										path={ApplicationRoutesEnum.ALL}
										element={
											<Navigate
												to={ApplicationRoutesEnum.HOME}
												replace={true}
											/>
										}
									/>
								</Routes>
							</AppLayout>
						</ReferenceDataProvider>
					) : (
						<ReferenceDataProvider>
							<AppLayout>
								<Routes>
									<Route
										path={ApplicationRoutesEnum.HOME}
										element={<FreeHome />}
									/>
									<Route
										path={ApplicationRoutesEnum.PRODUCT_EXPLORER}
										element={<Products />}
									/>
									<Route
										path={ApplicationRoutesEnum.TENDER_EXPLORER}
										element={<TendersFree />}
									/>
									<Route
										path={ApplicationRoutesEnum.PARALLEL_TRADE}
										element={<PTExplorer />}
									/>
									<Route
										path={ApplicationRoutesEnum.SHORTAGES}
										element={<ShortagesFree />}
									/>
									<Route
										path={ApplicationRoutesEnum.ATC_CODE_EXPLORER}
										element={<ATCExplorer />}
									/>
									<Route
										path={ApplicationRoutesEnum.WDA_LICENSES}
										element={<WDALicenses />}
									/>
									<Route
										path={ApplicationRoutesEnum.WDA_COMPANY}
										element={<WDACompany />}
									/>
									{/* <Route
										path={ApplicationRoutesEnum.ALL}
										element={
											<Navigate
												to={ApplicationRoutesEnum.HOME}
												replace={true}
											/>
										}
									/> */}
								</Routes>
							</AppLayout>
						</ReferenceDataProvider>
					)}
				</BrowserRouter>
				{isLoading && <Loader />}
				<NotificationAlerts />
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

export default App;
