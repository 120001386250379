import React, { Fragment, useCallback, useMemo } from 'react';
import { Grid, Paper, Typography } from '@mui/material';

import { RemoteDataTable } from 'components/Tables';
import { FormWithAdditionaFilters } from 'components/Forms';

import { columns } from './columns';
import { searchFilters } from './searchFilters';
import { useQueryPagination } from 'hooks/useQueryPagination';
import { useWDALicenses } from 'services/queries';
import { useSearchParams } from 'hooks';

interface SearchInputsIntreface {
    search: string;
    countries: { name: string; id: string; code: string | number }[];
    license_conditions: { name: string; id: number }[];
    only_from_database: boolean;
}

const initialSearchInputs = {
	search: '',
	countries: [],
	license_conditions: [],
	only_from_database: false,
};

export const WDALicenses = () => {
	const [
		searchInputs, 
		setSearchInputs, 
		// resetSearchInputs
	] = useSearchParams<SearchInputsIntreface>(
		initialSearchInputs
	);

	const WDAQueryParams = useMemo(
		() => ({
			country__in: searchInputs.countries.map((val) => val.id).join(','),
			authorization__in: searchInputs.license_conditions
				.map((val) => val.id)
				.join(','),
			...(searchInputs.only_from_database && {
				is_profile_filled: searchInputs.only_from_database,
			}),
		}),
		[
			searchInputs.countries,
			searchInputs.license_conditions,
			searchInputs.only_from_database,
		]
	);

	const {
		data: WDAData,
		state: WDAState,
		setPagination: WDASetPagination,
		rowCount: WDARowCount,
		setSorting: WDASorting,
		pageCount: WDAPageCount,
		setPageCount: WDASetPageCount,
	} = useQueryPagination(
		useWDALicenses, 
		searchInputs.search, 
		null, 
		WDAQueryParams
	);

	const onChangeHandler = useCallback((e) => setSearchInputs((prevInputs) => ({
		...prevInputs,
		[e.target.name]: e.target.value,
	})), [setSearchInputs]);

	return (
		<Fragment>
			<FormWithAdditionaFilters
				filters={searchFilters}
				searchInputs={searchInputs}
				onChangeHandler={onChangeHandler}
				// onResetHandler={resetSearchInputs}
				compact
			/>
			<Paper sx={{ borderRadius: '1rem' }} elevation={0}>
				<Grid item xs={12}>
					<RemoteDataTable
						state={WDAState}
						data={WDAData}
						pageCount={WDAPageCount}
						onRowsPerPageChange={WDASetPageCount}
						onPaginationChange={WDASetPagination}
						rowCount={WDARowCount}
						columns={columns}
						enableRowSelection={false}
						enableFullScreenToggle
						enablePagination
						manualSorting
						enableMultiSort={false}
						onSortingChange={WDASorting}
						renderCustomActions={() => (
							<Typography fontWeight='600' p={1} pl={0} ml={-1} sx={{ alignSelf: 'center' }}>
                                EU WDA License Search
							</Typography>
						)}
					/>
				</Grid>
			</Paper>
		</Fragment >
	);
};
