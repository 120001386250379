/* eslint-disable react/prop-types */
import React, { Fragment, useMemo } from 'react';
import { getNestedValue } from 'utils';

export const PriceCell = ({ column, ...props }) => {
	const priceValue = props.getValue();
	const currency = useMemo(
		() =>
			getNestedValue(props.row.original || props.row, column.priceSymbolPath),
		[props.row, column.priceSymbolPath]
	);

	return (
		<Fragment>
			{priceValue && currency && currency}
			{priceValue}
		</Fragment>
	);
};
