/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
	Container,
	Typography,
	Box,
	AppBar,
	Avatar,
	Toolbar,
	IconButton,
	Button,
	Menu,
	MenuItem,
	Fade,
	styled,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Modal } from 'components/Modal/Modal';

import { useUser } from 'context';
import { COLORS, DRAWER_ITEMS } from 'utils/consts';
import PharmaLogo from 'assets/images/logo.svg';
import { ApplicationRoutesEnum, MaterialUISizeEnum } from 'models/enums';
import { MEDIUM_BORDER_RADIUS } from 'utils/consts/themeConsts';

const DropdownMenu = styled(Menu)({
	'& .MuiPaper-root': {
		borderRadius: MEDIUM_BORDER_RADIUS,
		minWidth: '200px',
		padding: '12px',
	},
	'& .MuiButtonBase-root': {
		'&:hover': {
			backgroundColor: COLORS.background,
		},
	},
});

const DropdownMenuIcon = styled('img')(() => ({
	width: '15px',
	height: '15px',
	marginRight: '10px',
}));

const StyledAvatar = styled(Avatar)({
	fontSize: '14px',
	fontWeight: 600,
	backgroundColor: COLORS.secondary,
	color: COLORS.black,
});

const StyledButton = styled(Button)(() => ({
	padding: '17px',
	borderRadius: MEDIUM_BORDER_RADIUS,
	width: '100%',
	marginTop: '20px',
}));

const UserInfoWrapper = styled(Box)({
	display: 'flex',
	gap: '5px',
	alignItems: 'center',
});
const SidebarLogo = styled('img')(() => ({ height: '35px' }));

export const BaseAppBar = ({ renderNavItems = true }) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { user, setUserData } = useUser();

	const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
	const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const open = Boolean(anchorEl);
	const userInitials = user
		? user?.first_name?.split('')[0] + user?.last_name?.split('')[0]
		: '';

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseModal = () => {
		setShowLogoutModal(false);
	};

	const logoutUser = () => {
		setUserData(null);
		localStorage.clear();
		sessionStorage.clear();
	};

	return (
		<>
			<AppBar position="static" color="inherit" elevation={0}>
				<Container maxWidth={false}>
					<Toolbar className="header_toolbar">
						<Box
							sx={{
								mr: 2,
								display: { xs: 'none', md: 'flex' },
							}}
						>
							<Link to={ApplicationRoutesEnum.HOME}>
								<SidebarLogo alt="Pharmafootpath icon" src={PharmaLogo} />
							</Link>
						</Box>

						<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
							{renderNavItems && (
								<>
									<IconButton
										size="large"
										aria-label="account of current user"
										aria-controls="menu-appbar"
										aria-haspopup="true"
										onClick={handleOpenNavMenu}
										color="inherit"
									>
										<MenuIcon />
									</IconButton>
									<Menu
										id="menu-appbar"
										anchorEl={anchorElNav}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'left',
										}}
										keepMounted
										transformOrigin={{
											vertical: 'top',
											horizontal: 'left',
										}}
										open={Boolean(anchorElNav)}
										onClose={handleCloseNavMenu}
										sx={{
											display: { xs: 'block', md: 'none' },
										}}
									>
										{DRAWER_ITEMS.map(({ title, route }) => (
											<Link key={route} to={route} style={{ all: 'unset' }}>
												<MenuItem>
													<Typography
														variant="body2"
														textTransform="uppercase"
														textAlign="center"
														color={pathname === route ? 'primary' : 'initial'}
														fontWeight={600}
													>
														{title}
													</Typography>
												</MenuItem>
											</Link>
										))}
									</Menu>
								</>
							)}
						</Box>
						<Box
							sx={{
								mr: 2,
								display: { xs: 'flex', md: 'none', flexGrow: 1 },
							}}
						>
							<Link to={ApplicationRoutesEnum.HOME}>
								<SidebarLogo alt="Pharmafootpath icon" src={PharmaLogo} />
							</Link>
						</Box>
						<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
							{renderNavItems && DRAWER_ITEMS.map(({ title, route }) => (
								<Link key={route} to={route} style={{ all: 'unset' }}>
									<MenuItem>
										<Typography
											variant="body2"
											textTransform="uppercase"
											textAlign="center"
											color={pathname === route ? 'primary' : 'initial'}
											fontWeight={600}
										>
											{title}
										</Typography>
									</MenuItem>
								</Link>
							))}
						</Box>
						<UserInfoWrapper>
              
							<IconButton
								size={MaterialUISizeEnum.SMALL}
								id="fade-button"
								aria-controls={open ? 'fade-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={open ? 'true' : undefined}
								onClick={handleClick}
								sx={{ color: COLORS.black }}
								disableRipple
							>
								{user?.avatar ? (
									<StyledAvatar alt="Profile image" src={user.avatar} />
								) : (
									<StyledAvatar>{userInitials}</StyledAvatar>
								)}
								<ExpandMoreIcon />
							</IconButton>
							<DropdownMenu
								id="fade-menu"
								MenuListProps={{
									'aria-labelledby': 'fade-button',
								}}
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								TransitionComponent={Fade}
							>
								{user && !user.is_admin ? (
									<MenuItem
										onClick={() => {
											navigate('edit-profile');
											handleClose();
										}}
									>
										<DropdownMenuIcon
											alt="Edit icon"
											src={require('assets/icons/edit_profile_icon.png')}
										/>{' '}
                    Edit Profile
									</MenuItem>
								) : null}

								<MenuItem
									onClick={() => {
										setShowLogoutModal(true);
										handleClose();
									}}
								>
									<DropdownMenuIcon
										alt="Logout icon"
										src={require('assets/icons/logout_icon.png')}
									/>
                  Logout
								</MenuItem>
							</DropdownMenu>
						</UserInfoWrapper>
					</Toolbar>
				</Container>
			</AppBar>
			<Modal
				show={showLogoutModal}
				modalTitle={'Logout'}
				close={handleCloseModal}
			>
				<Typography sx={{ marginBottom: '65px', marginTop: '36px' }}>
          Are you sure you want to logout?
				</Typography>
				<StyledButton variant="contained" onClick={logoutUser}>
          Yes
				</StyledButton>
				<StyledButton variant="outlined" onClick={handleCloseModal}>
          Cancel
				</StyledButton>
			</Modal>
		</>
	);
};
