/* eslint-disable react/prop-types */
import React, { Fragment, useMemo } from 'react';
import { Grid } from '@mui/material';
import { COLORS } from 'utils/consts';
import { useQueryPagination } from 'hooks';
import { PlusIcon } from 'components/Icons';
import { StyledLink } from 'components/StyledComponents';
import { StyledTreeItem } from '../shared/StyledTreeItem';

export const Nested = ({ service, parent_id }) => {
	const queryParams = useMemo(() => ({ parent_id: parent_id }), [parent_id]);

	const { data } = useQueryPagination(service, null, null, queryParams);

	return (
		<Fragment>
			{Array.isArray(data)
				? data.map((row) => (
					<StyledTreeItem
						key={row.id}
						nodeId={row.id + ''}
						label={
							<Grid container justifyContent={'space-between'}>
								<Grid item>
									{row.children && row.children.length > 0 ? (
										row.name
									) : (
										<StyledLink
											to={`/atc-code-explorer/${row.id}`}
											className='mainLink'
										>
											{row.name}
										</StyledLink>
									)}
								</Grid>
								<Grid item className='rightColumn'>
									<StyledLink
										to={`/atc-code-explorer/${row.id}`}
										color={COLORS.blue}
									>
										{row.code}
									</StyledLink>
								</Grid>
							</Grid>
						}
						collapseIcon={
							row.children && row.children.length > 0 ? (
								<PlusIcon active />
							) : (
								<></>
							)
						}
						expandIcon={
							row.children && row.children.length > 0 ? <PlusIcon /> : <></>
						}
						endIcon={
							row.children && row.children.length > 0 ? <PlusIcon /> : <></>
						}
						className={
							row.children && row.children.length > 0 ? '' : 'noIcon'
						}
						// disabled={!row.children || row.children.length === 0}
					>
						<Nested parent_id={row.id} service={service} />
					</StyledTreeItem>
				))
				: null}
		</Fragment>
	);
};
