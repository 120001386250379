/* eslint-disable react/prop-types */
import React from 'react';

export const SettingsIcon = ({ stroke = '#0494F1', ...props }) => (
	<svg width='18' height='14' viewBox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M4.33397 4.00098C4.33397 5.47393 5.52803 6.66798 7.00097 6.66798C8.47391 6.66798 9.66797 5.47393 9.66797 4.00098C9.66797 2.52804 8.47391 1.33398 7.00097 1.33398C5.52803 1.33398 4.33397 2.52804 4.33397 4.00098ZM4.33397 4.00098H1.66797M12.334 3.99998H20.334M17.668 12.332C17.668 13.8049 16.4739 14.999 15.001 14.999C13.528 14.999 12.334 13.8049 12.334 12.332C12.334 10.859 13.528 9.66498 15.001 9.66498C16.4739 9.66498 17.668 10.859 17.668 12.332ZM17.668 12.332H20.334M9.66797 12.331H1.66797'
			stroke={stroke}
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
