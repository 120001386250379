/* eslint-disable react/prop-types */
import React from 'react';

export const ProductIcon = ({ stroke = '#0494F1', ...props }) => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M6.68187 6.68218L2.91666 10.4474C1.08467 12.2794 1.08467 15.2496 2.91666 17.0816C4.74864 18.9136 7.71888 18.9136 9.55087 17.0816L17.0813 9.55118C18.9133 7.71919 18.9133 4.74895 17.0813 2.91696C15.2493 1.08497 12.2791 1.08497 10.4471 2.91696L6.68187 6.68218ZM6.68187 6.68218L11.0801 11.0804"
			stroke={stroke}
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
