import React, { useState } from 'react';
import { Popover, Box, IconButton } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const InfoTooltip = (props: any) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

	const onCloseHandler = () => setAnchorEl(null);

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<div>
			<IconButton onClick={onClickHandler} color="primary" aria-label="info">
				<ErrorOutlineIcon />
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={onCloseHandler}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
			>
				<Box sx={{ p: 3, maxWidth: props.maxWidth ? props.maxWidth : '300px' }}>{props.children}</Box>
			</Popover>
		</div>
	);
};
