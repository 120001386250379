/* eslint-disable react/prop-types */
import React, {Fragment} from 'react';

export const ArrowRightArrorLeft = ({ stroke = '#0494F1', ...props }) => (
	<Fragment>
		<svg width='20' height='34' viewBox='0 0 20 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12.9565 6.28516L18.6695 11.9982L12.9565 17.7112M5.35547 11.9982H18.6525'
				stroke={stroke}
				strokeWidth='1.5'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M11.0685 27.7112L5.35547 21.9982L11.0685 16.28516M18.6695 21.9982H5.37247'
				stroke={stroke}
				strokeWidth='1.5'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	</Fragment>
);
