import { QueryKeysEnum } from 'models/enums';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteNote, getNotes } from 'services/NotesServices';

export const useNotes = ({ queryParams }, enabled) => {
	const { created_at, ordering, page, page_size, search } = queryParams;
	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[QueryKeysEnum.NOTES, created_at, ordering, page, page_size, search],
		() => getNotes({ queryParams }),
		{ enabled }
	);
	return { data, error, isLoading, isFetching, isFetched };
};

export const useMutateNotes = () => {
	const queryClient = useQueryClient();

	const onDeleteNote = useMutation(({ params }: any) => deleteNote({ params }), {
		onSuccess: (deletedNote: any) => {
			queryClient.invalidateQueries({
				queryKey: [QueryKeysEnum.NOTES],
				exact: false,
			});
			queryClient.invalidateQueries({
				queryKey: [QueryKeysEnum.PRODUCT_NOTES],
				exact: false,
			});
			queryClient.invalidateQueries({
				queryKey: [QueryKeysEnum.ACTIVE_INGREDIENT_NOTES],
				exact: false,
			});
		},
	});
	return {
		deleteNotes: onDeleteNote.mutate,
		deleteNotesAsync: onDeleteNote.mutateAsync,
		deleteNotesLoading: onDeleteNote.isLoading || onDeleteNote.isLoading,
		deleteNotesError: onDeleteNote.error,
	};
};
