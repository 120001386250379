import React from 'react';
import { Select, MenuItem, styled } from '@mui/material';
import { COLORS } from 'utils/consts';
import { MEDIUM_BORDER_RADIUS } from 'utils/consts/themeConsts';

interface BaseDropdownProps {
  handleChange?: (x: any) => void;
  value: any;
  label: string;
  options: any[];
  sx?: any;
  inputProps?: any;
  labelId?: string;
}

const SelectWrapper = styled(Select)({
	backgroundColor: COLORS.white,
	fieldset: {
		borderRadius: MEDIUM_BORDER_RADIUS,
	},
});

export const BaseDropdown = ({ label, value, handleChange, options, labelId }: BaseDropdownProps) => {
	return (
		<SelectWrapper labelId={labelId} id={label + '-select'} value={value} onChange={handleChange} autoWidth>
			{options.map((option: any) => (
				<MenuItem key={option.label} value={option.value}>
					{option.label}
				</MenuItem>
			))}
		</SelectWrapper>
	);
};
