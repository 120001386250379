import React, { FC } from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { MaterialUISizeEnum } from 'models/enums';
import { COLORS } from 'utils/consts';
import { SMALL_VARIANT_FONT_SIZE } from 'utils/consts/themeConsts';

interface SwitchInputInterface {
  onChange: (x: any) => void;
  onBlur?: (x: any) => void;
  value?: any;
  name: string;
  label: string;
  sx?: any;
  inputProps?: any;
  size?: 'small' | 'medium';
}

const switchStyles = {
	height: '20px',
	width: '36px',
	boxSizing: 'content-box',
	'& .MuiSwitch-switchBase': {
		padding: '9px 9px',
		'&:hover, &:focus, &:active': {
			backgroundColor: 'transparent !important',
			'& .MuiTouchRipple-root': {
				backgroundColor: 'transparent !important',
				'&:after, &:before': {
					content: 'none',
				},
			},
		},
		'&.Mui-checked': {
			padding: '9px 9px',
			color: 'transparent',
			'&:hover, &:focus, &:active': {
				backgroundColor: 'transparent !important',
				'& .MuiTouchRipple-root': {
					backgroundColor: 'transparent !important',
					'&:after, &:before': {
						content: 'none',
					},
				},
			},
		},
	},
	'& .MuiSwitch-thumb': {
		background: COLORS.white,
		width: '16px',
		height: '16px',
	},
	'& .MuiSwitch-track': {
		background: '#CACDC9',
		borderRadius: '12px',
	},
	'& .Mui-checked+.MuiSwitch-track': {
		background: COLORS.successGreen,
		opacity: '1 !important',
	},
};

export const BaseSwitchInput: FC<SwitchInputInterface> = ({
	label,
	value,
	name,
	size = 'small',
	onChange
}) => {
	return (
		<FormGroup sx={{ paddingBottom: '5px' }}>
			<FormControlLabel
				labelPlacement="start"
				sx={{
					fontSize: size === MaterialUISizeEnum.SMALL
						? SMALL_VARIANT_FONT_SIZE
						: undefined,
					justifyContent: 'left'
				}}
				control={
					<Switch
						size={size}
						color={'success'}
						name={name}
						onChange={(e) =>
							onChange({
								...e,
								target: {
									...e.target,
									name: e.target.name,
									value: e.target.checked,
								},
							})
						}
						value={value}
						checked={value}
						sx={switchStyles}
					/>
				}
				label={label}
			/>
		</FormGroup>
	);
};
