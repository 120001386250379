/* eslint-disable react/prop-types */
import React from 'react';

export const CompanyIcon = ({ stroke = '#0494F1', ...props }) => (
	<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M6.99999 7.7831L1.66699 10.333V16.999H6.99999M5.66699 1.00098L6.99999 1.24338M16.333 16.997V12.999M6.99999 16.999H20.333V3.66798L6.99999 1.24338M6.99999 16.999V1.24338"
			stroke={stroke}
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
