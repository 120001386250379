import React from 'react';
import { Paper } from '@mui/material';

import { useQueryPagination } from 'hooks';

import { useATCCodes } from 'services/queries';
import { Tree } from 'components/Tree';

export const ATCCodesExplorer = () => {
	const { data: atcData, state: atcState } = useQueryPagination(useATCCodes);

	return (
		<Paper sx={{ borderRadius: '1rem', paddingBottom: '20px' }} elevation={0}>
			<Tree list={atcData} service={useATCCodes} state={atcState} />
		</Paper>
	);
};
