/* eslint-disable react/prop-types */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { StyledLink } from 'components/StyledComponents';
import { getNestedValue } from 'utils';
import { COLORS } from 'utils/consts';

export const LinkCell = ({ column, ...props }) => {
	const { pathname, search } = useLocation();
	const dataItem = props.row.original;
	return (
		<StyledLink
			to={{
				pathname: `/${column.linkBase}${
					column.linkField
						? dataItem[column.linkField]
						: getNestedValue(dataItem, column.linkFieldPath)
				}`,
				search: `${column.linkQuery ? column.linkQuery : ''}`,
			}}
			state={{ from: pathname + search }}
		>
			<Box
				display={'flex'}
				alignItems={'center'}
				className={props.className ? props.className : ''}
			>
				{column.icon && <Box mr={1}>{column.icon}</Box>}

				<Typography
					color='primary'
					sx={{ 
						fontWeight: column?.fontWeight ? column.fontWeight : 600, 
						'&:hover': {
							color: COLORS.primaryDark
						}
					}}
					variant='body2'
				>
					{props.getValue()}
				</Typography>
			</Box>
		</StyledLink>
	);
};
