import React, { Fragment, useCallback, useMemo } from 'react';
import { Grid, Paper, Typography } from '@mui/material';

import { RemoteDataTable } from 'components/Tables';
import { FormWithAdditionaFilters } from 'components/Forms';

import { columns } from './columns';
import { searchFilters } from './searchFilters';
import { useQueryPagination } from 'hooks/useQueryPagination';
import { useTenders } from 'services/queries';
import { useSearchParams } from 'hooks';

interface SearchInputsIntreface {
  countries: { name: string; id: string; code: string | number }[];
  active_ingredient: { name: string; id: number }[];
  closed_tenders: boolean;
}

const initialSearchInputs = {
	countries: [],
	active_ingredient: [],
	closed_tenders: false,
};

export const Tenders = () => {
	const [
		searchInputs, 
		setSearchInputs, 
		// resetSearchInputs
	] = useSearchParams<SearchInputsIntreface>(
		initialSearchInputs
	);

	const tenderQueryParams = useMemo(
		() => ({
			country__in: searchInputs.countries.map((val) => val.id).join(','),
			active_ingredient__in: searchInputs.active_ingredient
				.map((val) => val.id)
				.join(','),
			...(!searchInputs.closed_tenders && {
				show_closed: searchInputs.closed_tenders,
			}),
		}),
		[
			searchInputs.countries,
			searchInputs.active_ingredient,
			searchInputs.closed_tenders,
		]
	);

	const {
		data: tenderData,
		state: tenderState,
		setPagination: tenderSetPagination,
		rowCount: tenderRowCount,
		setSorting: tenderSorting,
		pageCount: tenderPageCount,
		setPageCount: tenderSetPageCount,
	} = useQueryPagination(useTenders, null, null, tenderQueryParams, {
		sort: [{ desc: true, id: 'publication_date' }],
	});

	const onChangeHandler = useCallback((e) => {
		setSearchInputs((prevInputs) => ({
			...prevInputs,
			[e.target.name]: e.target.value,
		}));
	}, []);

	return (
		<Fragment>
			<FormWithAdditionaFilters
				filters={searchFilters}
				searchInputs={searchInputs}
				onChangeHandler={onChangeHandler}
				// onResetHandler={resetSearchInputs}
				compact
			/>
			<Paper sx={{ borderRadius: '1rem' }} elevation={0}>
				<Grid item xs={12}>
					<RemoteDataTable
						state={tenderState}
						data={tenderData}
						pageCount={tenderPageCount}
						onRowsPerPageChange={tenderSetPageCount}
						onPaginationChange={tenderSetPagination}
						rowCount={tenderRowCount}
						columns={columns}
						enableRowSelection={false}
						enableFullScreenToggle
						enablePagination
						manualSorting
						enableMultiSort={false}
						onSortingChange={tenderSorting}
						renderCustomActions={() => (
							<Typography fontWeight='600' p={1} sx={{ alignSelf: 'center' }}>
                EU Pharmaceutical Tenders
							</Typography>
						)}
					/>
				</Grid>
			</Paper>
		</Fragment >
	);
};
