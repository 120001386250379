import React, { ReactNode } from 'react';
import { Typography, Box, styled } from '@mui/material';

import { BackButton } from 'components/Buttons/BackButton/BackButton';
import { COLORS } from 'utils/consts';
import { useHeaderTitle } from 'hooks';
import { HeaderTitle } from 'components/StyledComponents';

const StyledHeader = styled('header')({
	backgroundColor: COLORS.background,
	padding: '40px 0',
});

const StyledNav = styled('nav')({
	display: 'flex',
	alignItems: 'center',
	width: '100%'
});

export const Header = () => {
	const { 
		title, 
		headerDescription, 
		hasBackButton, 
		headerButton, 
		headerElement
	} : { 
		title: string | React.ReactNode, 
		headerDescription: string | null, 
		hasBackButton: boolean, 
		headerButton: ReactNode | string | null,
		headerElement: ReactNode | string | null,
	} = useHeaderTitle();

	return (
		<StyledHeader>
			<StyledNav>
				{hasBackButton && <BackButton />}
				<Box sx={{width: '100%'}}>
					{headerElement ? (
						<React.Fragment>{headerElement}</React.Fragment>
					) : (
						<Box sx={{display: 'inline-block'}}>
							<HeaderTitle component='h1'>{title}</HeaderTitle>
							{headerButton && <>{headerButton}</>}
						</Box>
					)}
					{headerDescription && (
						<Typography variant='h5' component='p'>
							{headerDescription}
						</Typography>
					)}
				</Box>
			</StyledNav>
		</StyledHeader>
	);
};
