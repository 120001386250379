import AuthClient from './Clients/AuthClient';
import { GetClinicalTrialsParamsInterface } from 'models/interfaces';
import { generateQuery } from 'utils';

const clinicalTrialsServicesUrl = '/v1/clinical-trials/';

export const getClinicalTrials = async ({ queryParams }: { queryParams?: GetClinicalTrialsParamsInterface }) => {
	const clinicalTrialsResponse = await AuthClient.get(clinicalTrialsServicesUrl + generateQuery(queryParams));
	return clinicalTrialsResponse.data;
};

export const getClinicalTrial = async ({
	params,
	queryParams,
}: {
  params: any;
  queryParams?: GetClinicalTrialsParamsInterface;
}) => {
	const clinicalTrialResponse = await AuthClient.get(`${clinicalTrialsServicesUrl}${params.uid}/${generateQuery(queryParams)}`);
	return clinicalTrialResponse.data;
};
