import React, { useState, useRef, useCallback, useLayoutEffect } from 'react';
import { Chip, Grid, Tooltip, Typography } from '@mui/material';
import { COLORS } from 'utils/consts';
import { Box } from '@mui/system';

interface ChipData {
  id: string;
  name: string;
}

const SingleLineChips: React.FC<{ data: ChipData[] }> = ({ data }) => {
	const [visibleChips, setVisibleChips] = useState<ChipData[]>([]);
	const [overflowChips, setOverflowChips] = useState<ChipData[]>([]);
	const containerRef = useRef<HTMLDivElement>(null);


	const measureTextWidth = useCallback((text: string): number => {
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');
		if (context) {
			context.font = '16px Codec-Pro';
			const width = context.measureText(text).width;
			return width + 40; // Added some extra space for the chip
		}
		return 0;
	},[]);

	useLayoutEffect(() => {
		setTimeout(() => {
			if (containerRef.current && data) {
				const containerWidth = containerRef.current.clientWidth;
				let totalWidth = 0;
				const visible: ChipData[] = [];
				const overflow: ChipData[] = [];
  
				for (let i = 0; i < data.length; i++) {
					const chipWidth = measureTextWidth(data[i].name);
					totalWidth += chipWidth;
					if (totalWidth < containerWidth) {
						visible.push(data[i]);
					} else {
						overflow.push(data[i]);
					}
				}
				setVisibleChips(visible);
				setOverflowChips(overflow);
			}
		}, 50);
	}, [data, measureTextWidth, containerRef]);


	return (
		<>
			<Box ref={containerRef} sx={{maxWidth: '100%', boxSizing: 'content-box'}}></Box>
			<Grid container gap={1} pt={1} pb={1} overflow={'hidden'} maxWidth={'100%'}>
				{visibleChips?.map((item, index) => (
					<Chip
						key={item.id}
						label={item.name}
						sx={{
							fontSize: '14px',
							borderRadius: '5px',
							opacity: '.8',
							backgroundColor: COLORS.lightAqua,
							color: COLORS.lightTransparentBlue,
						}}
					/>
				))}
				{overflowChips?.length > 0 && (
					<Tooltip
						title={overflowChips.map((item) => (
							<Typography variant='body2' key={item.id+item.name}>{item.name}</Typography>
						))}
						disableFocusListener
					>
						<Chip
							label={`+${overflowChips.length}`}
							sx={{
								fontSize: '14px',
								borderRadius: '5px',
								backgroundColor: COLORS.lightAqua,
								color: COLORS.lightTransparentBlue,
							}}
						/>
					</Tooltip>
				)}
			</Grid>
		</>
	);
};

export { SingleLineChips };
