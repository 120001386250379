import AuthClient from './Clients/AuthClient';
import { generateQuery } from 'utils';

const companiesServicesUrl = '/v1/parallel-trade-licenses/';

export const getParallelTradeLicenses = async ({ queryParams }: any) => {
	const licensesResponse = await AuthClient.get(companiesServicesUrl + generateQuery(queryParams));
	return licensesResponse.data;
};

export const getParallelTradeLicensesByCompany = async ({ queryParams }) => {
	const licensesResponse = await AuthClient.get(`${companiesServicesUrl}` + generateQuery(queryParams));
	return licensesResponse.data;
};
