import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Paper, Typography } from '@mui/material';

import { useQueryPagination } from 'hooks';
import { useTender, useTenderLots } from 'services/queries';

import { RemoteDataTable } from 'components/Tables';

import { tenderColumns } from './columns';
import { BaseCard } from 'components/Cards';
import { cardFields } from './cardFields';

export const Tender = () => {
	const { uid } = useParams();
	const {
		data: TenderDetails,
		isLoading,
		isFetching,
	} = useTender({ params: { uid } });

	const tenderQueryParams = useMemo(
		() => ({
			tender: uid,
		}),
		[uid]
	);

	const {
		data: tenderData,
		state: tenderState,
		setPagination: tenderSetPagination,
		rowCount: tenderRowCount,
		setSorting: tenderSorting,
		pageCount: tenderPageCount,
		setPageCount: tenderSetPageCount,
	} = useQueryPagination(useTenderLots, null, null, tenderQueryParams);

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<BaseCard
					loading={isLoading || isFetching}
					cardTitle={TenderDetails?.title}
					dataItem={TenderDetails}
					cardFields={cardFields}
				/>
			</Grid>
			<Grid item xs={12}>
				<Paper sx={{ borderRadius: '1rem' }} elevation={0}>
					<RemoteDataTable
						state={tenderState}
						data={tenderData}
						pageCount={tenderPageCount}
						onRowsPerPageChange={tenderSetPageCount}
						onPaginationChange={tenderSetPagination}
						rowCount={tenderRowCount}
						columns={tenderColumns}
						enableRowSelection={false}
						enablePagination
						manualSorting
						enableMultiSort={false}
						onSortingChange={tenderSorting}
						renderCustomActions={() => (
							<Typography fontWeight='600' p={1}>
								{`${TenderDetails?.title} - ${TenderDetails?.country?.name}`}
							</Typography>
						)}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};
