import { CustomInputTypeEnum } from 'models/enums/CustomInputTypeEnum';
import { getPackSizes, getPresentations, getStrengths } from 'services';

export const searchFilters = [
	{
		type: CustomInputTypeEnum.COUNTRY_AUTOCOMPLETE_STATIC,
		gridWrapper: 3,
		name: 'countries',
		label: 'Country',
		placeholder: 'Search country',
	},
	{
		type: CustomInputTypeEnum.AUTOCOMPLETE_REMOTE,
		gridWrapper: 3,
		name: 'presentation',
		label: 'Presentation',
		placeholder: 'Search presentations',
		dataService: getPresentations,
	},
	{
		type: CustomInputTypeEnum.AUTOCOMPLETE_REMOTE,
		gridWrapper: 3,
		name: 'pack_size',
		label: 'Pack Size',
		placeholder: 'Search pack sizes',
		dataService: getPackSizes,
	},
	{
		type: CustomInputTypeEnum.AUTOCOMPLETE_REMOTE,
		gridWrapper: 3,
		name: 'strength',
		label: 'Strength',
		placeholder: 'Search strengths',
		dataService: getStrengths,
	},
];
