import React from 'react';
import { TableCustomCellEnum } from 'models/enums';
import { LinkArrowIcon } from 'components/Icons';

export const columns = [
	{
		header: 'Tender name',
		accessorKey: 'title',
		accessorFn: (row) => (row?.title ? row.title : '-'),
		// minSize: 100,
		// enableSorting: false,
		cellType: TableCustomCellEnum.LINK,
		linkBase: 'tenders/',
		linkField: 'id',
		size: 100,
		align: 'right',
		maxSize: 180,
	},
	{
		header: 'Country',
		accessorKey: 'country__name',
		accessorFn: (row) => (row?.country?.name ? row.country.name : '-'),
		cellType: TableCustomCellEnum.DATA,
		enableSorting: false,
		// size: 75,
		// maxSize: 75,
	},
	{
		header: 'Publication date',
		accessorKey: 'publication_date',
		accessorFn: (row) => (row?.publication_date ? row.publication_date : '-'),
		cellType: TableCustomCellEnum.DATA,
		// size: 150,
	},
	{
		header: 'Closing date',
		accessorKey: 'closing_date',
		accessorFn: (row) => (row?.closing_date ? row.closing_date : '-'),
		cellType: TableCustomCellEnum.DATA,
		// size: 150,
		// enableSorting: false,
	},
	{
		header: 'Link',
		accessorFn: (row) => {
			return row?.link && (
				<a href={row.link} target='_blank' rel='noreferrer noopener'>
					<LinkArrowIcon />
				</a>
			);
		},
		title: 'open',
		cellType: TableCustomCellEnum.DATA,
		alignHeader: 'center',
		aligned: 'center',
		enableSorting: false,
		minSize: '10%',
		component: 'div'
		// maxSize: 70,
	},
];
