import { TextField, styled } from '@mui/material';
import { MaterialUISizeEnum } from 'models/enums';
import { MEDIUM_BORDER_RADIUS, SMALL_VARIANT_FONT_SIZE, SMALL_VARIANT_INPUT_HEIGHT } from 'utils/consts/themeConsts';

export const TextFieldWrapper = styled(TextField)((props) => ({
	fieldset: {
		borderRadius: MEDIUM_BORDER_RADIUS,
	},
	'& .MuiInputBase-root': {
		borderRadius: MEDIUM_BORDER_RADIUS,
		fontSize: props.size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_FONT_SIZE : undefined,
		height: props.size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_INPUT_HEIGHT : undefined,
	},
	'& label[data-shrink=\'false\']': {
		fontWeight: 500,
		fontSize: props.size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_FONT_SIZE : undefined,
		color: '#cccccc',
	},
}));
