import React from 'react';
import { Grid, Paper, Typography, Container } from '@mui/material';
import { Logo } from 'components/shared/Logo/Logo';

export const Maintanance = () => (
	<Paper elevation={0}>
		<Container maxWidth='md'>
			<Grid
				container
				sx={{
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
				}}
			>
				<Logo />
				<Typography textAlign={'center'} fontSize={'1.2rem'} marginTop={'20px'}>
          Server maintenance underway. Back in 5 minutes. Thank you!
				</Typography>
			</Grid>
		</Container>
	</Paper>
);
