/* eslint-disable react/prop-types */
import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export const Loader = ({
	backgroundColor = 'rgba(255, 255, 255, 0.8)',
	color = '#cccccc',
}) => {
	return (
		<Backdrop
			sx={{
				backgroundColor,
				color,
				zIndex: (theme) => theme.zIndex.drawer + 1,
			}}
			open={true}
		>
			<CircularProgress color={'primary'} />
		</Backdrop>
	);
};
