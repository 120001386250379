import React from 'react';
import video from 'assets/videos/ATCExplorer.mp4';
import { VideoPageLayout } from '../shared/VideoPageLayout';

export const ATCExplorer = () => (
	<VideoPageLayout
		title="Explore Exclusive Insights"
		video={video}
		text="Watch this video to discover the premium features and valuable information awaiting you on this ATC explorer page with a paid subscription."
		buttonText='Let&apos;s discuss further'
		buttonLink='https://calendly.com/phil-pharmafootpath/30min'
	/>
);