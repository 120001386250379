import { QueryKeysEnum } from 'models/enums';
import { useQuery } from 'react-query';
import { getParallelTradeLicenses } from 'services/ParallelTradeServices';

export const useParallelTradeLicenses = ({ queryParams }, enabled) => {
	const {
		product_registration,
		product_registration__pack__presentation__in,
		product_registration__pack__pack_size__in,
		product_registration__pack__active_ingredients__strength__in,
		destination_countries__id__in,
		origin_countries__id__in,
		exclude_eu_licenses,
		status__in,
		ordering,
		page,
		page_size,
		search,
	} = queryParams;
	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[
			QueryKeysEnum.PARALLEL_LICENSES,
			product_registration,
			product_registration__pack__presentation__in,
			product_registration__pack__pack_size__in,
			product_registration__pack__active_ingredients__strength__in,
			destination_countries__id__in,
			origin_countries__id__in,
			exclude_eu_licenses,
			status__in,
			ordering,
			page,
			page_size,
			search,
		],
		() => getParallelTradeLicenses({ queryParams }),
		{ enabled }
	);
	return { data, error, isLoading, isFetching, isFetched };
};
