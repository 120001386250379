export enum QueryKeysEnum {
  // PRODUCT
  PRODUCTS = 'prodcuts',
  PRODUCT_PRESENTATIONS = 'product_presentations',
  PRODUCT_PRESENTATION = 'product_presentation',
  PRODUCT_REGISTRATIONS = 'product_registrations',
  PRODUCT_TENDERS = 'product_tenders',
  PRODUCT_PRICES = 'product_prices',
  PRODUCT_NOTES = 'product_notes',
  // ACTIVE INGREDIENT
  ACTIVE_INGREDIENT = 'active_ingredient',
  ACTIVE_INGREDIENTS = 'active_ingredients',
  ACTIVE_INGREDIENT_NOTES = 'active_ingredient_notes',
  // TRIALS
  CLINICAL_TRIALS = 'clinical_trials',
  // PARALLEL LICENSES
  PARALLEL_LICENSES = 'parallel_licenses',
  // COMPANY
  COMPANY = 'company',
  COMPANIES = 'companies',
  COMPANY_USERS = 'company_users',
  // NOTES
  NOTES = 'notes',
  // ATC CODES
  ATC_CODES = 'atc_codes',
  ATC_CODE = 'atc_code',
  // SHORTAGES
  SHORTAGES = 'shortages',
  // TENDERS
  TENDERS = 'tenders',
  TENDER = 'tender',
  TENDER_LOTS = 'tender_lots',
  // WDA LICENSES
  WDA_LICENSES = 'wda_licenses',
  WDA_COMPANY = 'wda_company',
  MY_WDA_COMPANY = 'my_wda_company',
}
