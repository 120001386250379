import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { COLORS } from 'utils/consts';
import { BaseAppBar } from 'components/Navigation';
import { AdminHeader } from 'components/Header/AdminHeader';

export const AdministratorLayout = ({ children }: { children: ReactNode }) => {
	return (
		<Box sx={{ minHeight: '100vh', backgroundColor: COLORS.background }}>
			<BaseAppBar renderNavItems={false} />
			<Box sx={{ px: 6 }} component='main' pb={2}>
				<AdminHeader />
				{children}
			</Box>
		</Box>
	);
};
