import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Formik } from 'formik';

import { BaseButton } from 'components/Buttons';
import { BaseInput } from 'components/Inputs';
import { updateSubuserSchema } from 'forms/updateSubuser';

// eslint-disable-next-line react/prop-types
export const UpdateUserModalContent = ({ onSubmit, onCancel, userForEdit }) => {
	return (
		<Box>
			<Formik
				initialValues={userForEdit as { first_name: string; last_name: string; email: string }}
				validationSchema={updateSubuserSchema}
				onSubmit={onSubmit}
			>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={4}>
							<Grid item xs={12}>
								<Typography my={1}>
                  Update details for users you already have allocated to licenses. If you change the email, you will need to
                  inform them of the change.
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<BaseInput
									fullWidth
									name="first_name"
									label="First Name"
									value={values.first_name}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.first_name && Boolean(errors.first_name)}
									helperText={touched.first_name ? errors.first_name : ''}
								/>
							</Grid>
							<Grid item xs={12}>
								<BaseInput
									fullWidth
									name="last_name"
									label="Last Name"
									value={values.last_name}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.last_name && Boolean(errors.last_name)}
									helperText={touched.last_name ? errors.last_name : ''}
								/>
							</Grid>
							<Grid item xs={12}>
								<BaseInput
									fullWidth
									name="email"
									label="Enter Email"
									value={values.email}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.email && Boolean(errors.email)}
									helperText={touched.email ? errors.email : ''}
								/>
							</Grid>
							<Grid container spacing={2} item xs={12}>
								<Grid item xs={6}>
									<Box mb={2}>
										<BaseButton type="button" onClick={onCancel} variant={'outlined'} label={'Cancel'} />
									</Box>
								</Grid>
								<Grid item xs={6}>
									<Box mb={2}>
										<BaseButton label={'Confirm'} />
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};
