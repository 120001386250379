import AuthClient from './Clients/AuthClient';
import { GetPricesParamsInterface } from 'models/interfaces';
import { generateQuery } from 'utils';

const pricesServicesUrl = '/v1/prices/';

export const getPrices = async ({ queryParams }: { queryParams?: GetPricesParamsInterface }) => {
	const pricesResponse = await AuthClient.get(pricesServicesUrl + generateQuery(queryParams));
	return pricesResponse.data;
};

export const getPricesForProduct = async ({ params, queryParams }: { params: any; queryParams?: GetPricesParamsInterface }) => {
	const pricesResponse = await AuthClient.get(`${pricesServicesUrl}${params.uid}/${generateQuery(queryParams)}`);
	return pricesResponse.data;
};

const pricesServicesUrlV2 = '/v2/products/';

export const getPricesForProductV2 = async ({
	params,
	queryParams,
}: {
  params?: any;
  queryParams?: GetPricesParamsInterface;
}) => {
	const pricesResponse = await AuthClient.get(`${pricesServicesUrlV2}${params.product_id}/prices/${generateQuery(queryParams)}`);
	return pricesResponse.data;
};
