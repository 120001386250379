import React from 'react';
import PharmaLogo from 'assets/images/logo.svg';

export const Logo = () => (
	<img
		style={{ maxWidth: '100%', maxHeight: '100%' }}
		alt="Logo"
		src={PharmaLogo}
	/>
);
