/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';

import { getNestedValue } from 'utils';
import { TypographyVariantType } from 'models/types';
import { COMMON_DATE_FORMAT } from 'utils/consts';

export const DateItem = ({
	dataItem,
	item,
	color = 'inherit',
	fontWeight = 400,
	variant = 'body1',
	...props
}) => {
	const value = getNestedValue(dataItem, item.fieldPath);
	if (!value) return <>-</>;
	return (
		<Typography
			color={color}
			fontWeight={fontWeight}
			variant={variant as TypographyVariantType}
		>
			{moment(value).format(item.format || COMMON_DATE_FORMAT)}
		</Typography>
	);
};
