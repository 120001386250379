import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
	headers: {
		'content-type': 'application/json',
	},
});

instance.interceptors.response.use(
	(res) => res,
	(error) => {
		if (error.message.includes('code 401')) {
			// localStorage.clear();
			// sessionStorage.clear();
		}

		return Promise.reject(error.response);
	}
);

export default instance;
