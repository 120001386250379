import React, { ReactNode } from 'react';
import ReactPlayer from 'react-player';
import { Box, Paper, Typography } from '@mui/material';
import { StyledBtnLink } from 'components/StyledComponents';
import { COLORS } from 'utils/consts';
import { To } from 'react-router-dom';

type LayoutProps = {
    title?: string, 
    text?: string, 
    video?: string, 
    buttonText?: string,
    buttonLink: To,
    children?: ReactNode
}


export const VideoPageLayout = ({title, text, video, buttonText, buttonLink, children} : LayoutProps) => {
	return (
		<Paper
			elevation={0}
			sx={{ 
				borderRadius: '10px', 
				padding: '20px 20px 60px',
				minHeight: 'calc(100vh - 250px)',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				textAlign: 'center',
				flexWrap: 'wrap'
			}}
		>
			<Box 
				maxWidth={'520px'} 
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: '20px',
					padding: '70px 0 0',
				}}
			>
				<Typography variant='subtitle2' component='h2' fontSize={20} marginBottom={'10px'}>
					{title}
				</Typography>
				<Typography>
					{text}
				</Typography>
				{children}
				<StyledBtnLink
					to={buttonLink}
					target='_blank'
					color={COLORS.blue}
					style={{
						textTransform: 'uppercase', 
						fontSize: '14px',
						maxWidth: '290px',
					}}
				>
					{buttonText}
				</StyledBtnLink>
			</Box>
			{video && (
				<Box sx={{
					width: '100%',
					maxWidth: '1000px',
					marginTop: '30px'
				}}>
					<ReactPlayer
						width={'100%'}
						height={'100%'}
						url={video}
						controls
					/>
				</Box>
			)}
		</Paper>
	);
};
