import React, { FC, Fragment } from 'react';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	Skeleton,
	Typography,
} from '@mui/material';

import { CustomCardItem } from '../shared';

interface BaseCardPropsInterface {
  dataItem?: any;
  cardFields?: any[];
  cardTitle?: string;
  loading: boolean;
}

export const BaseCard: FC<BaseCardPropsInterface> = ({
	dataItem,
	cardTitle,
	loading,
	cardFields,
}) => {
	return (
		<Card elevation={0}>
			{cardTitle && (
				<Fragment>
					<CardHeader title={cardTitle} />
					<Box mx={2}>
						<Divider />
					</Box>
				</Fragment>
			)}
			<CardContent>
				<Box m={2}>
					<Grid container columnSpacing={2}>
						{cardFields && cardFields.map((field, index) => (
							<Grid
								item
								xs={12}
								sm={6}
								md={field.col || 6}
								key={index}
								my={1}
							>
								<Box display={'flex'}>
									<Box mr={2}>
										<Typography
											whiteSpace={'nowrap'}
											color={'inherit'}
											fontWeight={600}
											variant={'body1'}
										>
											{field.key}
										</Typography>
									</Box>

									{!loading ? (
										<Box sx={{ flex: 'unset', flexShrink: 1 }}>
											{dataItem && (
												<CustomCardItem dataItem={dataItem} item={field} />
											)}
										</Box>
									) : (
										<Skeleton
											variant='text'
											animation='wave'
											sx={{ fontSize: '1rem', flexGrow: 1 }}
										/>
									)}
								</Box>
							</Grid>
						))}
					</Grid>
				</Box>
			</CardContent>
		</Card>
	);
};
