import AuthClient from './Clients/AuthClient';
import { GetNotesParamsInterface } from 'models/interfaces';
import { generateQuery } from 'utils';

const notesServicesUrl = '/v1/notes/';

export const getNotes = async ({ queryParams }: { queryParams?: GetNotesParamsInterface }) => {
	const notesResponse = await AuthClient.get(notesServicesUrl + generateQuery(queryParams));
	return {
		...notesResponse.data,
		results: notesResponse.data.results.map((item) => ({
			...item,
			user_name: `${item.user.first_name} ${item.user.last_name}`,
			user_avatar: item.user.avatar,
		})),
	};
};

export const getNote = async ({ params, queryParams }: { params: any; queryParams?: GetNotesParamsInterface }) => {
	const notesResponse = await AuthClient.get(`${notesServicesUrl}${params.uid}/${generateQuery(queryParams)}`);
	return notesResponse.data;
};

export const deleteNote = async ({ params }: { params: any }) => {
	const notesResponse = await AuthClient.delete(`${notesServicesUrl}${params.uid}/`);
	return notesResponse.data;
};
