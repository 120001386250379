import React, { useState, useCallback } from 'react';
import { NotificationsContext } from './NotificationsContext';
import { INotification } from 'models/interfaces';
import { NotificationsTypeEnum } from '../../models/enums';

export const NotificationsContextProvider = (props: any) => {
	const [notifications, setNotifications] = useState<INotification[]>([]);

	const fadeNotification = useCallback((id) => {
		setNotifications((prevNotifications) =>
			prevNotifications.map((notification) => (notification.id === id ? { ...notification, show: false } : notification))
		);
	}, []);

	const removeNotification = useCallback((id) => {
		setNotifications((prevNotifications) => prevNotifications.filter((notification) => notification.id !== id));
	}, []);

	const addNotification = useCallback(
		(notification) => {
			const id = Math.random() * 1000;
			setNotifications((prevNotifications) => [...prevNotifications, { ...notification, id, show: true }]);
			if (notification.type.toLowerCase() !== NotificationsTypeEnum.ERROR) {
				setTimeout(() => fadeNotification(id), 10000);
			}
		},
		[fadeNotification]
	);

	return (
		<NotificationsContext.Provider value={{ notifications, addNotification, fadeNotification, removeNotification }}>
			{props.children}
		</NotificationsContext.Provider>
	);
};
