import React, { FC } from 'react';
import { Box } from '@mui/material';

import { BaseIconButton } from 'components/Buttons';

interface IconCellInterface {
  column: any;
}

export const IconCell: FC<IconCellInterface> = ({ column, ...props }) => {
	return (
		<Box display={'flex'}>
			<Box ml='auto'>
				<BaseIconButton>{column.icon}</BaseIconButton>
			</Box>
		</Box>
	);
};
