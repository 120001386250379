import { styled, Box, Grid, Typography, Skeleton } from '@mui/material';
import React, { Fragment } from 'react';

import { COLORS } from 'utils/consts';
import { MEDIUM_BORDER_RADIUS } from 'utils/consts/themeConsts';

const CardWrapper = styled(Box)(() => ({
	padding: '15px',
	paddingLeft: '30px',
	paddingRight: '30px',
	background: COLORS.white,
	borderRadius: MEDIUM_BORDER_RADIUS,
}));

const StyledNumber = styled(Typography)(() => ({
	fontSize: '50px',
	fontWeight: '500',
	textAlign: 'center',
	borderRight: `1px solid ${COLORS.mediumLightGray}`,
	lineHeight: '.75em',
	padding: '0 15px',
	margin: '15px 0',
}));
const StyledLabel = styled(Typography)(() => ({
	fontSize: '14px',
	fontWeight: '500',
	lineHeight: '1.5em',
}));

export const InfoCard = ({
	number,
	color,
	label,
	labelProps,
	children,
	loading = false,
	...props
}: any) => (
	<CardWrapper {...props}>
		<Grid container alignItems={'center'} columnSpacing={2}>
			<Grid item>
				{loading ? (
					<Skeleton
						animation='wave'
						variant='circular'
						width={65}
						height={65}
					/>
				) : (
					<StyledNumber color={color}>{number}</StyledNumber>
				)}
			</Grid>
			<Grid item>
				{loading ? (
					<Fragment>
						<Skeleton
							animation='wave'
							height={10}
							width='100px'
							style={{ marginBottom: 6 }}
						/>
						<Skeleton
							animation='wave'
							height={10}
							width='60px'
							style={{ marginBottom: 6 }}
						/>
					</Fragment>
				) : (
					<StyledLabel {...labelProps}>{label}</StyledLabel>
				)}
			</Grid>
		</Grid>
		{children}
	</CardWrapper>
);
