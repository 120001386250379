import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';

import App from 'App';
import reportWebVitals from 'reportWebVitals';
import 'index.css';
import { AppDataContextProvider, UserContextProvider, NotificationsContextProvider } from 'context';

const queryClient = new QueryClient({
	defaultOptions: { queries: { refetchOnWindowFocus: false, staleTime: 30_000, retry: 0, cacheTime: 1000 * 60 * 60 * 24 } },
});

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
	// <React.StrictMode>
	<QueryClientProvider client={queryClient}>
		<AppDataContextProvider>
			<UserContextProvider>
				<NotificationsContextProvider>
					<App />
				</NotificationsContextProvider>
			</UserContextProvider>
		</AppDataContextProvider>
	</QueryClientProvider>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
