export enum ApplicationRoutesEnum {
  HOME = '/',

  // common user
  EDIT_PROFILE = '/edit-profile',
  PRODUCT_EXPLORER = '/product-explorer',
  PRODUCT_ID = '/product-explorer/:uid',
  INGREDIENT_ID = '/ingredients/:uid',
  COMPANY_EXPLORER = '/company-explorer',
  COMPANY_ID = '/company-explorer/:uid',
  PARALLEL_TRADE = '/parallel-trade-explorer',
  ATC_CODE_EXPLORER = '/atc-code-explorer',
  ATC_CODE_ID = '/atc-code-explorer/:uid',
  SHORTAGES = '/shortages',
  TENDER_EXPLORER = '/tenders',
  TENDER_ID = '/tenders/:uid',
  WDA_LICENSES = '/eu-wda-licenses',
  WDA_COMPANY = '/eu-wda-licenses/company/:uid',

  // admin
  COMPANY_PROFILE = '/company-profile',
  EDIT_COMPANY_PROFILE = '/company-profile/edit',

  // auth
  SIGNIN = '/signin',
  REGISTER = '/register',
  FORGOT_PASSWORD = '/forgot-password',
  CHANGE_PASSWORD = '/reset-password/:uid/:token',
  ACTIVATE_ACCOUNT = '/activate/:uid/:token',
  RESEND_ACTIVATION = '/resend-activation',

  ALL = '*',
}
