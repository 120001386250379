export function getNestedValue(dataItem, path) {
	const dataValue = path.reduce((acc, value) => {
		if (acc && acc[value]) {
			acc = acc[value];
		} else {
			acc = null;
		}
		return acc;
	}, dataItem);
	if (!dataValue) {
		return null;
	}
	return dataValue;
}
