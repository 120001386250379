/* eslint-disable react/prop-types */
import React from 'react';
import ReactDOM from 'react-dom';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { AppPortalElementsEnum } from 'models/enums';

export const PageSpinner = ({ backgroundColor = '#FFFFFF', color = '#cccccc' }) => {
	const content = (
		<Backdrop sx={{ backgroundColor, color, zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
			<CircularProgress color={'inherit'} />
		</Backdrop>
	);
	const loaderElement = document.getElementById(AppPortalElementsEnum.LOADER);
	return loaderElement ? ReactDOM.createPortal(content, loaderElement) : content;
};
