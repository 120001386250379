import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

// import { SideDrawer } from "components/Navigation/SideDrawer/SideDrawer";
import { Header } from 'components/Header/Header';
import { COLORS } from 'utils/consts';
import { BaseAppBar } from 'components/Navigation';

export const AppLayout = ({ children }: { children: ReactNode }) => {
	return (
	// <SideDrawer>
		<Box sx={{ minHeight: '100vh', backgroundColor: COLORS.background }}>
			<BaseAppBar />
			<Box sx={{ px: 6 }} component='main' pb={2}>
				<Header />
				{children}
			</Box>
		</Box>
	// </SideDrawer>
	);
};
