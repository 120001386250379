
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { dashboardStatLabels } from 'models/enums';
import { DashboardStats } from 'models/types';

import { getDashboardStats } from 'services';

export function useDashboardStats() {
	const [stats, setStats] = useState<DashboardStats[] | []>([
		{
			key: 'parallel_trade_count',
			label: dashboardStatLabels.PARALLEL_TRADE,
			max_width: '120px',
		},
		{
			key: 'tenders_count',
			label: dashboardStatLabels.TENDERS,
			max_width: '140px',
		},
		{
			key: 'clinical_trials_count',
			label: dashboardStatLabels.CLINICAL_TRIALS,
			max_width: '100px',
		},
	]);
	const [loading, setLoading] = useState<boolean>(true);

	const dates = useMemo(
		() => ({
			start: moment().subtract(7, 'days').startOf('isoWeek'),
			end: moment().subtract(7, 'days').endOf('isoWeek'),
		}),
		[]
	);

	useEffect(() => {
		getDashboardStats({
			queryParams: {
				created_from: dates.start.format('MM/DD/YYYY'),
				created_before: dates.end.format('MM/DD/YYYY'),
			},
		})
			.then((res)=>{
				setStats((prev)=>prev.map(item=>({
					...item,
					number: res[item['key']]
				})));
			})
			.catch((err)=> console.log(err))
			.finally(()=>{
				setLoading(false);
			});
	}, [dates.start, dates.end]);

	return {
		stats,
		dates,
		loading
	};
}
