import AuthClient from './Clients/AuthClient';
import { GetCompaniesParamsInterface, GetDashboardStatsParamsInterface } from 'models/interfaces';
import { generateQuery } from 'utils';

const countresServicesUrl = '/v1/countries/';

export const getCountries = async (
	queryParams?: GetCompaniesParamsInterface
) => {
	const counriesResponse = await AuthClient.get(
		countresServicesUrl + generateQuery(queryParams)
	);
	return counriesResponse.data;
};

const presentationsServicesUrl = '/v2/presentations/';

export const getPresentations = async (queryParams?: { search?: string }) => {
	const counriesResponse = await AuthClient.get(
		presentationsServicesUrl + generateQuery(queryParams)
	);
	return counriesResponse.data;
};

const packSizesServicesUrl = '/v2/pack-sizes/';

export const getPackSizes = async (queryParams?: { search?: string }) => {
	const counriesResponse = await AuthClient.get(
		packSizesServicesUrl + generateQuery(queryParams)
	);
	return counriesResponse.data;
};

const strengthsServicesUrl = '/v2/strengths/';

export const getStrengths = async (queryParams?: { search?: string }) => {
	const counriesResponse = await AuthClient.get(
		strengthsServicesUrl + generateQuery(queryParams)
	);
	return counriesResponse.data;
};

const ingredientSuggestionsServiceUrl = '/v1/active-ingredients/';

export const getActiveIngredientsSuggestions = async (queryParams?: {
  search?: string;
}) => {
	const ingredientsResponse = await AuthClient.get(
		ingredientSuggestionsServiceUrl + generateQuery(queryParams)
	);
	return ingredientsResponse.data;
};

const WDAAuthorizationsServiceUrl = '/v2/wda_authorizations/';

export const getWDAAuthorizationsSuggestions = async (queryParams?: {
  search?: string;
}) => {
	const WDAResponse = await AuthClient.get(
		WDAAuthorizationsServiceUrl + generateQuery(queryParams)
	);
	return WDAResponse.data;
};

const ShortageReasonsServiceUrl = '/v2/shortage_reasons/';

export const getShortageReasonsSuggestions = async (queryParams?: {
  search?: string;
}) => {
	const reasonsResponse = await AuthClient.get(
		ShortageReasonsServiceUrl + generateQuery(queryParams)
	);
	return reasonsResponse.data;
};

const MAHolderServiceUrl = '/v2/ma_holders/';

export const getMAHolders = async (queryParams?: {
	search?: string;
  }) => {
	const MAHoldersResponse = await AuthClient.get(
		MAHolderServiceUrl + generateQuery(queryParams)
	);
	return MAHoldersResponse.data;
};

const dashboardStatsServicesUrl = '/v2/dashboard/';

export const getDashboardStats = async ({ queryParams }: { queryParams?: GetDashboardStatsParamsInterface }) => {
	const dashboardStatsResponse = await AuthClient.get(dashboardStatsServicesUrl + generateQuery(queryParams));
	return dashboardStatsResponse.data;
};