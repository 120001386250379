import AuthClient from './Clients/AuthClient';
import { GetShortcodeInterface } from 'models/interfaces';
import { generateQuery } from 'utils';

const shortagesServicesUrl = '/v2/shortages/';

export const getShortages = async ({
	queryParams,
}: {
  queryParams?: GetShortcodeInterface;
}) => {
	const productsResponse = await AuthClient.get(
		shortagesServicesUrl + generateQuery(queryParams)
	);
	return productsResponse.data;
};
