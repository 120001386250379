/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@mui/material';
import { TypographyVariantType } from 'models/types';
import { getNestedValue } from 'utils';
import { capitalizeString } from 'utils/capitalizeString';

export const TextItem = ({
	dataItem,
	item,
	color = 'inherit',
	fontWeight = 400,
	variant = 'body1',
	...props
}) => {
	if (item.value)
		return (
			<Typography
				color={color}
				fontWeight={fontWeight}
				variant={variant as TypographyVariantType}
			>
				{item.value}
			</Typography>
		);

	if (!dataItem) return null;

	return (
		<Typography
			color={color}
			fontWeight={fontWeight}
			variant={variant as TypographyVariantType}
		>
			{item.value ? (
				item.value
			) : (
				<>
					{item.capitalize
						? capitalizeString(getNestedValue(dataItem, item.fieldPath) || '-')
						: getNestedValue(dataItem, item.fieldPath) || '-'}{' '}
					{item.devider && item.devider}{' '}
					{item.additionalFieldPath
						? getNestedValue(dataItem, item.additionalFieldPath) || '-'
						: ''}
				</>
			)}
		</Typography>
	);
};
