/* eslint-disable react/prop-types */
import React from 'react';

export const ReactivateIcon = ({ stroke = '#2DAC00', ...props }) => (
	<svg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M2.81802 8.13401C4.55402 9.87002 7.36802 9.87002 9.10402 8.13401C10.84 6.39801 10.84 3.58335 9.10402 1.84801C7.36802 0.112681 4.55335 0.112015 2.81802 1.84801L0.554688 4.11135M0.554688 4.11135V0.899348M0.554688 4.11135H3.76669'
			stroke={stroke}
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
