import React, { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Tabs, Tab } from '@mui/material';

import { SignIn } from './SignIn/SignIn';
import { Register } from './Register/Register';
import { AUTHENTICATE_TABS } from 'utils/consts';
import { ApplicationRoutesEnum } from 'models/enums';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel({ children, value, index, ...props }: TabPanelProps) {
	return (
		<Box
			sx={{
				maxWidth: { xs: '100%', md: '60%' },
			}}
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...props}
		>
			{value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
		</Box>
	);
}

export const Authenticate: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const [tabValue, setTabValue] = React.useState(0);
	useEffect(() => {
		if (location.pathname === ApplicationRoutesEnum.SIGNIN) {
			setTabValue(0);
		} else if (location.pathname === ApplicationRoutesEnum.REGISTER) {
			setTabValue(1);
		}
	}, [location.pathname]);

	const tabChangeHandler = (event: React.SyntheticEvent, tabValue: number) => navigate(AUTHENTICATE_TABS[tabValue]);

	return (
		<Box px={4} py={8} display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={1}>
			<Box
				sx={{
					width: { xs: '100%', md: '60%' },
					borderBottom: 1,
					borderColor: 'divider',
				}}
			>
				<Tabs
					sx={{
						width: '100%',
						'& .MuiTabs-indicator': {
							height: '3px',
						},
						fontSize: 15,
						fontWeight: '500',
					}}
					value={tabValue}
					onChange={tabChangeHandler}
					aria-label="Auth tabs"
					centered
				>
					<Tab sx={{ width: '50%' }} label="Sign In" />
					<Tab sx={{ width: '50%' }} label="Register" />
				</Tabs>
			</Box>
			<TabPanel value={tabValue} index={0}>
				<SignIn />
			</TabPanel>
			<TabPanel value={tabValue} index={1}>
				<Register />
			</TabPanel>
		</Box>
	);
};
