import { CustomInputTypeEnum } from 'models/enums/CustomInputTypeEnum';
import { getWDAAuthorizationsSuggestions } from 'services';

export const searchFilters = [
	{
		type: CustomInputTypeEnum.TEXT_SEARCH,
		gridWrapper: 2.5,
		name: 'search',
		label: 'Company name',
		placeholder: 'Search company name'
	},
	{
		type: CustomInputTypeEnum.AUTOCOMPLETE_REMOTE,
		gridWrapper: 2.5,
		name: 'license_conditions',
		label: 'License Conditions',
		placeholder: 'Select License Conditions',
		dataService: getWDAAuthorizationsSuggestions,
	},
	{
		type: CustomInputTypeEnum.COUNTRY_AUTOCOMPLETE_STATIC,
		gridWrapper: 2.5,
		name: 'countries',
		label: 'Country',
		placeholder: 'Search country',
	},
	{
		type: CustomInputTypeEnum.SWITCH,
		gridWrapper: 4,
		name: 'only_from_database',
		label: 'Only show companies with profiles',
	},
];
