import { QueryKeysEnum } from 'models/enums';
import { useQuery } from 'react-query';
import { getTenders, getTender, getTenderLots } from 'services';

export const useTender = ({ params }) => {
	const { uid } = params;

	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[QueryKeysEnum.TENDER, uid],
		() => getTender({ params }),
		{ enabled: !!uid }
	);
	return { data, error, isLoading, isFetching, isFetched };
};

export const useTenders = ({ queryParams }, enabled) => {
	const {
		product,
		active_ingredient,
		country__in,
		show_closed,
		ordering,
		page,
		page_size,
		search,
	} = queryParams;
	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[
			QueryKeysEnum.TENDERS,
			product,
			active_ingredient,
			country__in,
			show_closed,
			ordering,
			page,
			page_size,
			search,
		],
		() => getTenders({ queryParams }),
		{ enabled }
	);
	return { data, error, isLoading, isFetching, isFetched };
};

export const useTenderLots = ({ queryParams }, enabled) => {
	const { tender, ordering, page, page_size, search } = queryParams;
	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[QueryKeysEnum.TENDER_LOTS, tender, ordering, page, page_size, search],
		() => getTenderLots({ queryParams }),
		{ enabled }
	);
	return { data, error, isLoading, isFetching, isFetched };
};
