import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { COLORS } from 'utils/consts';

export const BackButton = () => {
	const navigate = useNavigate();

	return (
		<Button onClick={() => navigate(-1)} sx={{ color: COLORS.black }}>
			<ArrowBackIcon />
		</Button>
	);
};