/* eslint-disable react/prop-types */
import React from 'react';

export const ArrowBottomIcon = ({ stroke = '#0494F1', ...props }) => (
	<svg
		width='10'
		height='7'
		viewBox='0 0 10 7'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M1.175 0L5 3.825L8.825 0L10 1.18333L5 6.18333L0 1.18333L1.175 0Z'
			fill={stroke}
		/>
	</svg>
);
