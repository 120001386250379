import React, { FC, ReactNode, useState } from 'react';
import { Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { MatFormLabel, TextFieldWrapper } from '../';
import { MaterialUIInputSizeType } from 'models/types';

interface BaseInputInterface {
  onChange?: (x: any) => void;
  onBlur?: (x: any) => void;
  value?: any;
  name: string;
  label: string;
  labelInfo?: ReactNode;
  placeholder?: string;
  autoComplete?: string;
  variant?: 'outlined' | 'standard' | 'filled';
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  inputProps?: any;
  size?: MaterialUIInputSizeType;
}

export const BasePasswordInput: FC<BaseInputInterface> = ({
	onChange,
	onBlur,
	value,
	autoComplete,
	variant = 'outlined',
	fullWidth,
	label,
	labelInfo,
	name,
	placeholder,
	error,
	helperText,
	inputProps,
	size = 'small',
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const handlePasswordVisibility = () => setShowPassword(!showPassword);
	return (
		<Box>
			<MatFormLabel id={name}>
				{label}
				{labelInfo && <Box sx={{ ml: 'auto' }}>{labelInfo}</Box>}
			</MatFormLabel>
			<TextFieldWrapper
				type={showPassword ? 'text' : 'password'}
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							<IconButton onClick={handlePasswordVisibility} edge='end'>
								{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
							</IconButton>
						</InputAdornment>
					),
					...inputProps,
				}}
				onBlur={onBlur}
				onChange={onChange}
				value={value}
				variant={variant}
				fullWidth={fullWidth}
				id={name}
				name={name}
				placeholder={placeholder || label}
				autoComplete={autoComplete || name}
				error={error}
				helperText={helperText}
				size={size}
			/>
		</Box>
	);
};
