// @ts-nocheck
import { ChartCountryPallete } from './consts/colors';

export function chartData(data) {
	let temp = { x: null, y: null };
	const uniqueFields = [...new Set(data.map((val) => val.date))].sort(
		(a, b) => new Date(a) - new Date(b)
	);
	return Object.entries(
		data.reduce((acc, val) => {
			const countryName =
        val.country.name === 'Czech Republic' ? 'Czechia' : val.country.name;
			if (acc[countryName]) {
				acc[countryName].push(val);
			} else {
				acc[countryName] = [val];
			}
			return acc;
		}, {})
	).map(([key, value]) => {
		return {
			id: key,
			color: ChartCountryPallete[key],
			data: uniqueFields.map(val => {
				const dataItem = value.find((item) => item.date === val);
				if (dataItem) {
					const availablePrice = [
						dataItem.ex_factory_price,
						dataItem.retail_price,
						dataItem.wholesale_price,
					].filter(Boolean);

					// NUMBER_TWO_DECIMAL_FORMAT.format();
					const averagePrice = availablePrice.reduce((acc, val) => {
						acc += parseFloat(val);
						return acc;
					}, 0) / availablePrice.length;
					temp = {
						x: val,
						y: averagePrice,
						...dataItem,
					};
					return { ...temp };
				} else {
					if (temp && temp.country && temp.country.name === key) {
						return { ...temp, x: val };
					} else {
						return { x: val, y: null };
					}
				}
			}),
		};
	});
}
