
import { useCallback, useEffect, useState } from 'react';

export const useTextSearch = (initialSearchValue: string) => {
	const [searchValue, setSearchValue] = useState<string>(initialSearchValue);

	useEffect(()=>{
		setSearchValue(initialSearchValue);
	},[initialSearchValue]);

    
	const onSearchChangeHandler = useCallback((e)=>{
		setSearchValue(e.target.value);
	},[setSearchValue]);

	return {
		searchValue,
		setSearchValue,
		onSearchChangeHandler
	};
};
