/* eslint-disable react/prop-types */
// @ts-nocheck
import React, { useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import { ResponsiveLine } from '@nivo/line';
import { useOrdinalColorScale } from '@nivo/colors';
import { PricesHistoryTooltip } from '../ChartTooltips';
import { abbreviateNumber } from 'utils';
import { CircleOutline } from 'components/Icons';

export const PricesHistoryChart = ({ data, displayInputs, isShort, start_from }) => {
	const [hiddenIds, setHiddenIds] = useState<string[]>([]);

	const parseDate = (dateString) => {
		const date = new Date(dateString);
		return isNaN(date.getTime()) ? null : date;
	};

	const getEarliestDate = (data) => {
		let earliestDate = null;

		data.forEach((countryData) => {
			countryData.data.forEach((val) => {
				const dateField = val.x || val.date;
				const parsedDate = parseDate(dateField);
				if (parsedDate && (earliestDate === null || parsedDate < earliestDate)) {
					earliestDate = parsedDate;
				}
			});
		});

		return earliestDate ? moment(earliestDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
	};

	const chartData = useMemo(() => {
		return data
			.filter((item) => !hiddenIds.includes(item.id))
			.map((countryData) => {
				// Sort the data by date to ensure correct order
				const sortedData = countryData.data.sort((a, b) => parseDate(a.x) - parseDate(b.x));

				// Create a complete list of months within the range
				const startDate = moment(start_from.id === null ? getEarliestDate(data) : start_from.id);
				const endDate = moment();
				const allMonths = [];

				const currentMonth = startDate.clone();
				while (currentMonth.isSameOrBefore(endDate, 'month')) {
					allMonths.push(currentMonth.format('YYYY-MM'));
					currentMonth.add(1, 'month');
				}

				// Create a new array to hold the filled data
				const filledData = [];
				let lastKnownValue = null;

				// Map all months to ensure every month has a data point on the 1st
				allMonths.forEach((month) => {
					const firstOfMonth = month + '-01';
					const existingDataPoint = sortedData.find((dataPoint) =>
						moment(dataPoint.x).isSame(moment(firstOfMonth), 'day')
					);
					if (!existingDataPoint && lastKnownValue !== null) {
						filledData.push({
							x: firstOfMonth,
							y: lastKnownValue,
							color: countryData?.color,
							currency: countryData?.data?.find(e => e.currency)?.currency || ' '

						});
					}

					sortedData.forEach((dataPoint) => {
						const dataPointDate = moment(dataPoint.x);
						if (dataPointDate.isSame(moment(month, 'YYYY-MM'), 'month')) {
							filledData.push(dataPoint);
							lastKnownValue = dataPoint.y;
						}
					});
				});

				return {
					...countryData,
					data: filledData,
				};
			});
	}, [hiddenIds, data, start_from]);

	const colors = useOrdinalColorScale({ scheme: 'category10' }, 'id');

	const colorMap = useMemo(() => {
		const map = {};
		data.forEach((item) => {
			map[item.id] = item.color;
		});
		return map;
	}, [data, colors]);

	const dateFormat = useCallback(
		(date) => {
			return isShort ? moment(date).format('MMMM D') : moment(date).format('Y MMM D');
		},
		[isShort]
	);

	const calculateTickValues = (startDateStr, numTicks) => {
		const startDate = moment(startDateStr || getEarliestDate(data), 'YYYY-MM-DD');
		const endDate = moment();
		const totalMonths = endDate.diff(startDate, 'months') + 1;
		const interval = Math.max(1, Math.floor(totalMonths / numTicks));

		const tickDates = [];
		const currentDate = startDate;
		while (currentDate <= endDate) {
			tickDates.push(currentDate.toDate());
			currentDate.add(interval, 'months');
		}

		return tickDates;
	};

	const tickValues = calculateTickValues(start_from.id, 12);

	return (
		<ResponsiveLine
			colors={(d) => d.color || colors(d)}
			sx={{ position: 'relative' }}
			tooltip={(props) => {
				return (
					<PricesHistoryTooltip
						{...props}
						displayInputs={displayInputs}
						data={chartData}
						date={dateFormat(props.point.data.x)}
						startDate={moment(start_from.id === null ? getEarliestDate(data) : start_from.id)}
						endDate={moment()}
					/>
				);
			}}
			data={chartData.map((e) => ({
				...e,
				data: e.data.map((val) => ({
					...val,
					x: parseDate(val.x),
					compareDate: parseDate(val.x),
				})),
			}))}
			pointSize={(() => {
				const lengths = chartData.map((e) => e.data.length);
				let maxLength = 0;
				for (const len of lengths) {
					if (len > maxLength) {
						maxLength = len;
					}
				}
				return maxLength >= 100 ? 5 : 8;
			})()}
			margin={{ top: 50, right: 110, bottom: 100, left: 60 }}
			xScale={{
				type: 'time',
				format: '%Y-%m-%dT%H:%M:%S.%LZ',
				useUTC: false,
				precision: 'day',
			}}
			xFormat="time:%Y-%m"
			yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
			curve="stepAfter"
			lineWidth={2}
			layers={[
				'grid',
				'axes',
				'areas',
				'crosshair',
				'slices',
				'mesh',
				'legends',
				({ series, lineGenerator, xScale, yScale }) => {
					return series.map(({ id, data, color }, index) => (
						<path
							key={id}
							d={lineGenerator(
								data.map((d, i) => {
									let yCoordinate = d.data.y;
									if (!d.data.y && i > 0) {
										for (let j = i; j >= 0; j--) {
											const prevY = data[j].data.y;
											if (prevY) {
												yCoordinate = prevY;
												break;
											}
										}
									}
									return {
										x: d.data.x ? xScale(d.data.x) : null,
										y: yCoordinate ? yScale(yCoordinate) : null,
									};
								})
							)}
							fill="none"
							stroke={color}
							style={{
								strokeDasharray: '3, 6',
								strokeWidth: 1.5,
							}}
						/>
					));
				},
				'lines',
				'markers',
				'points',
			]}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickRotation: isShort ? -45 : -90,
				tickSize: 0,
				tickPadding: 20,
				tickValues: tickValues,
				format: (value) => moment(value).format('MMM YYYY'),
			}}
			axisLeft={{
				orient: 'left',
				legend: 'Average Price',
				legendOffset: -45,
				legendPosition: 'middle',
				format: function (value) {
					return abbreviateNumber(value);
				},
			}}
			pointColor={{ theme: 'background' }}
			pointBorderWidth={2}
			pointBorderColor={{ from: 'serieColor' }}
			pointLabelYOffset={-32}
			useMesh={true}
			legends={[
				{
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 100,
					translateY: 0,
					itemsSpacing: 0,
					itemDirection: 'left-to-right',
					itemWidth: 80,
					itemHeight: 20,
					itemOpacity: 0.75,
					symbolSize: 12,
					symbolShape: 'circle',
					symbolBorderColor: 'rgba(0, 0, 0, .5)',
					data: data.map((item) => ({
						color: hiddenIds.includes(item.id)
							? 'rgba(1, 1, 1, .1)'
							: item.color || colors(item),
						id: item.id,
						label: item.id,
					})),
					effects: [
						{
							on: 'hover',
							style: { itemBackground: 'rgba(0, 0, 0, .03)', itemOpacity: 1 },
						},
					],
					onClick: (datum) => {
						setHiddenIds((state) =>
							state.includes(String(datum.id))
								? state.filter((item) => item !== datum.id)
								: [...state, String(datum.id)]
						);
					},
				},
			]}
			enablePoints={true}
			pointSymbol={({ datum }) => {
				const date = moment(datum.x);
				const color = datum?.color || colorMap[datum?.country?.name] || '#000';

				return date.date() === 1 ? (
					<CircleOutline
						{...datum}
						stroke={color}
						size={'10px'}
						x={'-5px'}
						y={'-5px'}
					/>
				) : null; // Render circle only for the 1st of each month
			}}
		/>
	);
};
