/* eslint-disable react/prop-types */
import React from 'react';

export const LinkArrowSquareIcon = ({ stroke = '#CFCCD6' }) => (
	<svg
		width='18'
		height='18'
		viewBox='0 0 18 18'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M11 0V2H14.59L4.76 11.83L6.17 13.24L16 3.41V7H18V0M16 16H2V2H9V0H2C0.89 0 0 0.9 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V9H16V16Z'
			fill={stroke}
		/>
	</svg>
);
