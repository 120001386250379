/* eslint-disable react/prop-types */
import React from 'react';

export const NoIcon = ({ stroke = '#A40000', strokeWidth = 1, width = '10', height = '10' }) => (
	<svg
		width={width}
		height={height}
		viewBox='0 0 10 10'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M1.44482 1.43164L8.55549 8.54097M1.44482 8.54097L8.55549 1.43164'
			stroke={stroke}
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={strokeWidth}
		/>
	</svg>
);
