import AuthClient from './Clients/AuthClient';
import AuthClientMultipartFormData from './Clients/AuthClientMultipartFormData';

const userServicesUrl = '/auth/users/';

export const getUser = async (id) => {
	const userResponse = await AuthClient.get(userServicesUrl + id);
	return userResponse.data;
};

export const getUserMe = async (token) => {
	const userResponse = await AuthClient.get(userServicesUrl + 'me/', {
		headers: {
			Authorization: 'Bearer ' + token,
		},
	});
	return userResponse.data;
};

export const editUserMe = async (formData) => {
	const editResponse = await AuthClientMultipartFormData.put(userServicesUrl + 'me/', formData);
	return editResponse.data;
};
