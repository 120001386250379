import * as Yup from 'yup';

export const initialFormValues = {
	first_name: '',
	last_name: '',
	email: '',
};

export const createSubuserSchema = Yup.object().shape({
	first_name: Yup.string().required('Required'),
	last_name: Yup.string().required('Required'),
	email: Yup.string().email('Invalid email').required('Required'),
});
