import React from 'react';
import { TableCustomCellEnum } from 'models/enums';
import { NoIcon, YesIcon } from 'components/Icons';
import { ProductLinkCell } from 'components/Tables/shared/UniqueCells';

export const shortageColumns = [
	{
		size: 170,
		header: 'Name',
		accessorKey: 'name',
		accessorFn: (row) => row?.registration?.pack?.product?.name ? (
			<ProductLinkCell
				value={row.registration.pack.product.name}
				link={`/product-explorer/${row?.registration?.pack?.id}/`}
				params={!row.registration.is_eu_registered && `?country=${row?.registration?.country?.id || '0'}`}
				extraProps={{maxSize: '170px', wrap: true}}
			/>
		) : row?.name || '-',
		component: 'div',
	},
	{
		size: 70,
		header: 'Country',
		accessorKey: 'country__name',
		accessorFn: (row) => {
			const countriesParam = encodeURIComponent(
				JSON.stringify({
					countries: [row.country]
				})
			);
			return (row.country ? (
				<ProductLinkCell
					key={row.country.id}
					value={row.country.name}
					link={'/eu-wda-licenses'}
					params={`s=${countriesParam}`}
				/>
			) : null);
		},
		cellType: TableCustomCellEnum.DATA,
		enableSorting: false,
	},
	{
		size: 100,
		header: 'Registration Number',
		accessorKey: 'registration_number',
		accessorFn: (row) => row?.registration_number,
		cellType: TableCustomCellEnum.DATA,
		// enableSorting: false,
	},
	{
		// maxSize: 120,
		header: 'Active Shortage',
		accessorKey: 'active_shortage',
		accessorFn: (row) => (row?.is_active ? <YesIcon /> : <NoIcon />),
		cellType: TableCustomCellEnum.DATA,
		aligned: 'center',
		alignHeader: 'center',
		enableSorting: false,
	},
	{
		size: 70,
		header: 'Start Date',
		accessorKey: 'start_date',
		accessorFn: (row) => row?.start_date || '-',
		cellType: TableCustomCellEnum.DATA,
	},
	{
		size: 100,
		header: 'Expected End Date',
		accessorKey: 'expected_end_date',
		accessorFn: (row) => row?.expected_end_date || '-',
		cellType: TableCustomCellEnum.DATA,
	},
	{
		size: 100,
		header: 'Notification Date',
		accessorKey: 'notification_date',
		accessorFn: (row) => row?.notification_date || '-',
		cellType: TableCustomCellEnum.DATA,
	},
	{
		size: 150,
		header: 'Reason',
		accessorKey: 'reason',
		accessorFn: (row) => (row?.reason ? row.reason.name : '-'),
		cellType: TableCustomCellEnum.DATA,
		enableSorting: false,
	},
	// {
	// 	size: 160,
	// 	header: 'Additional Information',
	// 	accessorKey: 'additional_info',
	// 	accessorFn: (row) => (row?.additional_info ? {label: 'info', value: row.id} : ''),
	// 	cellType: TableCustomCellEnum.MODAL_LINK,
	// 	enableSorting: false,
	// },
];
