import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { MaterialUISizeEnum } from 'models/enums';
import { MaterialUIInputSizeType } from 'models/types';
import { SMALL_VARIANT_FONT_SIZE } from 'utils/consts/themeConsts';

interface BaseCheckboxProps {
  label?: string;
  checked?: boolean;
  size?: MaterialUIInputSizeType;
  [key: string | symbol | number]: any;
}

export const BaseCheckbox = ({
	label,
	checked,
	size = 'small',
	...inputProps
}: BaseCheckboxProps) => {
	return (
		<FormGroup>
			<FormControlLabel
				disableTypography={true}
				sx={{
					fontSize: size === MaterialUISizeEnum.SMALL
						? SMALL_VARIANT_FONT_SIZE
						: undefined,
				}}
				control={
					<Checkbox size={size} {...inputProps} checked={Boolean(checked)} />
				}
				label={label}
			/>
		</FormGroup>
	);
};
