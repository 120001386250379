export const initialFormValues = {
	logo: '',
	country_license: '',
	email: '',
	website: '',
	office_address: '',
	linkedin_link: '',
	site_address: '',
	description: '',
	is_clinical_trial_supply: null,
	is_parallel_trade: null,
	is_import_export: null,
	is_tenders: null,
	is_unlicensed_medicines: null,
};  