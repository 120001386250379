/* eslint-disable react/prop-types */
import React from 'react';

export const YesIcon = ({ stroke = '#2DAC00', strokeWidth = 1 }) => (
	<svg
		width='14'
		height='9'
		viewBox='0 0 14 9'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M1.00012 4.5L4.99542 8L13.0001 1'
			stroke={stroke}
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={strokeWidth}
		/>
	</svg>
);
