/* eslint-disable react/prop-types */
import React from 'react';
import { Fragment } from 'react';
import { Typography } from '@mui/material';
import { StyledInlineLink } from 'components/StyledComponents';
import { COLORS } from 'utils/consts';

export const LinksCell = ({ column, ...props }) => {
	const value = props.getValue();
	return (
		<Fragment>
			{value?.map((item, index) => (
				<Typography
					variant='inherit'
					component={'span'}
					sx={{
						fontWeight: column?.fontWeight ? column.fontWeight : 600,
						paddingRight: '4px',
					}}
					color={COLORS.darkGray}
					key={item.id}
				>
					<StyledInlineLink
						to={`${column?.baseLink || '/'}${item.id}`}
						color={COLORS.primary}
					>
						{item.name}
					</StyledInlineLink>
					{index + 1 !== value.length ? ',' : ''}
				</Typography>
			))}
		</Fragment>
	);
};
