import * as React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { TransitionProps } from '@mui/material/transitions';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import { COLORS } from 'utils/consts';
import {
	TreeItem,
	TreeItemProps,
	treeItemClasses,
} from '@mui/x-tree-view/TreeItem';

function TransitionComponent(props: TransitionProps) {
	const style = useSpring({
		to: {
			opacity: props.in ? 1 : 0,
			transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
		},
	});

	return (
		<animated.div style={style}>
			<Collapse {...props} />
		</animated.div>
	);
}

const CustomTreeItem = React.forwardRef(
	(props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
		<TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref} />
	)
);

export const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
	color: COLORS.black,
	[`.${treeItemClasses.label}`]: {
		lineHeight: '20px !important',
		fontSize: '16px',
		fontFamily: 'Codec-Pro',
		fontWeight: 500,
		paddingTop: '10px',
		paddingBottom: '10px',
		a: {
			fontWeight: 500,
		},
		'& .rightColumn': {
			minWidth: '50px',
			textAlign: 'right',
			'& a': {
				width: '100%',
				display: 'inline-block',
				cursor: 'pointer',
			},
		},
	},
	[`& .${treeItemClasses.content}`]: {
		paddingRight: '40px',
		'&:hover': {
			backgroundColor: 'unset',
		},
		[`&.${treeItemClasses.expanded}`]: {
			backgroundColor: 'unset',
		},
		[`&.${treeItemClasses.selected}`]: {
			backgroundColor: 'unset',
		},
		[`&.${treeItemClasses.focused}`]: {
			backgroundColor: 'unset',
			[`&.${treeItemClasses.selected}`]: {
				backgroundColor: 'unset',
			},
		},
	},
	[`& .${treeItemClasses.iconContainer}`]: {
		'& .close': {
			opacity: 0.3,
		},
	},
	[`&.noIcon .${treeItemClasses.iconContainer}`]: {
		display: 'none',
	},
	[`&.noIcon .${treeItemClasses.label}`]: {
		paddingLeft: '0',
		paddingTop: '0',
		paddingBottom: '0',
		'& a.mainLink': {
			padding: '10px',
			'&:hover': {
				color: COLORS.blue,
				background: COLORS.transparentBlue,
			},
		},
	},
	[`&.noIcon .${treeItemClasses.content}`]: {
		paddingLeft: 0,
	},
	[`&:not(.first) .${treeItemClasses.group}`]: {
		marginLeft: 14,
		paddingLeft: 8,
		borderLeft: `1px solid ${COLORS.mediumLightGray}`,
		['& li[role*=treeitem]']: {
			position: 'relative',
			['&:before']: {
				content: '""',
				width: '8px',
				height: '1px',
				background: COLORS.mediumLightGray,
				position: 'absolute',
				top: '20px',
				left: '-8px',
			},
		},
	},
	['&.first > .MuiTreeItem-content']: {
		// borderTop: `1px solid ${COLORS.mediumLightGray}`,
		padding: '10px 32px',
		color: COLORS.primary,
		position: 'relative',
		'&:before': {
			content: '""',
			width: 'calc(100% - 64px)',
			height: '1px',
			position: 'absolute',
			top: 0,
			left: '32px',
			background: COLORS.mediumLightGray,
		},
	},
	[`&.first .${treeItemClasses.iconContainer}`]: {
		width: 'auto !important',
	},
	[`&.first > div > .${treeItemClasses.group}`]: {
		marginLeft: '50px',
	},
	[`&.first > .${treeItemClasses.expanded}`]: {
		background: `${COLORS.transparentBlue} !important`,
		// borderTop: 'unset',
		'&:before': {
			content: 'unset',
		},
	},
}));
