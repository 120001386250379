import { CardItemTypeEnum } from 'models/enums';

export const cardFields = [
	{
		key: 'Tender Name:',
		fieldPath: ['title'],
		type: CardItemTypeEnum.TEXT,
		col: 4,
	},
	{
		key: 'Publication Date:',
		fieldPath: ['publication_date'],
		// format: 'D MMMM, YYYY',
		type: CardItemTypeEnum.DATE,
		col: 4,
	},
	{
		key: 'Link:',
		fieldPath: ['title'],
		linkFieldPath: ['link'],
		type: CardItemTypeEnum.EXTERNAL_LINK,
		col: 4,
	},
	{
		key: 'Country:',
		fieldPath: ['country', 'name'],
		type: CardItemTypeEnum.TEXT,
		col: 4,
	},
	{
		key: 'Closing Date:',
		fieldPath: ['closing_date'],
		// format: 'D MMMM, YYYY',
		type: CardItemTypeEnum.DATE,
		col: 4,
	},
];
