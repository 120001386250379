/* eslint-disable react/prop-types */
import React from 'react';

export const SortIcon = ({ stroke = '#0494F1', ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={18}
		height={18}
		fill={stroke}
		{...props}
	>
		<path d="M18 21L14 17H17V7H14L18 3L22 7H19V17H22M2 19V17H12V19M2 13V11H9V13M2 7V5H6V7H2Z" />
	</svg>
);