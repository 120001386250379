/* eslint-disable react/prop-types */
import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import { ReferenceDataContext } from './ReferenceDataContext';
import { getCountries } from 'services';
import { SERVER_DATE_FORMAT } from 'utils/consts';

export const ReferenceDataProvider = ({ children }) => {
	const [referenceData, setReferenceData] = useState({
		countries: [],
		dateDropdownOptions: [
			{
				id: moment(
					new Date(new Date().setMonth(new Date().getMonth() - 6))
				).format(SERVER_DATE_FORMAT),
				label: 'Last 6 months',
			},
			{
				id: moment(
					new Date(new Date().setFullYear(new Date().getFullYear() - 1))
				).format(SERVER_DATE_FORMAT),
				label: 'Last 12 months',
			},
			{
				id: moment(
					new Date(new Date().setFullYear(new Date().getFullYear() - 2))
				).format(SERVER_DATE_FORMAT),
				label: 'Last 24 months',
			},
			{ id: null, label: 'All' },
		],
	});
	const [loadingReference, setLoadingReference] = useState(false);

	useEffect(() => {
		async function fetchReferenceData() {
			try {
				setLoadingReference(true);
				const countriesData = await getCountries();
				setReferenceData((prevData) => ({
					...prevData,
					countries: countriesData.map((e) => ({
						...e,
						name: e.name === 'Czech Republic' ? 'Czechia' : e.name,
					})),
				}));
			} finally {
				setLoadingReference(false);
			}
		}
		fetchReferenceData();
	}, []);

	const providerValue = useMemo(
		() => ({
			referenceData,
			loadingReference,
		}),
		[referenceData, loadingReference]
	);

	return (
		<ReferenceDataContext.Provider value={providerValue}>
			{children}
		</ReferenceDataContext.Provider>
	);
};
