/* eslint-disable react/prop-types */
import React from 'react';

export const EditIcon = ({ stroke = '#0494F1', ...props }) => (
	<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.2213 7.88856V11.2226C13.2213 12.3272 12.326 13.2226 11.2213 13.2226H2.77734C1.67268 13.2226 0.777344 12.3272 0.777344 11.2226V2.77856C0.777344 1.6739 1.67268 0.778564 2.77734 0.778564H6.11068M8.98401 2.50256L10.348 3.86656M4.33334 7.15323L10.6187 0.867898L13.132 3.38123L6.84668 9.66656H4.33268L4.33334 7.15323Z"
			stroke={stroke}
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
