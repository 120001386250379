import React from 'react';
import { Box, Typography, Grid, Avatar, styled } from '@mui/material';
import { Formik } from 'formik';

import { BaseButton } from 'components/Buttons';
import { BaseInput } from 'components/Inputs';
import { addNoteSchema, initialFormValues } from 'forms/addNote';
import { useUser } from 'context';
import { COLORS } from 'utils/consts';

const AddNoteHeaderWrapper = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
}));
const AvatarWrapper = styled(Box)({
	display: 'flex',
	gap: '8px',
	alignItems: 'center',
});
const ModalTitle = styled('span')(() => ({
	fontWeight: 600,
	fontSize: '24px',
	lineHeight: 1.5,
}));
const UserAvatar = styled(Avatar)({
	backgroundColor: COLORS.secondary,
	color: COLORS.black,
	width: 48,
	height: 48,
});

// eslint-disable-next-line react/prop-types
export const AddNewNoteModalContent = ({ onSubmit, onCancel }) => {
	const { user } = useUser();
	const userInitials = user ? user?.first_name?.split('')[0] + user?.last_name?.split('')[0] : '';
	return (
		<Box>
			<Formik initialValues={initialFormValues} validationSchema={addNoteSchema} onSubmit={onSubmit}>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={4}>
							<Grid item xs={12}>
								<AddNoteHeaderWrapper>
									<ModalTitle>Add Note</ModalTitle>
									<AvatarWrapper>
										{user?.avatar ? (
											<UserAvatar alt="Profile image" src={user.avatar} />
										) : (
											<UserAvatar>{userInitials}</UserAvatar>
										)}
										<Typography color="primary" fontSize={12}>
											<strong>
												{user?.first_name} {user?.last_name}
											</strong>
										</Typography>
									</AvatarWrapper>
								</AddNoteHeaderWrapper>
							</Grid>
							<Grid item xs={12}>
								<BaseInput
									fullWidth
									rows={4}
									name="note"
									label="Add Note"
									placeholder="Enter your Note here."
									value={values.note}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.note && Boolean(errors.note)}
									helperText={touched.note ? errors.note : ''}
								/>
							</Grid>
							<Grid container spacing={2} item xs={12}>
								<Grid item xs={6}>
									<Box mb={2}>
										<BaseButton type="button" onClick={onCancel} variant={'outlined'} label={'Cancel'} />
									</Box>
								</Grid>
								<Grid item xs={6}>
									<Box mb={2}>
										<BaseButton type={'submit'} label={'Confirm'} />
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};
