import React from 'react';
import { styled, Box, Modal as MuiModal, Button, Grid } from '@mui/material';
import { CloseIcon } from 'components/Icons';

import { COLORS } from 'utils/consts';

const ModalWrapper = styled(Box)(() => ({
	position: 'fixed',
	zIndex: 1300,
	left: '50%',
	top: '50%',
	transform: 'translate(-50%,-50%)',
	boxSizing: 'border-box',
	transition: 'all 0.6s ease-out',
	padding: '40px',
	background: COLORS.white,
	boxShadow: '0px 0px 24px rgba(107, 107, 107, 0.16)',
	borderRadius: '16px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	'&:focus-visible': {
		outline: 'none',
	},
}));

const ModalTitle = styled('span')(() => ({
	fontWeight: 600,
	fontSize: '24px',
	lineHeight: 1.5,
}));

export const Modal = ({
	close,
	show = false,
	modalTitle,
	children,
	closeButton = false,
	compact = false,
	width = '400px',
}: any) => {
	return (
		<MuiModal open={show} onClose={close} sx={{ maxHeight: '90vh' }}>
			<ModalWrapper
				sx={{
					width,
					maxHeight: '90vh',
					overflowY: 'auto',
					...(compact && {
						alignItems: 'start',
						padding: '10px 15px 25px 25px',
					}),
				}}
			>
				<Grid
					container
					justifyContent={compact ? 'space-between' : 'center'}
					flexWrap='nowrap'
					alignItems='flex-start'
					columnGap={2}
					{...(compact && { sx: { paddingBottom: '10px' } })}
				>
					<ModalTitle
						{...(compact && {
							sx: {
								fontSize: '16px',
								paddingTop: '10px',
							},
						})}
					>
						{modalTitle}
					</ModalTitle>
					{closeButton && (
						<Button
							onClick={close}
							sx={{
								padding: 0,
								minWidth: 'unset',
							}}
						>
							<CloseIcon />
						</Button>
					)}
				</Grid>
				{children}
			</ModalWrapper>
		</MuiModal>
	);
};
