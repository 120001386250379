/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { Chip } from '@mui/material';

import { getNestedValue } from 'utils';

export const ListChipItem = ({ 
	dataItem, 
	item, 
	color = 'inherit', 
	fontWeight = 600, 
	variant = 'body1', 
	...props 
}) => {
	if (item.value) return <Chip variant='outlined' label={item.value} />;

	if (!dataItem) return null;
	const list = getNestedValue(dataItem, item.listFieldPath);
	return (
		<Fragment>
			{(Array.isArray(list) && list.length && list.map((val, index) => (
				<Chip
					key={val?.id || val || index}
					sx={{ marginRight: '.5rem', marginBottom: '.5rem' }}
					variant='outlined'
					label={getNestedValue(val, item.fieldPath)}
				/>
			))) || '-'}
		</Fragment>
	);
};
