/* eslint-disable react/prop-types */
import React from 'react';

export const TrashIcon = ({ stroke = '#0494F1', ...props }) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.4987 6.22202V12.2214C12.4987 13.326 11.6034 14.2214 10.4987 14.2214H5.61004C4.50537 14.2214 3.61004 13.326 3.61004 12.2214V6.22202M2.7207 3.55669H13.3874M7.16537 1.77869H8.94337M5.7747 7.11135V11.5547M8.05404 7.11135V11.5547M10.248 7.11135V11.5547"
			stroke={stroke}
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
