import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Grid, Avatar, Badge, Box, Typography, Paper, List, ListItem, ListItemIcon, ListItemText, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useMyWDACompany } from 'services/queries';
import { updateWDACompany } from 'services';
import { useNotifications } from 'context';

import { COLORS } from 'utils/consts';
import { MEDIUM_BORDER_RADIUS } from 'utils/consts/themeConsts';
import { AlertsTypesEnum, ApplicationRoutesEnum } from 'models/enums';
import { initialFormValues } from 'forms/editCompanyProfile';
import { BaseInput } from 'components/Inputs';
import { NoIcon, YesIcon } from 'components/Icons';

interface ErrorType extends Error {
    status?: string | number;
}

const StyledAvatar = styled(Avatar)({
	backgroundColor: 'rgba(207, 204, 214, 0.5)',
	color: COLORS.black,
	width: 127,
	height: 127,
	fontSize: '36px',
	textTransform: 'uppercase',
});

const EditProfileIcon = styled('img')(() => ({
	width: '9px',
	height: '9px',
}));

const StyledBadge = styled(Badge)({
	width: '20px',
	height: '20px',
	cursor: 'pointer',
	top: '-25px',
	left: '90px',
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
	background: COLORS.white,
	borderRadius: '20px',
	[`& .${toggleButtonGroupClasses.grouped}`]: {
		border: `1px solid ${COLORS.lightGray}`,
		padding: '8px',
		borderRadius: '20px',
	},
	[`& .${toggleButtonGroupClasses.firstButton}`]: {
		borderRadius: '20px 0 0 20px',
		'&.Mui-selected': {
			background: COLORS.success,
		}
	},
	[`& .${toggleButtonGroupClasses.lastButton}`]: {
		borderRadius: '0 20px 20px 0',
		'&.Mui-selected': {
			background: COLORS.error,
		}
	},
}));

const StyledLink = styled(Link)(({ disabled, color }: any) => ({
	color: disabled ? '#ccc' : color || 'unset',
	pointerEvents: disabled ? 'none' : 'unset',
	textDecoration: 'none',
	display: 'flex',
	alignItems: 'center',
	border: `1.5px solid ${COLORS.blue}`,
	borderRadius: '10px',
	padding: '10px 20px',
	gap: '5px'
}));

const CheckboxWrapper = styled(Grid)({
	background: COLORS.white,
	borderRadius: '5px',
	padding: '20px',
	minWidth: '170px',
	display: 'flex',
	alignItems: 'center',
	columnGap: '10px'
});

const VerticalList = styled(List)<{ component?: React.ElementType }>({
	'& .MuiListItem-root': {
		width: 'auto',
		padding: '0 20px 0 0',
	},
	'& .MuiListItemIcon-root': {
		minWidth: 0,
		marginRight: 16,
	},
});

const StyledButton = styled(Button)(() => ({
	padding: '17px',
	borderRadius: MEDIUM_BORDER_RADIUS,
	width: '100%',
	marginBottom: '40px',
	minWidth: '170px',
}));

export const EditCompanyProfile = () => {
	const navigate = useNavigate();
	const [imageAvatar, setImageAvatar] = useState<any>({});
	const { addNotification } = useNotifications();

	const {
		data: CompanyDetails,
		error,
		refetch
	} = useMyWDACompany();

	useEffect(() => {
		refetch();
	}, [refetch]);

	const editProfileSchema = Yup.object().shape({
		country_license: Yup.string(),
		email: Yup.string().email('Invalid email'),
		website: Yup.string().url('Invalid URL'),
		office_address: Yup.string(),
		linkedin_link: Yup.string().matches(/^(https?:\/\/)(www\.)?linkedin\.com\/\S*$/, 'Invalid Linkedin URL'),
		site_address: Yup.string(),
		description: Yup.string(),
		is_clinical_trial_supply: Yup.mixed().oneOf(['yes', 'no', null]),
		is_parallel_trade: Yup.mixed().oneOf(['yes', 'no', null]),
		is_import_export: Yup.mixed().oneOf(['yes', 'no', null]),
		is_tenders: Yup.mixed().oneOf(['yes', 'no', null]),
		is_unlicensed_medicines: Yup.mixed().oneOf(['yes', 'no', null]),
	});

	const onEditProfileHandler = async (values: any, { setSubmitting, setErrors }: any) => {
		try {
			const formData = {
				email: values.email,
				website: values.website,
				office_address: values.office_address,
				linkedin_link: values.linkedin_link,
				site_address: values.site_address,
				description: values.description,
				is_clinical_trial_supply: values.is_clinical_trial_supply === 'yes' ? true : values.is_clinical_trial_supply === 'no' ? false : null,
				is_parallel_trade: values.is_parallel_trade === 'yes' ? true : values.is_parallel_trade === 'no' ? false : null,
				is_import_export: values.is_import_export === 'yes' ? true : values.is_import_export === 'no' ? false : null,
				is_tenders: values.is_tenders === 'yes' ? true : values.is_tenders === 'no' ? false : null,
				is_unlicensed_medicines: values.is_unlicensed_medicines === 'yes' ? true : values.is_unlicensed_medicines === 'no' ? false : null,
				...(typeof imageAvatar.image !== 'string' && { logo: imageAvatar.image })
			};

			const updateRes = await updateWDACompany({ uid: CompanyDetails.id, body: formData });
			if (updateRes) {
				try {
					addNotification({
						type: AlertsTypesEnum.SUCCESS,
						message: 'Profile data updated',
					});
				} finally {
					navigate(ApplicationRoutesEnum.COMPANY_PROFILE);
				}
			}
		} catch (err: any) {
			if (err.data.detail) {
				addNotification({
					type: AlertsTypesEnum.ERROR,
					message: err.data.detail,
				});
			} else {
				setErrors(err.data);
			}
		} finally {
			setSubmitting(false);
		}
	};

	const handleImageUpload = (e: any) => {
		const image = e.target.files[0];
		const reader = new FileReader();
		reader.readAsArrayBuffer(image);
		reader.onload = () => {
			//@ts-ignore
			const blob = new Blob([reader.result]);
			const blobImageURI = URL.createObjectURL(blob);
			setImageAvatar({ image, imageBlob: blobImageURI && blobImageURI });
		};
	};

	const formik = useFormik({
		initialValues: initialFormValues,
		validationSchema: editProfileSchema,
		onSubmit: onEditProfileHandler,
	});

	useEffect(() => {
		if ((error as ErrorType)?.status === 404) {
			navigate(ApplicationRoutesEnum.COMPANY_PROFILE);
		} else if (CompanyDetails) {
			formik.setValues({
				...formik.values,
				country_license: CompanyDetails.wda_license.country.name || CompanyDetails.country || '',
				email: CompanyDetails.email || '',
				website: CompanyDetails.website || '',
				office_address: CompanyDetails.address || '',
				linkedin_link: CompanyDetails.linkedin_link || '',
				site_address: CompanyDetails.wda_license.site_address || '',
				description: CompanyDetails.description || CompanyDetails.wda_license.details || '',
			});
			formik.setFieldValue('is_clinical_trial_supply', CompanyDetails.is_clinical_trial_supply === true ? 'yes' : CompanyDetails.is_clinical_trial_supply === false ? 'no' : undefined);
			formik.setFieldValue('is_parallel_trade', CompanyDetails.is_parallel_trade === true ? 'yes' : CompanyDetails.is_parallel_trade === false ? 'no' : undefined);
			formik.setFieldValue('is_import_export', CompanyDetails.is_import_export === true ? 'yes' : CompanyDetails.is_import_export === false ? 'no' : undefined);
			formik.setFieldValue('is_tenders', CompanyDetails.is_tenders === true ? 'yes' : CompanyDetails.is_tenders === false ? 'no' : undefined);
			formik.setFieldValue('is_unlicensed_medicines', CompanyDetails.is_unlicensed_medicines === true ? 'yes' : CompanyDetails.is_unlicensed_medicines === false ? 'no' : undefined);

			if (CompanyDetails.logo) {
				setImageAvatar({ image: CompanyDetails.logo, imageBlob: null });
			}
		}
	}, [error, CompanyDetails, navigate]);

	return (
		<Fragment>
			<Grid container spacing={4}>
				<Grid item container xs={12} alignItems={'center'} gap={'30px'}>
					<Grid item>
						{imageAvatar.imageBlob || imageAvatar.image ? (
							<StyledAvatar alt='Profile image' src={imageAvatar.imageBlob || imageAvatar.image} />
						) : (
							<StyledAvatar> </StyledAvatar>
						)}
						<label htmlFor='avatar' style={{ position: 'relative' }}>
							<StyledBadge
								badgeContent={<EditProfileIcon alt='Edit progile icon' src={require('assets/icons/' + 'edit_profile_white.png')} />}
								color='primary'
							/>
							<Typography sx={{ fontSize: '14px', marginTop: '-10px', cursor: 'pointer' }}>
                                (Add company logo)
							</Typography>
						</label>
						<input
							type='file'
							accept='image/png, image/jpeg'
							id='avatar'
							style={{ display: 'none' }}
							onChange={handleImageUpload}
						/>
					</Grid>
					<Grid item>
						<Typography variant="subtitle2" component={'h2'} fontSize={40}>
							{CompanyDetails?.name}
						</Typography>
					</Grid>
					<Grid item>
						<StyledLink
							to={ApplicationRoutesEnum.COMPANY_PROFILE}
							color={COLORS.blue}
							target="_blank"
						>
							<Typography variant='subtitle2' textTransform={'uppercase'}>Company Profile</Typography>
						</StyledLink>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<form onSubmit={formik.handleSubmit}>
						<Grid container pt={4} columnSpacing={2} rowSpacing={4}>
							<Grid item xs={12}>
								<Typography
									component={'h3'}
									variant="subtitle2"
									fontSize='20px'
								>
                                    Basic informations
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<BaseInput
									fullWidth
									name='country_license'
									label='Country license'
									placeholder="Enter where country license is held"
									value={formik.values.country_license}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									inputProps={{
										readOnly: true,
										sx: { backgroundColor: COLORS.white }
									}}
									error={formik.touched.country_license && Boolean(formik.errors.country_license)}
									helperText={formik.touched.country_license ? formik.errors.country_license : ''}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<BaseInput
									fullWidth
									name='email'
									label='Contact email'
									placeholder="Add contact email"
									value={formik.values.email}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									inputProps={{ sx: { backgroundColor: COLORS.white } }}
									error={formik.touched.email && Boolean(formik.errors.email)}
									helperText={formik.touched.email ? formik.errors.email : ''}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<BaseInput
									fullWidth
									name='website'
									label='Website'
									placeholder='Add website URL'
									value={formik.values.website}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									inputProps={{ sx: { backgroundColor: COLORS.white } }}
									error={formik.touched.website && Boolean(formik.errors.website)}
									helperText={formik.touched.website ? formik.errors.website : ''}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<BaseInput
									fullWidth
									name='office_address'
									label='Office address'
									placeholder='Enter office address'
									value={formik.values.office_address}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									inputProps={{ sx: { backgroundColor: COLORS.white } }}
									error={formik.touched.office_address && Boolean(formik.errors.office_address)}
									helperText={formik.touched.office_address ? formik.errors.office_address : ''}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<BaseInput
									fullWidth
									name='linkedin_link'
									label='Linkedin'
									placeholder='Add linkedin link'
									value={formik.values.linkedin_link}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									inputProps={{ sx: { backgroundColor: COLORS.white } }}
									error={formik.touched.linkedin_link && Boolean(formik.errors.linkedin_link)}
									helperText={formik.touched.linkedin_link ? formik.errors.linkedin_link : ''}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<BaseInput
									fullWidth
									name='site_address'
									label='Site address'
									placeholder='Add site address'
									value={formik.values.site_address}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									inputProps={{ sx: { backgroundColor: COLORS.white } }}
									error={formik.touched.site_address && Boolean(formik.errors.site_address)}
									helperText={formik.touched.site_address ? formik.errors.site_address : ''}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography
									component={'h3'}
									variant="subtitle2"
									sx={{
										margin: '20px auto 10px',
										fontSize: '20px',
									}}
								>
                                    About the company
								</Typography>
								<BaseInput
									fullWidth
									multiline
									rows={5}
									size='medium'
									name='description'
									placeholder='Add info about your company.'
									cornerNote={'(max 1000 characters)'}
									value={formik.values.description}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									inputProps={{ sx: { backgroundColor: COLORS.white } }}
									error={formik.touched.description && Boolean(formik.errors.description)}
									helperText={formik.touched.description ? formik.errors.description : ''}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography
									component={'h3'}
									variant="subtitle2"
									fontSize='20px'
								>
                                    Options that company offer
								</Typography>
								<Grid container gap={2} margin={'20px 0'}>
									<CheckboxWrapper item>
										<StyledToggleButtonGroup
											size="small"
											id="is_clinical_trial_supply"
											value={formik.values.is_clinical_trial_supply}
											exclusive
											onChange={(event, newVal) => formik.setFieldValue('is_clinical_trial_supply', newVal)}
											aria-label="Clinical Trial Supply"
											// label="Clinical Trial Supply"
										>
											<ToggleButton value="yes" aria-label="left aligned">
												<YesIcon
													strokeWidth={2}
													stroke={formik.values.is_clinical_trial_supply === 'yes' ? '#fff' : COLORS.success}
												/>
											</ToggleButton>
											<ToggleButton value="no" aria-label="centered">
												<NoIcon
													strokeWidth={2}
													stroke={formik.values.is_clinical_trial_supply === 'no' ? '#fff' : COLORS.error}
												/>
											</ToggleButton>
										</StyledToggleButtonGroup>
										<Typography
											component={'label'}
											variant="subtitle2"
											htmlFor="is_clinical_trial_supply"
										>
                                            Clinical Trial Supply
										</Typography>
									</CheckboxWrapper>
									<CheckboxWrapper item>
										<StyledToggleButtonGroup
											size="small"
											id="is_parallel_trade"
											value={formik.values.is_parallel_trade}
											exclusive
											onChange={(event, newVal) => formik.setFieldValue('is_parallel_trade', newVal)}
											aria-label="Parallel trade"
											// label="Parallel trade"
										>
											<ToggleButton value="yes" aria-label="left aligned">
												<YesIcon
													strokeWidth={2}
													stroke={formik.values.is_parallel_trade === 'yes' ? '#fff' : COLORS.success}
												/>
											</ToggleButton>
											<ToggleButton value="no" aria-label="centered">
												<NoIcon
													strokeWidth={2}
													stroke={formik.values.is_parallel_trade === 'no' ? '#fff' : COLORS.error}
												/>
											</ToggleButton>
										</StyledToggleButtonGroup>
										<Typography
											component={'label'}
											variant="subtitle2"
											htmlFor="is_parallel_trade"
										>
                                            Parallel trade
										</Typography>
									</CheckboxWrapper>
									<CheckboxWrapper item>
										<StyledToggleButtonGroup
											size="small"
											id="is_import_export"
											value={formik.values.is_import_export}
											exclusive
											onChange={(event, newVal) => formik.setFieldValue('is_import_export', newVal)}
											aria-label="Import/export"
											// label="Import/export"
										>
											<ToggleButton value="yes" aria-label="left aligned">
												<YesIcon
													strokeWidth={2}
													stroke={formik.values.is_import_export === 'yes' ? '#fff' : COLORS.success}
												/>
											</ToggleButton>
											<ToggleButton value="no" aria-label="centered">
												<NoIcon
													strokeWidth={2}
													stroke={formik.values.is_import_export === 'no' ? '#fff' : COLORS.error}
												/>
											</ToggleButton>
										</StyledToggleButtonGroup>
										<Typography
											component={'label'}
											variant="subtitle2"
											htmlFor="is_import_export"
										>
                                            Import/export
										</Typography>
									</CheckboxWrapper>
									<CheckboxWrapper item>
										<StyledToggleButtonGroup
											size="small"
											id="is_tenders"
											value={formik.values.is_tenders}
											exclusive
											onChange={(event, newVal) => formik.setFieldValue('is_tenders', newVal)}
											aria-label="Tenders"
											// label="Tenders"
										>
											<ToggleButton value="yes" aria-label="left aligned">
												<YesIcon
													strokeWidth={2}
													stroke={formik.values.is_tenders === 'yes' ? '#fff' : COLORS.success}
												/>
											</ToggleButton>
											<ToggleButton value="no" aria-label="centered">
												<NoIcon
													strokeWidth={2}
													stroke={formik.values.is_tenders === 'no' ? '#fff' : COLORS.error}
												/>
											</ToggleButton>
										</StyledToggleButtonGroup>
										<Typography
											component={'label'}
											variant="subtitle2"
											htmlFor="is_tenders"
										>
                                            Tenders
										</Typography>
									</CheckboxWrapper>
									<CheckboxWrapper item>
										<StyledToggleButtonGroup
											size="small"
											id="is_unlicensed_medicines"
											value={formik.values.is_unlicensed_medicines}
											exclusive
											onChange={(event, newVal) => formik.setFieldValue('is_unlicensed_medicines', newVal)}
											aria-label="Unlicensed medicines"
											// label="Unlicensed medicines"
										>
											<ToggleButton value="yes" aria-label="left aligned">
												<YesIcon
													strokeWidth={2}
													stroke={formik.values.is_unlicensed_medicines === 'yes' ? '#fff' : COLORS.success}
												/>
											</ToggleButton>
											<ToggleButton value="no" aria-label="centered">
												<NoIcon
													strokeWidth={2}
													stroke={formik.values.is_unlicensed_medicines === 'no' ? '#fff' : COLORS.error}
												/>
											</ToggleButton>
										</StyledToggleButtonGroup>
										<Typography
											component={'label'}
											variant="subtitle2"
											htmlFor="is_unlicensed_medicines"
										>
                                            Unlicensed medicines
										</Typography>
									</CheckboxWrapper>
								</Grid>
							</Grid>

							<Grid container item xs={12} columnGap={2}>
								<Grid item>
									<StyledButton
										disabled={formik.isSubmitting}
										disableElevation={true}
										color='primary'
										variant='contained'
										type='submit'
									>
                                        Save
									</StyledButton>
								</Grid>
								<Grid item>
									<StyledButton
										disableElevation={true}
										color='primary'
										variant='outlined'
										onClick={() => navigate(-1)}
									>
                                        Cancel
									</StyledButton>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</Grid>
			</Grid>
			{CompanyDetails?.wda_license?.wda_authorizations?.length > 0 && (
				<Box>
					<Typography
						component={'h3'}
						variant="subtitle2"
					>
                        Licenses conditions
					</Typography>
					<Paper elevation={0} sx={{ borderRadius: '10px', padding: '20px', marginTop: '20px' }}>
						<VerticalList>
							{CompanyDetails.wda_license.wda_authorizations.map(item => (
								<ListItem key={item.id}>
									<ListItemIcon>
										<YesIcon strokeWidth={1.5} />
									</ListItemIcon>
									<ListItemText
										primary={`${item.code} ${item.description}`}
										primaryTypographyProps={{
											variant: 'subtitle1',
											component: 'span'
										}}
									/>
								</ListItem>
							))}
						</VerticalList>
					</Paper>
				</Box>
			)}
		</Fragment>
	);
};
