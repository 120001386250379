import { generateQuery } from 'utils';
import AuthClient from './Clients/AuthClient';

const companiesServicesUrl = '/companies/';

export const getUserCompanies = async () => {
	const companiesResponse = await AuthClient.get(companiesServicesUrl);
	return companiesResponse.data;
};

export const getUserCompany = async (companyId) => {
	const companyResponse = await AuthClient.get(`${companiesServicesUrl}${companyId}/`);
	return companyResponse.data;
};

export const getCompanySubusers = async ({ params, queryParams }: { params: any; queryParams?: any }) => {
	const companySubusersResponse = await AuthClient.get(
		`${companiesServicesUrl}${params.uid}/sub-users/` + generateQuery(queryParams)
	);
	return companySubusersResponse.data;
};

export const createCompanySubuser = async (companyId, userBody) => {
	const companySubusersResponse = await AuthClient.post(`${companiesServicesUrl}${companyId}/sub-users/`, userBody);
	return companySubusersResponse.data;
};

export const updateCompanySubuser = async (companyId, { id, ...userBody }) => {
	const companySubusersResponse = await AuthClient.put(`${companiesServicesUrl}${companyId}/sub-users/${id}/`, userBody);
	return companySubusersResponse.data;
};

export const disableCompanySubuser = async (companyId, userId) => {
	const companySubusersResponse = await AuthClient.delete(`${companiesServicesUrl}${companyId}/sub-users/${userId}/`);
	return companySubusersResponse.data;
};

export const enableCompanySubuser = async (companyId, userId) => {
	const companySubusersResponse = await AuthClient.post(`${companiesServicesUrl}${companyId}/sub-users/${userId}/reactivate/`);
	return companySubusersResponse.data;
};

export const getCompanyNotes = async (companyId) => {
	const companyNotesResponse = await AuthClient.get(`${companiesServicesUrl}${companyId}/notes/`);
	return companyNotesResponse.data;
};

export const addNote = async (companyId, noteBody) => {
	const companyNoteResponse = await AuthClient.post(`${companiesServicesUrl}${companyId}/notes/add_note/`, noteBody);
	return companyNoteResponse.data;
};
