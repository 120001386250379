import React, { FC, ReactNode, useState } from 'react';

import { Box, ListItem, ListItemText, Select, Checkbox, OutlinedInput, Typography } from '@mui/material';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { MatFormLabel } from '../';
import { COLORS } from 'utils/consts';
import { MaterialUIInputSizeType, MaterialUIInputVariantType } from 'models/types';
import { MaterialUISizeEnum } from 'models/enums';
import { MEDIUM_BORDER_RADIUS, SMALL_VARIANT_FONT_SIZE, SMALL_VARIANT_INPUT_HEIGHT } from 'utils/consts/themeConsts';

interface BaseSelectInputInterface {
  onChange?: (x: any) => void;
  onBlur?: (x: any) => void;
  value?: any;
  data: any[];
  name: string;
  label: string;
  labelInfo?: ReactNode;
  placeholder?: string;
  autoComplete?: string;
  variant?: MaterialUIInputVariantType;
  autoWidth?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  sx?: any;
  inputProps?: any;
  size?: MaterialUIInputSizeType;
}

export const BaseSelectInput: FC<BaseSelectInputInterface> = ({
	data,
	label,
	labelInfo,
	placeholder,
	name,
	fullWidth,
	size = 'small',
}) => {
	const [selected, setSelected] = useState<any[]>([]);

	const handleChange = (index: number) => {
		const value = data[index];

		setSelected((prevSelected) => {
			if (prevSelected.some((val) => val.name === value.name)) {
				return prevSelected.filter((val) => val.name !== value.name);
			} else {
				return [...prevSelected, value];
			}
		});
	};

	function renderRow(props: ListChildComponentProps) {
		const { index, style, data } = props;
		const item = data[index];
		return (
			<ListItem style={style} key={item.name} value={item.name} onClick={() => handleChange(index)}>
				<Box display='flex' alignItems='center'>
					<Checkbox checked={selected.some((val) => val.name === item.name)} />
					<Box mr={1}>{item.flag}</Box>
					<ListItemText primary={item.name} />
				</Box>
			</ListItem>
		);
	}

	return (
		<Box>
			<MatFormLabel id={name}>
				{label}
				{labelInfo && <Box sx={{ ml: 'auto' }}>{labelInfo}</Box>}
			</MatFormLabel>
			<Select
				displayEmpty
				id={name}
				multiple
				fullWidth={fullWidth}
				value={selected}
				input={<OutlinedInput />}
				renderValue={(selected) =>
					selected.map((val) => val.name).join(', ') || (
						<Typography
							variant='body1'
							fontSize={size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_FONT_SIZE : undefined}
							color={COLORS.mediumGray}
						>
							{placeholder}
						</Typography>
					)
				}
				sx={{
					maxHeight: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_INPUT_HEIGHT : undefined,
					fontSize: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_FONT_SIZE : undefined,
					borderRadius: MEDIUM_BORDER_RADIUS,
					backgroundColor: COLORS.white,
				}}
			>
				<Box sx={{ width: '100%', height: 400, bgcolor: 'background.paper' }}>
					<FixedSizeList
						itemData={data}
						height={400}
						width={'100%'}
						itemSize={SMALL_VARIANT_INPUT_HEIGHT}
						itemCount={data?.length}
						overscanCount={5}
					>
						{renderRow}
					</FixedSizeList>
				</Box>
			</Select>
		</Box>
	);
};
