import AuthClient from './Clients/AuthClient';
import { generateQuery } from 'utils';

const awardedTendersUrl = '/v1/awarded_tenders/';

export const getProductTenders = async ({
	queryParams,
}: {
  queryParams: any;
}) => {
	const tendersResponse = await AuthClient.get(
		awardedTendersUrl + generateQuery(queryParams)
	);
	return tendersResponse.data;
};

const tendersUrl = '/v1/tenders/';

export const getTenders = async ({ queryParams }: { queryParams: any }) => {
	const tendersResponse = await AuthClient.get(
		tendersUrl + generateQuery(queryParams)
	);
	return tendersResponse.data;
};

export const getTender = async ({ params }: { params: any }) => {
	const atcCodeResponse = await AuthClient.get(`${tendersUrl}${params.uid}/`);
	return atcCodeResponse.data;
};

const lotsUrl = '/v1/lots/';

export const getTenderLots = async ({ queryParams }: { queryParams: any }) => {
	const tendersResponse = await AuthClient.get(
		lotsUrl + generateQuery(queryParams)
	);
	return tendersResponse.data;
};
