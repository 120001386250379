import React, { FC, ReactNode } from 'react';
import { MatFormLabel, TextFieldWrapper } from '../';
import { Box, Typography } from '@mui/material';
import { MaterialUIInputVariantType, MaterialUIInputSizeType } from 'models/types';

interface BaseInputInterface {
  onChange?: (x: any) => void;
  onBlur?: (x: any) => void;
  onFocus?: (x: any) => void;
  onkeydown?: (x: any) => void;
  value?: any;
  name: string;
  label?: string;
  altLabel?: string;
  labelInfo?: ReactNode;
  placeholder?: string;
  autoComplete?: string;
  variant?: MaterialUIInputVariantType;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  sx?: any;
  inputProps?: any;
  multiline?: any;
  rows?: number;
  size?: MaterialUIInputSizeType;
  wrapperProps?: any;
  background?: string;
  cornerNote?: string | ReactNode;
}

export const BaseInput: FC<BaseInputInterface> = ({
	onChange,
	onBlur,
	onFocus,
	onkeydown,
	value,
	autoComplete,
	variant = 'outlined',
	fullWidth,
	label,
	altLabel,
	labelInfo,
	name,
	placeholder,
	error,
	helperText,
	inputProps,
	multiline,
	rows,
	size = 'small',
	wrapperProps,
	background,
	cornerNote,
}) => {
	return (
		<Box {...wrapperProps}>
			{label && (
				<MatFormLabel id={name}>
					{label}
					{labelInfo && <Box sx={{ ml: 'auto' }}>{labelInfo}</Box>}
				</MatFormLabel>
			)}
			{altLabel && (
				<Typography mb={2} fontSize={'12px'} fontWeight={500}>
					{altLabel}
				</Typography>
			)}
			<TextFieldWrapper
				onBlur={onBlur}
				onChange={onChange}
				onFocus={onFocus}
				onKeyDown={onkeydown}
				value={value}
				variant={variant}
				fullWidth={fullWidth}
				id={name}
				name={name}
				placeholder={placeholder || label}
				autoComplete={autoComplete || name}
				error={error}
				helperText={helperText}
				InputProps={inputProps}
				multiline={multiline}
				rows={rows}
				size={size}
				sx={{
					overflow: 'hidden',
					borderRadius: '10px',
					...(background && {background: background})
				}}
			/>
			{cornerNote && (
				<Box sx={{position: 'relative'}}>
					<Typography sx={{
						fontSize: '14px',
						opacity: '0.7',
						position: 'absolute',
						right: '10px',
						bottom: error || helperText ? '30px' : '7px',
					}}>
						{cornerNote}
					</Typography>
				</Box>
			)}
		</Box>
	);
};
