/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { Typography } from '@mui/material';
import { TypographyVariantType } from 'models/types';
import { StyledLink } from 'components/StyledComponents';
import { getNestedValue } from 'utils';
import { capitalizeString } from 'utils/capitalizeString';

export const ListLinkItem = ({ dataItem, item, color = 'primary', fontWeight = 600, variant = 'body1', ...props }) => {
	if (item.value)
		return (
			<Typography color={color} fontWeight={fontWeight} variant={variant as TypographyVariantType}>
				{item.value}
			</Typography>
		);

	if (!dataItem) return null;
	const list = getNestedValue(dataItem, item.listFieldPath);
	return (
		<Fragment>
			{Array.isArray(list) && list.length
				? list.map((val, index) => (
					<StyledLink
						key={index}
						to={`/${item.linkBase}${getNestedValue(val, item.linkFieldPath)}${item.linkQuery ? item.linkQuery : ''}`}
					>
						<Typography component='span' color={color} fontWeight={fontWeight} variant={variant as TypographyVariantType}>
							{item.capitalize ? 
								capitalizeString(getNestedValue(val, item.fieldPath)) 
								: getNestedValue(val, item.fieldPath)
							} {item.devider && item.devider}
							{item.additionalFieldPath ? getNestedValue(val, item.additionalFieldPath) : null}
						</Typography>
					</StyledLink>
				))
				: '-'}
		</Fragment>
	);
};
