import React, { Fragment, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { NationalProduct } from './NacionalProduct/NacionalProduct';
import { EuAuthorizedProduct } from './EuAuthorizedProduct/EuAuthorizedProduct';

export const Product = () => {
	const [searchParams] = useSearchParams();

	const productCountry = useMemo(() => {
		return searchParams.get('country');
	}, [searchParams]);

	return (
		<Fragment>
			{!productCountry || productCountry === '0' ? (
				<EuAuthorizedProduct />
			) : (
				<NationalProduct />
			)}
		</Fragment>
	);
};
