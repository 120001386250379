import React, { FC } from 'react';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';

import { MaterialUIInputSizeType } from 'models/types';
import { TextFieldWrapper } from '../shared/TextFieldWrapper';

interface BaseDatePickerInterface {
  onChange: (x: any) => void;
  value?: any;
  name: string;
  label?: string;
  size?: MaterialUIInputSizeType;
  inputProps?: any;
}

export const BaseDatePicker: FC<BaseDatePickerInterface> = ({ onChange, value, name, size = 'small', inputProps }) => {
	const onChangeHandler = (value) => onChange({ target: { name, value: moment(new Date(value)).format('YYYY-MM-DD') } });

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DesktopDatePicker
				onChange={onChangeHandler}
				value={value}
				inputFormat='YYYY/MM/DD'
				InputProps={inputProps}
				renderInput={(params) => (
					<TextFieldWrapper {...params} error={false} size={size} inputProps={{ ...params.inputProps, readOnly: true, name }} />
				)}
			/>
		</LocalizationProvider>
	);
};
